import { useState } from "react";
import "../../../styles/faq.css";

const faqs = [
  {
    title: "What is included in the Lakshadweep Samudram Package?",
    text: "The package includes ship tickets, accommodation in an AC cabin (Diamond Class), three daily meals, visits to three islands, sightseeing with a vehicle and guide, and three water sports activities (kayaking, glass-bottom boat rides, and snorkeling). It also covers entry permits and a cozy candlelit dinner.",
  },
  {
    title:
      "How long does it take to obtain permits for a Lakshadweep trip package? ",
    text: "The processing time varies based on the type of permit and current administrative procedures. GoL Travels can assist with the application process and provide timelines for all Lakshadweep packages, including the Samudram Package.",
  },
  {
    title:
      "When is the best time to visit Lakshadweep Islands for a Samudram Package tour?",
    text: "The ideal time to visit is from November to March when the weather is pleasant, and sea conditions are calm, perfect for snorkeling, scuba diving, and boat trips. It's advisable to check the specific schedule and availability as per government guidelines.",
  },
  {
    title:
      "What amenities are provided during a Lakshadweep Samudram Package tour?",
    text: "Onboard facilities include comfortable AC cabins, dining options, and access to beautiful islands where you can engage in various recreational activities like water sports and island exploration",
  },
  {
    title: " What is the Lakshadweep Package for LTC?",
    text: "The Lakshadweep Package for LTC (Leave Travel Concession) is a special travel package designed for government employees, allowing them to explore the Lakshadweep Islands while utilizing their LTC benefits.",
  },
  {
    title: " Who is eligible for the Lakshadweep Package for LTC?",
    text: "Government employees and their families who are eligible for LTC benefits can avail of this package.",
  },
  {
    title: "How can I book a Lakshadweep Samudram package online? ",
    text: "Meal inclusions vary depending on the specific package you choose. Some of our Bali packages may include daily breakfast, while others may offer additional meals such as lunch or dinner at select restaurants or accommodationsYou can book the package through GoL Travels’ website by selecting your preferred tour dates, providing necessary personal details, and making a reservation payment. Confirmation and additional instructions will be sent via email.",
  },

  {
    title: "Can you provide the Lakshadweep Samudram Package 2024 schedule?",
    text: "The schedule is available from the Lakshadweep Tourism Department or authorized tour operators like GoL Travels. It includes departure dates, duration, itinerary, and pricing, ensuring you can secure your spot without last-minute disappointment.",
  },
  {
    title:
      "What amenities are included in the Lakshadweep Bangaram Island package?",
    text: "The package offers AC huts with direct beach access, spacious rooms with stunning views, and attentive service, ensuring a comfortable and enjoyable stay on Bangaram Island.",
  },
  {
    title:
      " Are there any specific water activities included in the Lakshadweep tour package itinerary? ",
    text: "The package offers water sports like snorkeling, scuba diving, and kayaking, with opportunities to explore traditional fishing villages and enjoy cultural performances.",
  },
  {
    title: "Can I customize my Lakshadweep Holiday package?",
    text: "Yes, you can customize your package by choosing from a range of accommodation options, activities, and excursions to suit your preferences. Whether it’s water sports, cultural experiences, or romantic cruises, you can tailor the itinerary to match your dream vacation.",
  },
  {
    title: " What are the highlights of a 10-day Lakshadweep tour package?  ",
    text: "A 10-day tour allows ample time to explore pristine beaches, coral reefs, and lagoons, with opportunities for snorkeling and diving. You can also immerse yourself in the local culture and enjoy delicious seafood cuisine across various islands.",
  },
];

export default function SamudramFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Samudram Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
