import { useState } from "react";
import "../../../styles/faq.css";

const faqs = [
  {
    title: "What does the Bangaram Premium package include?",
    text: "The Bangaram Premium package includes accommodation in an AC wooden hut, all meals, transfers between Agatti and Bangaram, personalized support and escort services, entry permits, and a special candlelit dinner. Day trips to nearby islands are also part of the package.",
  },
  {
    title: "Are water sports included in the package?",
    text: "No, water sports activities such as scuba diving, snorkeling, and kayaking are not included in the package. These activities are available at an additional cost at the watersports center on Bangaram Island.",
  },
  {
    title: "How do I get to Bangaram Island?",
    text: "Your journey to Bangaram Island starts with a flight to Agatti Airport, where you will be greeted and transferred by boat to Bangaram Island. The boat ride takes about one hour and offers scenic views, and you might even spot dolphins along the way.",
  },
  {
    title: "What is the refund policy if the trip is canceled?",
    text: "If the trip is canceled due to unfavorable weather or unexpected technical issues, a 100% refund will be provided. No other damages or claims will be payable beyond the refund of the amount paid by the guests.",
  },
  {
    title: "When is full payment required for the package?",
    text: "Full payment is required 14 days before your arrival date. For bookings made within 2-3 months, a 25% payment of the total amount is required. For bookings made more than 4 months in advance, a 10% upfront payment is needed.",
  },
  {
    title: " Can I customize the Bangaram Premium package?",
    text: "While the package offers a set itinerary, you can discuss with GoL Travels any specific preferences or needs you may have, such as additional activities or special arrangements, to see if they can be accommodated.",
  },
  {
    title: " What is the best time to visit Lakshadweep?",
    text: "The best time to visit Lakshadweep is from October to April, when the weather is pleasant and perfect for outdoor activities.",
  },

  {
    title: "Are there any COVID-19 guidelines to follow?",
    text: "Yes, all travelers must follow the current COVID-19 guidelines, which may include testing, vaccination proof, and safety protocols during the trip. Please check for the latest updates before you travel.",
  },
  {
    title: "Can I cancel or reschedule my trip?",
    text: "Yes, you can cancel or reschedule your trip. However, cancellation or rescheduling fees may apply, depending on the terms of the package. Please refer to our cancellation policy for more details.",
  },
  {
    title: "What is included in a Lakshadweep Trip package?",
    text: "A standard Lakshadweep package typically includes accommodations at resorts or guest houses, meals (full-board or half-board), and guided tours or activities. Some packages may also offer extras like water sports or cultural experiences. It’s essential to check the details of each Lakshadweep tour package to ensure it meets your preferences. We offer various packages, such as the Honeymoon Package and the Lakshadweep Samudram Package, among others.",
  },
  {
    title:
      "How long does it take to obtain permits for a Lakshadweep trip package?",
    text: "The time required to obtain permits for a Lakshadweep trip can vary based on the type of permit and current administrative procedures. For packages like the Lakshadweep Samudram Package, it also depends on government norms. GoL Travels can assist you with the permit application process and provide timelines for all Lakshadweep and other destination packages.",
  },
  {
    title:
      "When is the best time to visit Lakshadweep Islands for a Samudram Package tour?",
    text: "The ideal time to visit the Lakshadweep Islands for a Samudram Package tour is from November to March. During these winter months, the weather is pleasant, and the sea conditions are calm, perfect for activities like snorkeling, scuba diving, and boat trips. However, it’s advisable to check the specific schedule and availability of the Lakshadweep Samudram Package tours as they may vary based on government guidelines.",
  },
  {
    title: "What is the Lakshadweep Package for LTC?",
    text: "The Lakshadweep Package for LTC (Leave Travel Concession) is a special travel package designed for government employees. It allows you to explore the beautiful islands of Lakshadweep while utilizing your LTC benefits.",
  },
  {
    title: "Who is eligible for the Lakshadweep Package for LTC?",
    text: "Government employees and their families who are eligible for LTC benefits can avail of this package.",
  },
];

export default function BangaramPremFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Bangaram Premium Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
