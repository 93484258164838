import React from "react";
import Slider from "react-slick";
import ava01 from "../../assets/images/testimonials/Ava01.png";
import ava02 from "../../assets/images/testimonials/Ava02.png";
import ava03 from "../../assets/images/testimonials/Ava03.png";
import ava04 from "../../assets/images/testimonials/Ava04.png";
import ava05 from "../../assets/images/testimonials/Ava05.png";

import "./testimonial.css";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p>
          GOL Travels turned my vacation into a personal love story with
          destinations handpicked just for me. It felt like they knew my travel
          dreams intimately and brought them to life. Thanks to them, my
          suitcase is now filled with memories, not just souvenirs!
        </p>
        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava01} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Prajwal R</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
          Our family adventure with GOL was like a magic carpet ride of joy.
          From expert advice on family-friendly spots to making us feel like
          VIPs everywhere we went, it was a memory-making marathon.
        </p>
        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava02} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Paritosh upadhya</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
          Every detail was like a note literally creating a unique travel
          composition just for me. If you want a travel experience as unique as
          you are, they are your conductor.
        </p>
        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava03} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Govindaraj PG </h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
          My honeymoon wasn't just a trip, it was a love story written by GOL
          Travels. From romantic hideaways to personalized surprises, every
          moment felt like a scene from a fairy tale.
        </p>
        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava04} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Rahul Jain</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
      <div className="testimonial py-4 px-3">
        <p>
          Traveling solo with GOL was like having a local friend in every
          destination. Safety was a priority, but so was the freedom to explore.
          They gave me not just a trip but a solo adventure that felt like a
          journey with a trusted companion. They've won a solo traveler's heart
        </p>
        <div className="d-flex align-items-center gap-4 mt-3">
          <img src={ava05} className="w-25 h-25 rounded-2" alt="" />
          <div>
            <h6 className="mb-0 mt-3">Deepika Balachandra</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Testimonials;
