import React, { useState, useEffect } from "react";
import { CommonSection4 } from "../shared/CommonSection";
import "../styles/tour.css";
import TourCard from "../shared/TourCard";
import SearchBar from "../shared/SearchBar";
import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
import { malaysia } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import MalaysiaFaq from "../components/Faq/MalaysiaFaq";

// import useFetch from "../hooks/useFetch";
// import { BASE_URL } from "../utils/config";
const Malaysia = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  // const {
  //   data: tours,
  //   loading,
  //   error,
  // } = useFetch(`${BASE_URL}/tours?page=${page}`);
  // const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>Malaysia packages | Malaysia Packages that are affordable</title>
        <meta
          name="description"
          content="Explore Malaysia's majesty affordably with tailored best-priced packages. Ideal for solo travelers or bachelor adventures seeking tropical treasures"
        />
        <meta
          name="keywords"
          content="malaysia honeymoon package, malaysia tour package, singapore and malaysia tour packages, singapore malaysia package, singapore malaysia thailand tour package, malaysia package, malaysia package from singapore, singapore malaysia tour packages, singapore and malaysia tour package from chennai, singapore malaysia tour package from Chennai, malaysia tour package, singapore and malaysia tour packages, singapore malaysia thailand tour package, singapore malaysia tour packages, singapore malaysia tour package from chennai, malaysia tour package price, malaysia tour packages from india for family, singapore and malaysia tour package from bangalore, singapore malaysia tour package from bangalore"
        />
      </Helmet>
      <CommonSection4 title={"Malaysia Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading...</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            {malaysia?.map((tour) => (
              <Col lg="3" className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}
            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Embark on an incredible journey through Malaysia with our
                  exclusive package from GolTravels. From the busy streets of
                  Kuala Lumpur to the tranquil beaches of Langkawi, Malaysia
                  offers a varied range of experiences that suit every
                  traveler's desires. Whether exploring vibrant markets, hiking
                  through lush rainforests, or indulging in mouthwatering
                  cuisines, Malaysia provides the ultimate­ relaxing escape. Our
                  tour ensures an authentic e­xperience showcasing the­ best
                  parts. Comfortable lodging, knowledge­able guides, and smooth
                  logistics let you re­lax and enjoy your adventure. Book now
                  and allow us to guide­ you through the heart of Southeast
                  Asia.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <MalaysiaFaq />
      <Newsletter />
    </>
  );
};

export default Malaysia;
