import React, { useState, useEffect } from "react";
import { CommonSection1 } from "../../../shared/CommonSection";
import "../../../styles/tour.css";
// import TourCard from "../shared/TourCard";
// import SearchBar from "../shared/SearchBar";
// import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
// import { maldives } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import MaldivesAllCatogories from "./MaldivesCategory";
import Newsletter from "../../../shared/Newsletter";
import SearchBar from "../../../shared/SearchBar";
// import MaldivesFaq from "../components/Faq/MaldivesFaq";
// import MaldivesAllCatogories from "./MaldivesCategory";

const MaldivesCategoryPage = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>
          Maldives honeymoon package | Budget friendly maldives package
        </title>
        <meta
          name="description"
          content="Experience romantic bliss with the best beachfront villas. Indulge in water activities on our budget-friendly trips for couples."
        />
        <meta
          name="keywords"
          content="maldives all inclusive packages, maldives honeymoon package, maldives packages, maldives honeymoon packages, maldives resort all inclusive package, maldives resort packages, maldives vacation package, travel to maldives packages, maldives all inclusive resort package, honeymoon packages Maldives, maldives honeymoon package, maldives honeymoon packages, honeymoon packages maldives, maldives honeymoon tour package, maldives honeymoon package for 7 days, maldives honeymoon travel packages, maldives island honeymoon packages, honeymoon maldives package, honeymoon packages for maldives, maldives island honeymoon package"
        />
      </Helmet>
      <CommonSection1 title={"Maldives Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Row>
            <MaldivesAllCatogories />
            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <MaldivesFaq /> */}
      <Newsletter />
    </>
  );
};

export default MaldivesCategoryPage;
