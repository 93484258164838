import React from "react";
import "./partner.css";
import partner1 from "../assets/images/partner1.jpg";
import partner2 from "../assets/images/partner2.png";
import partner3 from "../assets/images/partner3.png";
import partner4 from "../assets/images/partner4.png";
import partner5 from "../assets/images/partner5.jpg";

const Partner = ({ partner }) => {
  return (
    <div className="partner">
      <img src={partner1} alt="" />
      <img src={partner2} alt="" />
      <img src={partner3} alt="" />
      <img src={partner4} alt="" />
      <img src={partner5} alt="" />
    </div>
  );
};

export default Partner;
