import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./tour-card.css";
import calculateAvgRating from "../utils/avgRating";
import { Helmet } from "react-helmet";

const TourCard = ({ tour }) => {
  const {
    id,
    title,
    city,
    photo,
    price,
    packages,
    featured,
    reviews,
    metaTitle,
    metaDesc,
  } = tour;

  const { totalRating, avgRating } = calculateAvgRating(reviews);
  // console.log(tour.metaTitle);

  return (
    <>
      <div className="tour__card">
        <Card>
          <div className="tour__img">
            <Link rel="canonical" to={`/packages/${city}/${id}`}>
              <img src={photo} alt="tour-img" />
            </Link>

            {featured && <span>Featured</span>}
          </div>
          <CardBody>
            <div className="tour__top d-flex align-items-center justify-content-between">
              <span className="tour__location d-flex align-items-center gap-1">
                <i class="ri-map-pin-line"></i>
                {city}
              </span>
              <span className="tour__rating d-flex align-items-center gap-1">
                <i class="ri-star-fill"></i>
                {avgRating === 0 ? null : avgRating}{" "}
                {totalRating === 0 ? (
                  "Not rated"
                ) : (
                  <span>{reviews.length}</span>
                )}
              </span>
            </div>
            <h5 className="tour__title d-flex align-items-center justify-content-between mt-3">
              <Link rel="canonical" to={`/packages/${city}/${id}`}>
                {title}
              </Link>
            </h5>
            <p className="card__package">{packages}</p>

            <div className="card__bottom d-flex align-items-center justify-content-between mt-3">
              <h5>
                ₹{price}/-
                {/* <span>/2 Adults</span> */}
              </h5>
            </div>
            <div className="button__bottom d-flex align-items-center justify-content-between mt-1">
              <button className="btn booking__btn1">
                <Link to="">
                  <i class="ri-phone-line"></i>Get Call Back
                </Link>
              </button>
              <button className="btn booking__btn">
                <Link rel="canonical" to={`/packages/${city}/${id}`}>
                  Book Now
                </Link>
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default TourCard;
