import React, { useState, useEffect } from "react";
import "../../../styles/tour.css";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { CommonSection1 } from "../../../shared/CommonSection";
import SearchBar from "../../../shared/SearchBar";
import MaldivesFaq from "../../../components/Faq/MaldivesFaq";
import {
  MaldivesCatogories,
  maldives_Budget,
  maldives_Honeymoon,
  maldives_Resort,
  maldives_WaterSports,
} from "../../../assets/data/maldivesTours";
import Newsletter from "../../../shared/Newsletter";
import MaldivesTourCard from "../MaldivesTourCard/MaldivesTourCard";
import { useParams } from "react-router-dom";

const MaldivesHoneymoon = () => {
  const { category } = useParams();

  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const tour = MaldivesCatogories.find((tour) => tour.category === category);
  // console.log(maldivesCatogories.category);
  // const { category } = tour;
  console.log(category);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>
          Maldives honeymoon package | Budget friendly maldives package
        </title>
        <meta
          name="description"
          content="Experience romantic bliss with the best beachfront villas. Indulge in water activities on our budget-friendly trips for couples."
        />
        <meta
          name="keywords"
          content="maldives all inclusive packages, maldives honeymoon package, maldives packages, maldives honeymoon packages, maldives resort all inclusive package, maldives resort packages, maldives vacation package, travel to maldives packages, maldives all inclusive resort package, honeymoon packages Maldives, maldives honeymoon package, maldives honeymoon packages, honeymoon packages maldives, maldives honeymoon tour package, maldives honeymoon package for 7 days, maldives honeymoon travel packages, maldives island honeymoon packages, honeymoon maldives package, honeymoon packages for maldives, maldives island honeymoon package"
        />
      </Helmet>
      <CommonSection1 title={"Maldives Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Row>
            <div>
              {category === "Honeymoon" ? (
                <div>
                  <Row>
                    {maldives_Honeymoon?.map((tour) => (
                      <Col lg="3" className="mb-4" key={tour._id}>
                        <MaldivesTourCard tour={tour} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : category === "Resort_Packages" ? (
                <div>
                  <Row>
                    {maldives_Resort?.map((tour) => (
                      <Col lg="3" className="mb-4" key={tour._id}>
                        <MaldivesTourCard tour={tour} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : category === "Budget_Packages" ? (
                <div>
                  <Row>
                    {maldives_Budget?.map((tour) => (
                      <Col lg="3" className="mb-4" key={tour._id}>
                        <MaldivesTourCard tour={tour} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : category === "Watersports" ? (
                <div>
                  <Row>
                    {maldives_WaterSports?.map((tour) => (
                      <Col lg="3" className="mb-4" key={tour._id}>
                        <MaldivesTourCard tour={tour} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : null}
            </div>

            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Indulge­ in luxury and calmness with our Maldives package.
                  With the white sandy beache­s, crystal-clear waters, and
                  luxurious overwate­r villas, Maldives is the epitome­ of
                  paradise. Whether a hone­ymooner seeking romance­ or a family
                  looking for a memorable escape­, our package offers the
                  pe­rfect blend of relaxation and adve­nture. Snorkel in coral
                  ree­fs alive with colorful fish to spotting manta rays, our
                  Maldives package­ ensures memorable­ moments for all. Our
                  experie­nced team will craft an unforgettable­ vacation, so
                  book your dates and let the magic of Maldive­s sweep you away.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <MaldivesFaq />
      <Newsletter />
    </>
  );
};

export default MaldivesHoneymoon;
