import React, { useEffect, useState } from "react";
import "./footer.css";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import logo1 from "../../assets/images/logo1.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Lakshadweep_packages = [
  {
    // Title: "Sail Samudram package with online booking and, Live the dream!",
    // description:
    //   " Discover the Samudram tour 2024 details and more beauty of the­ sea, uncover hidden ge­ms, and experience­ the thrill of oceanic adventure­s. This Lakshadweep Samudram package offers the ultimate trip discounts and offers for you. Book now and se­t your sail today!",
    path: "/packages/Lakshadweep/Samudram",
    display: "Lakshadweep Samudram Package",
  },
  {
    path: "/packages/Lakshadweep/Bangaram",
    display: "Lakshadweep Bangaram Packages",
  },
  {
    path: "/packages/03/packages/Lakshadweep/Bangaram_premium",
    display: "Bangaram Premium Packages",
  },
  {
    path: "/packages/Lakshadweep/Agatti",
    display: "Agatti Island Packages",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Snorkeling spots in Lakshadweep",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep cruise holidays",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep coral islands",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep cultural experiences",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep marine life exploration",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep family-friendly resorts",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep offbeat destinations",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep water sports adventures",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep pristine beaches",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep green tourism",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep coastal escapes",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep seaplane tours",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep Honeymoon Packages",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep tour packages",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep local cuisine",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep off-season travel",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep cultural festivals",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep packages comparison",
  },
  {
    path: "/packages/Lakshadweep",
    display: "Lakshadweep travel itinerary ideas",
  },
];

const Maldives_packages = [
  {
    path: "/packages/Maldives/Dhiffushi",
    display: "Dhiffushi Island Packages",
  },
  {
    path: "/packages/Maldives/Thulusdhoo",
    display: "Thulusdhoo Island Packages",
  },
  {
    path: "/packages/Maldives/Dhiffushi&Thulusdhoo",
    display: "Dhiffushi & Thulusdhoo Packages",
  },
  {
    path: "/packages/Maldives/Maldives_Premium_Honeymoon",
    display: "Maldives Honeymoon Packages",
  },
  {
    path: "/packages/Maldives/Personalized_Island_Experience",
    display: "Personalized Island Packages",
  },
  {
    path: "/packages/Maldives/Maldives_Submarine_Dive",
    display: "Maldives Submarine Packages",
  },
  {
    path: "/packages/Maldives/Splendid_Serenity_Retreat",
    display: "Splendid Serenity Retreat Packages",
  },
  {
    path: "/packages/Maldives/Honeymoon_Hideaway_Experience_Two_Island",
    display: "Honeymoon Packages",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives vacation packages",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives luxury resorts",
  },
  {
    path: "/packages/07",
    display: "Maldives honeymoon package 7 day",
  },
  {
    path: "/packages/Maldives",
    display: "cheapest water villa in Maldives",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives Water Villa Price",
  },
  {
    path: "/packages/Maldives",
    display: "Best water villas in Maldives",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives adventure packages",
  },
  {
    path: "/packages/Maldives",
    display: "Budget trip to maldives from India",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives family packages",
  },
  {
    path: "/packages/Maldives",
    display: "Best honeymoon resorts in Maldives",
  },
  {
    path: "/packages/Maldives",
    display: "Best maldives budget packages in 2023",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives affordable packages",
  },
  {
    path: "/packages/Maldives",
    display: "Scuba diving in Maldives",
  },
  {
    path: "/packages/Maldives",
    display: "Best beaches in Maldives",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives luxury beachfront stays",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives sunset cruises",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives budget-friendly vacations",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives private island retreats",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives sustainable tourism",
  },
  {
    path: "/packages/Maldives",
    display: "Maldives travel tips",
  },
];

const Bali_packages = [
  {
    path: "/packages/Bali/Bali_Odyssey",
    display: "Bali Packages",
  },
  {
    path: "/packages/Bali/Bali_Island_Odyssey",
    display: "Bali Island Packages",
  },
  {
    path: "/packages/Bali/BaliFamily_Adventure",
    display: "BaliFamily Adventure Packages",
  },
  {
    path: "/packages/Bali",
    display: "bali tour package",
  },
  {
    path: "/packages/Bali",
    display: "bali honeymoon package",
  },
  {
    path: "/packages/Bali",
    display: "bali tour packages from india",
  },
  {
    path: "/packages/Bali",
    display: "bali packages for couple",
  },
  {
    path: "/packages/Bali",
    display: "bali packages from delhi",
  },
  {
    path: "/packages/Bali",
    display: "bali tour package for couple",
  },
  {
    path: "/packages/Bali",
    display: "bali indonesia package",
  },
  {
    path: "/packages/Bali",
    display: "bali indonesia tour package",
  },
  {
    path: "/packages/Bali",
    display: "bali indonesia honeymoon package",
  },
  {
    path: "/packages/Bali",
    display: "bali holiday packages",
  },
  {
    path: "/packages/Bali",
    display: "bali family holiday packages",
  },
  {
    path: "/packages/Bali",
    display: "bali honeymoon package for 7 days",
  },
  {
    path: "/packages/Bali",
    display: "bali tour package 5 days 4 nights",
  },
];

const Thailand_packages = [
  {
    path: "/packages/Thailand/Bangkok_Odyssey",
    display: "Bangkok Packages",
  },
  {
    path: "/packages/Thailand/Pattaya_Delight",
    display: "Pattaya Packages",
  },
  {
    path: "/packages/Thailand/Pattaya&Bangkok_Escapade",
    display: "Pattaya & Bangkok Packages",
  },
  {
    path: "/packages/Thailand",
    display: "thailand package",
  },
  {
    path: "/packages/Thailand",
    display: "thailand tour package",
  },
  {
    path: "/packages/Thailand",
    display: "thailand packages from india",
  },
  {
    path: "/packages/Thailand",
    display: "thailand honeymoon packages",
  },
  {
    path: "/packages/Thailand",
    display: "thailand packages from delhi",
  },
  {
    path: "/packages/Thailand",
    display: "thailand package from bangalore",
  },
  {
    path: "/packages/Thailand",
    display: "thailand tour packages from mumbai",
  },
  {
    path: "/packages/Thailand",
    display: "thailand honeymoon packages",
  },
  {
    path: "/packages/Thailand",
    display: "phuket thailand honeymoon packages",
  },
  {
    path: "/packages/Thailand",
    display: "thailand holiday packages",
  },
  {
    path: "/packages/Thailand",
    display: "best thailand holiday packages",
  },
  {
    path: "/packages/Thailand",
    display: "Thailand Packages all inclusives",
  },
  {
    path: "/packages/Thailand",
    display: "packages for pattaya & phuket",
  },
  {
    path: "/packages/Thailand",
    display: "bangkok pattaya phuket krabi package",
  },
];

const Malaysia_packages = [
  {
    path: "/packages/Malaysia/Malaysian_Marvels",
    display: "Malaysian Marvels Packages",
  },
  {
    path: "/packages/Malaysia/Malaysian_Odyssey",
    display: "Malaysian Packages",
  },
  {
    path: "/packages/Malaysia/Kuala_Lumpur",
    display: "Kuala Lumpur Packages",
  },
  {
    path: "/packages/Malaysia/Kuala_Lumpur",
    display: "malaysia tour package",
  },
  {
    path: "/packages/Malaysia/Kuala_Lumpur",
    display: "malaysia honeymoon package",
  },
];

const nav__links = [
  {
    path: "/home",
    display: "Home",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/tours",
    display: "Tours",
  },
  {
    path: "/gallery",
    display: "Gallery",
  },
  {
    path: "/login",
    display: "Login",
  },
  {
    path: "/register",
    display: "Register",
  },
  {
    path: "/blog",
    display: "Blog",
  },
];

const quick__links = [
  // {
  //   path: "https://golakshadweep.com/",
  //   display: "Gol Lakshadweep",
  // },
  // {
  //   path: "https://golmaldives.com/",
  //   display: "Gol Maldives",
  // },
  {
    path: "https://www.golcourier.com/",
    display: "Gol Couriers",
  },
  {
    path: "https://goltravels.com/",
    display: "Gol Travels",
  },
  {
    path: "",
    display: "Faq",
  },
  {
    path: "/Cancellation&Refund",
    display: "Cancellation & Refund",
  },
  {
    path: "/sitemap.html",
    display: "Sitemap",
  },
  {
    path: "/terms&conditions",
    display: "Terms & Conditions",
  },
];

// eslint-disable-next-line import/first
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

function Footer(props) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  // Function to check if the screen size is small
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  // Add event listener to check for screen size changes
  // window.addEventListener("resize", checkMobileView);
  // Check screen size when component mounts

  const year = new Date().getFullYear;
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>
          Welcome­ back to Gol Travels! Access your travel account here
        </title>
        <meta
          name="description"
          content=" Log in to your Gol Travels account to manage­ bookings, view your travel plans, and take advantage­ of exclusive offers. Enjoy a hassle­-free travel planning e­xperience with our e­asy-to-use platform. Click and start your travel journey here"
        />
      </Helmet> */}
      <div>
        <footer className="footer">
          <Container>
            <Row>
              <Col>
                <Accordion open={open} toggle={toggle}>
                  {isMobileView ? (
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Lakshadweep Packages
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <ListGroup className="footer__list">
                          <ul className="horizontal-list">
                            {Lakshadweep_packages.map((item, index) => (
                              <ListGroupItem
                                key={index}
                                className="ps-0 border-0 "
                              >
                                <Link rel="canonical" to={item.path}>
                                  {item.display}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ul>
                        </ListGroup>
                      </AccordionBody>
                    </AccordionItem>
                  ) : (
                    <>
                      <h6 className="footer__link-title1">
                        Lakshadweep Packages
                      </h6>
                      <ListGroup className="footer__list">
                        <ul className="horizontal-list">
                          {Lakshadweep_packages.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              className="ps-0 border-0 "
                            >
                              <Link rel="canonical" to={item.path}>
                                {item.display}
                              </Link>
                            </ListGroupItem>
                          ))}
                        </ul>
                      </ListGroup>
                      <hr className="line"></hr>
                    </>
                  )}
                  {isMobileView ? (
                    <AccordionItem>
                      <AccordionHeader targetId="5">
                        Maldives Packages
                      </AccordionHeader>
                      <AccordionBody accordionId="5">
                        <ListGroup className="footer__list">
                          <ul className="horizontal-list">
                            {Maldives_packages.map((item, index) => (
                              <ListGroupItem
                                key={index}
                                className="ps-0 border-0 "
                              >
                                <Link rel="canonical" to={item.path}>
                                  {item.display}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ul>
                        </ListGroup>
                      </AccordionBody>
                    </AccordionItem>
                  ) : (
                    <>
                      <h6 className="footer__link-title1">Maldives Packages</h6>
                      <ListGroup className="footer__list">
                        <ul className="horizontal-list">
                          {Maldives_packages.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              className="ps-0 border-0 "
                            >
                              <Link rel="canonical" to={item.path}>
                                {item.display}
                              </Link>
                            </ListGroupItem>
                          ))}
                        </ul>
                      </ListGroup>
                      <hr className="line"></hr>
                    </>
                  )}

                  {isMobileView ? (
                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        Bali, Indonesia Packages
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        <ListGroup className="footer__list">
                          <ul className="horizontal-list">
                            {Bali_packages.map((item, index) => (
                              <ListGroupItem
                                key={index}
                                className="ps-0 border-0 "
                              >
                                <Link rel="canonical" to={item.path}>
                                  {item.display}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ul>
                        </ListGroup>
                      </AccordionBody>
                    </AccordionItem>
                  ) : (
                    <>
                      <h6 className="footer__link-title1">
                        Bali, Indonesia Packages
                      </h6>
                      <ListGroup className="footer__list">
                        <ul className="horizontal-list">
                          {Bali_packages.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              className="ps-0 border-0 "
                            >
                              <Link rel="canonical" to={item.path}>
                                {item.display}
                              </Link>
                            </ListGroupItem>
                          ))}
                        </ul>
                      </ListGroup>
                      <hr className="line"></hr>
                    </>
                  )}

                  {isMobileView ? (
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        Thailand Packages
                      </AccordionHeader>
                      <AccordionBody accordionId="3">
                        <ListGroup className="footer__list">
                          <ul className="horizontal-list">
                            {Thailand_packages.map((item, index) => (
                              <ListGroupItem
                                key={index}
                                className="ps-0 border-0 "
                              >
                                <Link rel="canonical" to={item.path}>
                                  {item.display}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ul>
                        </ListGroup>
                      </AccordionBody>
                    </AccordionItem>
                  ) : (
                    <>
                      <h6 className="footer__link-title1">Thailand Packages</h6>
                      <ListGroup className="footer__list">
                        <ul className="horizontal-list">
                          {Thailand_packages.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              className="ps-0 border-0 "
                            >
                              <Link rel="canonical" to={item.path}>
                                {item.display}
                              </Link>
                            </ListGroupItem>
                          ))}
                        </ul>
                      </ListGroup>
                      <hr className="line"></hr>
                    </>
                  )}

                  {isMobileView ? (
                    <AccordionItem>
                      <AccordionHeader targetId="4">
                        Malaysia Packages
                      </AccordionHeader>
                      <AccordionBody accordionId="4">
                        <ListGroup className="footer__list">
                          <ul className="horizontal-list">
                            {Malaysia_packages.map((item, index) => (
                              <ListGroupItem
                                key={index}
                                className="ps-0 border-0 "
                              >
                                <Link rel="canonical" to={item.path}>
                                  {item.display}
                                </Link>
                              </ListGroupItem>
                            ))}
                          </ul>
                        </ListGroup>
                      </AccordionBody>
                    </AccordionItem>
                  ) : (
                    <>
                      <h6 className="footer__link-title1">Malaysia Packages</h6>
                      <ListGroup className="footer__list">
                        <ul className="horizontal-list">
                          {Malaysia_packages.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              className="ps-0 border-0 "
                            >
                              <Link rel="canonical" to={item.path}>
                                {item.display}
                              </Link>
                            </ListGroupItem>
                          ))}
                        </ul>
                      </ListGroup>
                      <hr className="line"></hr>
                    </>
                  )}
                </Accordion>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg="3">
                <div className="logo">
                  <Link to="/">
                    <img src={logo1} alt="" />
                  </Link>

                  <p>
                    GOL is entirely owned and managed by GoL ventures pvt ltd.
                    GOL is striving hard for ultimate success and innovation. We
                    are a registered company as a tour & travel company that
                    aims to set clear goals, fix the priorities and organize the
                    resources effectively.
                  </p>
                </div>
              </Col>
              <Col lg="3">
                <h5 className="footer__link-title">Discover</h5>

                <ListGroup className="footer__quick-links">
                  {nav__links.map((item, index) => (
                    <ListGroupItem key={index} className="ps-0 border-0 ">
                      <a rel="canonical" href={item.path}>
                        {item.display}
                      </a>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col lg="3">
                <h5 className="footer__link-title">Quick Links</h5>

                <ListGroup className="footer__quick-links">
                  {quick__links.map((item, index) => (
                    <ListGroupItem key={index} className="ps-0 border-0 ">
                      <a rel="canonical" href={item.path}>
                        {item.display}
                      </a>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col lg="3">
                <h5 className="footer__link-title">Contact</h5>

                <ListGroup className="footer__quick-links">
                  <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-3">
                    <h6
                      className="mb-0 d-flex align-item-center
                gap-2"
                    >
                      <span>
                        <i class="ri-map-pin-line"></i>
                      </span>
                      address:
                    </h6>
                    <p>
                      Cochin – 682 005, Kerala, India. Bangalore | Delhi | Male
                      | Lakshadweep
                    </p>
                  </ListGroupItem>
                  <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-3">
                    <h6
                      className="mb-0 d-flex align-item-center
                gap-2"
                    >
                      <span>
                        <i class="ri-mail-line"></i>
                      </span>
                      Email:
                    </h6>
                    <p>info@goltravels.com</p>
                  </ListGroupItem>
                  <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-3">
                    <h6
                      className="mb-0 d-flex align-item-center
                gap-2"
                    >
                      <span>
                        <i class="ri-phone-line"></i>
                      </span>
                      Phone:
                    </h6>
                    <p>+91-9778389592</p>
                  </ListGroupItem>
                  <div className="social__links d-flex align-items-center gap-4">
                    <span className="social__link d-flex align-items-center gap-4">
                      <a
                        rel="canonical"
                        href="https://www.youtube.com/channel/UCDX2UCiA9O44hgVq2_2dSuQ"
                        target="blank"
                      >
                        <i class="ri-youtube-line"></i>
                      </a>
                      <a
                        rel="canonical"
                        href="https://www.facebook.com/GoLtravels"
                        target="blank"
                      >
                        <i class="ri-facebook-circle-line"></i>
                      </a>
                      <a
                        rel="canonical"
                        href="https://www.instagram.com/gol_travels/"
                        target="blank"
                      >
                        <i class="ri-instagram-line"></i>
                      </a>
                      <a
                        rel="canonical"
                        href="https://www.linkedin.com/in/gol-travels-b26926299/"
                        target="blank"
                      >
                        <i class="ri-linkedin-box-fill"></i>
                      </a>
                      <a
                        rel="canonical"
                        href="https://www.whatsapp.com/channel/0029Va4pkxSInlqUFpq3mY2s"
                        target="blank"
                      >
                        <i class="ri-whatsapp-fill"></i>
                      </a>
                    </span>
                  </div>
                </ListGroup>
              </Col>

              <Col lg="12" className="text-center pt-5">
                <p className="copyright">
                  Copyright {year}, Gol Ventures Private Ltd . All rights
                  Reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
}

export default Footer;
