import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { visaFree } from "../../assets/data/tours";

const VisaFreeDest = () => {
  return (
    <>
      {visaFree?.map((visa) => (
        <Col lg="3" md="6" sm="6" className="mb-4" key={visa.id}>
          <TourCard tour={visa} />
        </Col>
      ))}
    </>
  );
};

export default VisaFreeDest;
