import { useState } from "react";
// import "./faq.css";
// import '../../src/components/balifaq.css';
import "../../styles/faq.css";

const faqs = [
  {
    title: "What types of Maldives packages does GoL Travels offer?",
    text: "GOl Travels offers a variety of Maldives packages at affordable costs, catering to different preferences and budgets. Whether you're seeking the best Maldives packages, luxurious honeymoon experiences, or all-inclusive Maldives resort stays, we have options to suit every traveler's needs.",
  },
  {
    title: "Can I customize my Maldives package online before booking?",
    text: "While we understand you might have different requirements, on our official website, range of standard Maldives packages as per the availability and budgets. You can directly reach our to us at the provided contact details for specific customizations in you Maldives honeymoon package as per your need. Our expert team will guide you and give you the best experience of your life!",
  },
  {
    title: "Are there specific Maldives packages for couples?",
    text: "Yes, GOl Travels specializes in Maldives honeymoon packages for couples looking to experience the ultimate romantic getaway. Our budget-friendly  Maldives honeymoon packages ensure a memorable and intimate experience amidst the breathtaking beauty of the Maldives.",
  },
  {
    title:
      "Can I book a Maldives package over the phone or via email with GoL Travels?",
    text: "Yes, if you prefer to book your Maldives package over the phone or via email, our dedicated customer support team is available to assist you. Simply reach out to us with your travel requirements and preferences, and we'll guide you through the booking process and answer any questions you may have.",
  },
  {
    title: "Do you offer all-inclusive Maldives resort packages?",
    text: "Absolutely! Our Maldives resort all-inclusive packages provide a hassle-free vacation experience, allowing you to indulge in luxury amenities, gourmet dining, and exciting activities without worrying about additional costs in your Maldives Tour package.",
  },
  {
    title: "Can I customize my Maldives package with GoL Travels?",
    text: "Yes, of course! We understand that every traveler has unique preferences and requirements. That's why we offer custom-made packages for Maldives, allowing you to tailor your itinerary according to your interests, duration of stay, and budget.",
  },
  {
    title:
      "Are GoL Travels' Maldives packages from India with prices all-inclusive?",
    text: "Yes, our Maldives packages from India come with transparent pricing, ensuring that you know exactly what to expect without any hidden charges. We strive to provide affordable costs for Maldives tours, making your dream vacation to this tropical paradise a reality.",
  },
  {
    title: "How can I book a Maldives package online with GoL Travels?",
    text: "Booking your dream Maldives package with GOl Travels is quick and easy. Simply visit our website and browse through our range of Maldives packages. Once you've selected the Maldives package that suits your preferences, click on more details or directly reach at our provided 24x7 customer support for the prompt response.",
  },
  {
    title: "Can GoL Travels assist with visa applications for the Maldives?",
    text: "Yes, GOl Travels offers visa assistance services to facilitate the visa application process for travelers. Our experienced team will provide you with the necessary information and support to ensure a smooth and hassle-free visa application experience.",
  },
  {
    title: "Do I need a visa to travel to the Maldives with GoL Travels?",
    text: "For many nationalities, a tourist visa is not required to visit the Maldives Packages for stays of up to 30 days. However, it's essential to check the specific visa requirements based on your nationality.  Maldives can have another requirement that needs to be checked as well. We provide assistance and guidance to help you navigate the visa process seamlessly for your Maldives package from India.",
  },
  {
    title:
      "What can I expect from a 4-day 3-night package to the Maldives Tour package with GOl Travels?",
    text: "Our 3-night - 4-day Maldives Honeymoon package offers a perfect blend of relaxation and adventure in the Maldives. You'll have ample time to soak in the stunning natural beauty, indulge in water sports, and explore vibrant marine life Where Maldives’ natural beauty lies. Through snorkeling or diving, enjoy romantic moments with your loved ones. Choose this Maldives package for a heaven-like experience.",
  },
  {
    title:
      "Are there any Top 4 Beaches in Maldives Packages for couples on a  Special Maldives honeymoon trip?",
    text: "1.Veligandu Island Beach: Known for its powdery white sands and crystal-clear turquoise waters, this Maldives Beach  Tour offers couples a serene and secluded escape.. 2.Fulhadhoo Beach: With its pristine shoreline fringed by swaying palm trees and vibrant coral reefs just offshore, It is the perfect Maldives package for couples seeking tranquility and natural beauty. 3.Biyadhoo Island Beach: it boasts soft sandy shores and vibrant marine life, making it an ideal destination for Maldives holiday as a package where couples who enjoy snorkeling together. 4.Vaadhoo Island Beach: Famous for its mesmerizing bioluminescent phytoplankton that illuminate the shoreline at night, it offers the best Maldives honeymoon for couples a magical and unforgettable experience.",
  },
];

export default function MaldivesFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Maldives Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
