import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import "./header1.css";
import logo1 from "../../assets/images/logo1.png";
import { Link } from "react-router-dom";

function Header1(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar
        className="header"
        {...args}
        expand="lg"
        height="80px"
        dark="True"
        // fixed="top"
        // color="black"
      >
        <NavbarBrand href="/">
          <img src={logo1} alt="" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                SouthEast Packages
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/packages/Bali/">Exclusive Bali Bliss</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Thailand/">Thailand Thrills</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Malaysia/">Majestic Malaysia</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Coral Gateways
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/packages/Lakshadweep/">
                    Budget-Friendly Lakshadweep
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Maldives/">Maldives Haven</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Honeymoon Packages
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/packages/Lakshadweep/">Lakshadweep Love Nest</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Maldives/">Maldives Honeymoon Haven</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Bali/">Bali Bliss for Two</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Thailand/">Thailand Romance Retreat</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Malaysia/">
                    Malaysia Couple's Getaway
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Budget Packages
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/packages/Lakshadweep/">Lakshadweep on Budget</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Maldives/">
                    Maldives Affordable Getaways
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Bali/">Bali Budget Adventures</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Thailand/">Thailand Thrifty Trips</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/packages/Malaysia/">
                    Malaysia Pocket-Friendly Packages
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Our Story
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/about/">About Us</Link>
                </DropdownItem>
                <DropdownItem>
                  <a href="/contact/">Contact</a>
                </DropdownItem>
                <DropdownItem>
                  <a href="/blog/">Blog</a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <div className="btn-auth">
            <Button>
              <Link to="/login/">Login</Link>
            </Button>
            <Button>
              <Link to="/register/">Register</Link>
            </Button>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header1;
