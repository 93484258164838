import React, { useState } from "react";
import "./readmore.css";

const ReadMoreButton = ({ text, maxLength }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayText = isReadMore ? text.slice(0, maxLength) : text;

  return (
    <div>
      <p>{displayText}</p>
      {text.length > maxLength && (
        <p className="press" onClick={toggleReadMore}>
          {isReadMore ? "Read More" : "Read Less"}
        </p>
      )}
    </div>
  );
};

export default ReadMoreButton;
