import { useState } from "react";
// import "./faq.css";
// import '../../src/components/balifaq.css';
import "../../styles/faq.css";

const faqs = [
  {
    title: "What types of Malaysia tour packages are available?",
    text: "Our Malaysia tour packages cater to various preferences including family vacations, honeymoon getaways, group tours, and customized itineraries for couples.",
  },
  {
    title: "What are some popular Malaysia vacation packages offered?",
    text: "We offer a range of Malaysia vacation packages, including group tours from Singapore, holiday packages covering Kuala Lumpur and Langkawi, and comprehensive 7-day tours covering the best of Malaysia.",
  },
  {
    title: "Can I customize my travel package according to my preferences?",
    text: "Yes, you can at GoL Travels, we understand that every traveler is unique. That's why we offer customizable travel packages where you can personalize your itinerary, choose accommodations, and select activities based on your interests and budget.",
  },
  {
    title: "Are there specific Singapore-Malaysia honeymoon packages?",
    text: "Yes, GoL Travels has tailored Singapore Malaysia tour packages specifically designed for couples and honeymooners. These packages include romantic experiences, luxurious accommodations, and special amenities to make your trip memorable.",
  },
  {
    title: "What kind of activities can families enjoy during a Malaysia trip?",
    text: "Families can indulge in activities such as exploring vibrant markets, enjoying theme parks, experiencing Malaysia’s best trips for family including cultural heritage sites, and relaxing on pristine beaches. Our family-friendly tour packages ensure everyone has a fantastic time.",
  },
  {
    title:
      "Can GoL travel book 3 Days and 4 Nights in Kuala Lumpur and Langkawi package?",
    text: "Yes, GoL can provide customizations for 3-day and 4-night packages depending upon the availability and time of visit. Our guests are advised to contact us directly for up-to-date information and guidance on package booking online.",
  },
  {
    title:
      "Can you highlight what's covered in the Best of Malaysia in 7 days package?",
    text: "Our 7-day tour package covers the highlights of Malaysia, including Kuala Lumpur, Langkawi, Penang, etc depending upon the schedule and availability of the package. You'll visit iconic landmarks, indulge in local cuisine, explore cultural sites, and relax on picturesque beaches.",
  },
  {
    title:
      "Are there any special inclusions for honeymoon tour packages in Malaysia?",
    text: "Yes, our honeymoon tour packages in Malaysia include romantic dinners, private excursions, and upgraded accommodations to ensure a blissful and unforgettable experience for newlyweds. Contact us to know more.",
  },
  {
    title: "How do GoL Travels book the Singapore Malaysia tour package?",
    text: "Our Singapore Malaysia tour packages are meticulously crafted to offer exceptional value, personalized experiences, and seamless travel arrangements. With expert guidance, local insights, and a passion for travel, we strive to make your Malaysia vacation remarkable.",
  },
  {
    title: "Where can I find more information and book Malaysia tour packages?",
    text: "You can explore our range of Malaysia tour packages on our website goltravels.com or contact our travel consultants for personalized assistance. Book now and embark on an unforgettable journey to discover the beauty and diversity of Malaysia!",
  },
];

export default function MalaysiaFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Malaysia Package FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1} </p>
      <p className="title">{title} </p>
      <p className="icon">{isOpen ? "-" : "+"} </p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
