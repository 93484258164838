import React from "react";
import "./common-section.css";
import { Col, Container, Row } from "reactstrap";
// import TourImg from "../assets/images/tour.jpg";

const CommonSection = ({ title }) => {
  return (
    <section className="common-section">
      {/* <img src={TourImg} alt="" /> */}
      <Container>
        <Row>
          <Col lg="12">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const CommonSection1 = ({ title }) => {
  return (
    <section className="common-section1">
      {/* <img src={TourImg} alt="" /> */}
      <Container>
        <Row>
          <Col lg="12">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const CommonSection2 = ({ title }) => {
  return (
    <section className="common-section2">
      {/* <img src={TourImg} alt="" /> */}
      <Container>
        <Row>
          <Col lg="12">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
const CommonSection3 = ({ title }) => {
  return (
    <section className="common-section3">
      {/* <img src={TourImg} alt="" /> */}
      <Container>
        <Row>
          <Col lg="12">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const CommonSection4 = ({ title }) => {
  return (
    <section className="common-section4">
      {/* <img src={TourImg} alt="" /> */}
      <Container>
        <Row>
          <Col lg="12">
            <h1>{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export {
  CommonSection,
  CommonSection1,
  CommonSection2,
  CommonSection3,
  CommonSection4,
};
