import React, { useState } from "react";
import "./readmore.css";

const AboutReadMoreButton = ({ text, maxLength }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const displayText = isReadMore ? text.slice(0, maxLength) : text;

  return (
    <div>
      <p className="displaytext">{displayText}</p>
      {text.length > maxLength && (
        <button className="pressed" onClick={toggleReadMore}>
          {isReadMore ? "Read More" : "Read Less"}
        </button>
      )}
    </div>
  );
};

export default AboutReadMoreButton;
