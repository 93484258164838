import React, { useState, useEffect } from "react";
import { CommonSection4 } from "../shared/CommonSection";
import "../styles/tour.css";
import TourCard from "../shared/TourCard";
import SearchBar from "../shared/SearchBar";
import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
import { srilanka } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import SrilankaFaq from "../components/Faq/SrilankaFaq";

const Srilanka = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>
          Best Priced Sri Lanka Tour Packages | Sri Lanka Packages from
          Bangalore
        </title>
        <meta
          name="description"
          content="Explore top Sri Lanka tour packages with GoL Travels. Book Sri Lanka packages, including special holiday packages and couple tours from Bangalore. Plan now!"
        />
        <meta
          name="keywords"
          content="malaysia honeymoon package, malaysia tour package, singapore and malaysia tour packages, singapore malaysia package, singapore malaysia thailand tour package, malaysia package, malaysia package from singapore, singapore malaysia tour packages, singapore and malaysia tour package from chennai, singapore malaysia tour package from Chennai, malaysia tour package, singapore and malaysia tour packages, singapore malaysia thailand tour package, singapore malaysia tour packages, singapore malaysia tour package from chennai, malaysia tour package price, malaysia tour packages from india for family, singapore and malaysia tour package from bangalore, singapore malaysia tour package from bangalore"
        />
      </Helmet>
      <CommonSection4 title={"Srilanka Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading...</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            {srilanka?.map((tour) => (
              <Col lg="3" className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}
            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Discover the magic of Sri Lanka with our exclusive Sri Lanka
                  tour packages. Whether you're looking for an adventure, a
                  relaxing holiday, or a romantic getaway, our Sri Lanka
                  packages cater to all your travel needs. Starting from
                  Bangalore, our Sri Lanka package from Bangalore ensures a
                  hassle-free journey to this tropical paradise. With GoL
                  Travels, experience the best of Sri Lanka. Our Sri Lanka
                  holiday packages cover stunning beaches, historic sites, and
                  vibrant culture. Perfect for couples, our Sri Lanka tour
                  package for couples promises unforgettable memories. Book your
                  dream vacation Sri Lanka package today!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <SrilankaFaq />
      <Newsletter />
    </>
  );
};

export default Srilanka;
