// src/components/FullBlogPost.js
import React from "react";
import { useParams } from "react-router-dom";
import "./blog.css";

const FullBlogPost = ({ posts }) => {
  const { id } = useParams();
  const post = posts.find((p) => p.id === parseInt(id));

  if (!post) {
    return <h2>Post not found</h2>;
  }

  return (
    <div className="full-blog-post">
      {/* <h2>{post.title}</h2>
      <p>{post.content}</p>
      <p><em>By {post.author} on {post.date}</em></p> */}
      {/* <h2>{post.title}</h2> */}
      <p>{post.summary}</p>

      {/* <h2>{post.title0}</h2>
      <p>{post.summary0}</p> */}
      {/* <img src={post.image} alt="image" /> */}

      <p>{post.conclusions}</p>
      <p>
        <em>
          By {post.author} on {post.date}
        </em>
      </p>
    </div>
  );
};

export default FullBlogPost;
