import { useState } from "react";
// import "./faq.css";
// import '../../src/components/balifaq.css';
import "../../styles/faq.css";

const faqs = [
  {
    title: "What makes GoL Travels' Bali packages stand out?",
    text: "GoL Travels' Bali package­s stands out by offering fully Customizable packages.The­ir itineraries are de­signed to provide immersive­ experience­s that showcase the island's natural beauty, rich culture­, and heritage. With amenities and luxury accommodation. We also help in Visa process.",
  },
  {
    title: "Are the Bali trip packages from India customizable?",
    text: "Yes, the Bali trip package­s from India are fully customizable to cater to your spe­cific preference­s and requirements. Whe­ther you're a beach love­r, a water sport adventure see­ker, or a culture enthusiast, GoL Trave­ls can tailor your itinerary to include activities and e­xperiences that align with your inte­rest.",
  },
  {
    title: "How can I book Bali tour packages from Delhi with GoL Travels?",
    text: "Booking your Bali tour package from Delhi with GoL Travels is easy. Simply visit our website, goltravel.com and browse through our range of Bali packages, each offering detailed descriptions of inclusions, activities, and accommodations. Once you've found the perfect package, you can easily book online or reach out to our dedicated customer support team for assistance. We're here to make the booking process seamless and stress-free for you.",
  },
  {
    title: "Are there special Bali trip packages for couples available?",
    text: "Absolutely! Our Bali trip packages for couples are meticulously crafted to create unforgettable romantic experiences. From private candlelit dinners on the beach to special water activities. Luxury accommodations and fully cusotmized packages. We offer special discounts for couples and group tours",
  },
  {
    title: "Do you provide Bali honeymoon packages?",
    text: "Yes, we specialize in creating Bali honeymoon packages that are tailored to celebrate the love and romance of newlyweds. Our honeymoon packages are designed to provide couples with intimate experiences, luxurious accommodations, and unforgettable moments together. From romantic beachside dinners to private villa all inclusive packages are available.",
  },
  {
    title: " Are there any discounts available on Bali tour packages?",
    text: "Yes, we occasionally offer special promotions and discounts on our Bali tour packages to provide our customers with great value for their money. These discounts may vary depending on the season, availability, and specific package offerings.",
  },
  {
    title: " Are meals included in Bali packages from GoL Travels?",
    text: "Meal inclusions vary depending on the specific package you choose. Some of our Bali packages may include daily breakfast, while others may offer additional meals such as lunch or dinner at select restaurants or accommodations.",
  },

  {
    title: "How do I book my Bali package online with GoL Travels?",
    text: "Booking your dream Bali package online with GoL Travels is quick and easy! Simply visit our website and browse through our wide range of Bali packages tailored to suit every traveler's needs and preferences. Once you've found the perfect Bali package, you can directly reach our to us on our provided contact details or leave a query form for us to reach out ot you at earliest",
  },
  {
    title: "What is in GoL Travels' Bali packages from Bangalore?",
    text: "GoL Travels' Bali package­s from Bangalore are tailored to the­ unique prefere­nces of travellers from the­ city. If you're seeking water activities, Culinary delights our best  Bali package offe­rings from Bangalore have something for e­veryone, ensuring a truly me­morable vacation.",
  },
  {
    title: "Can I find Bali packages from Surat with GoL Travels?",
    text: "We also provides a range­ of Bali packages from Surat. These package­s cater to the nee­ds and preference­s of Surat-based travellers, offe­ring convenience and value­ with seamless travel arrange­ments and carefully curated e­xperiences that showcase­ the best of Bali. Whethe­r you're planning a family vacation package, a romantic couple package getaway, or an adventure­-filled trip with friends, our Bali packages from Surat have­ got you covered.",
  },
  {
    title:
      "How can I get in touch with GoL Travels for further inquiries about Bali packages?",
    text: "Getting in touch with GoL Travels is easy! You can reach us through multiple channels, including our website, email, phone, or social media platforms. Our dedicated team of travel consultants is available to assist you with any inquiries you may have about our Bali packages, including itinerary customization, accommodation options, activity recommendations.",
  },
];

export default function BaliFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Bali Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
