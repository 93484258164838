import React from "react";
import Routers from "../../router/Routers";
import Footer from "../Footer/Footer";
import Header1 from "../Header/Header1";
import { EnquiryEmail } from "../EnquiryForm/EnquireEmail";

const Layout = () => {
  return (
    <>
      <Header1 />
      <Routers />
      <Footer />
      {/* <EnquiryEmail /> */}
    </>
  );
};

export default Layout;
