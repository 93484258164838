import React from "react";
import ServiceCard from "./ServiceCard";
import { Col } from "reactstrap";

import weatherImg from "../assets/images/weather.png";
import guideImg from "../assets/images/guide.png";
import customizationImg from "../assets/images/customization.png";

const servicesData = [
  {
    imgUrl: weatherImg,
    title: "Tailored Getaways",
    desc: "A getaway that's uniquely yours with our personalized itineraries. From hand-picking the perfect destination to curating every detail, we ensure your dream vacation comes to life. Whether you seek relaxation, adventure, or cultural exloration, our itineraries cater to your specific desires, making every moment memorable.",
  },
  {
    imgUrl: customizationImg,
    title: "Cost-Efficient Luxury",
    desc: "Enjoy the luxuries of travel without overspending with our budget-friendly packages. We focus on giving you the best value for your money, so you can make the most of your vacation budget. From finding wallet-friendly places to stay to careful planning, we make sure everyone can experience luxury without sacrificing quality. ",
  },
  {
    imgUrl: guideImg,
    title: "Guided Tours ",
    desc: "Enjoy peace of mind knowing that our team is available around the clock to assist you. Whether you have questions, need assistance, or want to make last-minute changes, we're here for you. Your comfort is our commitment, and we strive to make your vacation as stress-free and enjoyable as possible, from start to finish. ",
  },
];

const ServiceList = () => {
  return (
    <>
      {servicesData.map((item, index) => (
        <Col lg="3" key={index}>
          <ServiceCard item={item} />
        </Col>
      ))}
    </>
  );
};

export default ServiceList;
