import React from "react";
import { Col } from "reactstrap";
import TourCard from "../../shared/TourCard";

import { maldives } from "../../assets/data/tours";
// import MaldivesCard from "../../shared/MaldivesCard";

const MaldivesTourList = () => {
  return (
    <>
      {maldives?.map((maldive) => (
        <Col lg="3" md="6" sm="6" className="mb-4" key={maldive.id}>
          <TourCard tour={maldive} />
        </Col>
      ))}
    </>
  );
};

export default MaldivesTourList;
