import React from "react";
import "./trendingtag.css";

const TrendingTag = () => {
  return (
    <div className="trending-tag">
      Trending <i class="ri-fire-line"></i>
    </div>
  );
};

export default TrendingTag;
