import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { EnquiryEmail } from "./EnquireEmail";
import "./enquire.css";

const AutoLaunchModal = (e) => {
  //   e.preventDefault();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []); // Run only once on component mount
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      // backdrop="static"
      className="modal"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body className="modal.body">
        <EnquiryEmail />
        <Button
          className="close-button"
          variant="secondary"
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button
          className="close-button"
          variant="secondary"
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
        // <Button variant="primary" onClick={() => setShowModal(false)}>
        Save Changes
      </Button> //
      </Modal.Footer> */}
    </Modal>
  );
};

export default AutoLaunchModal;
