import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./enquire.css";
import { useNavigate } from "react-router-dom";

// import "./enquireEmail.css";

// import './enquireemail.css';

export const EnquiryEmail = () => {
  const [showForm, setShowForm] = useState(false);
  const form = useRef();
  const navigate = useNavigate();

  // const handleCancel = () => {
  //   // Hide the form by setting showForm to false
  //   setShowForm(false);
  // };

  const handleFormSubmit = (e) => {
    setShowForm(false);
    // e.preventDefault();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    //   emailjs
    //     .sendForm("service_9mh32bh", "template_iucwdt1", form.current, {
    //       publicKey: "9BO9CFVCt4ge1O4gY",
    //     })
    //     .then(
    //       () => {
    //         console.log("SUCCESS!");
    //         // navigate("/contact/");
    //         // e.target.reset();
    //       },
    //       (error) => {
    //         console.log("FAILED...", error.text);
    //       }
    //     );
    // };
    emailjs
      .sendForm("service_9mh32bh", "template_iucwdt1", form.current, {
        publicKey: "9BO9CFVCt4ge1O4gY",
      })
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          console.log("Message Sent");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail} className="inquiry-form-container">
        <h1 className="headline">Enquiry Now</h1>
        <label>Name</label>
        <input type="text" name="user_name" required />

        <label>City of residence</label>
        <input type="text" name="user_city" required />

        <label>Email</label>
        <input type="email" name="user_mail" required />

        <label>Mobile Number</label>
        <input type="tel" name="user_number" maxLength={10} required />

        <label htmlFor="email">Choose Destinations:</label>
        <input
          type="text"
          list="options"
          id="destination"
          name="user_destination"
          required
        />
        <datalist id="options">
          <option value="Maldives" />
          <option value="Lakshadweep" />
          <option value="Thailand" />
          <option value="Bali, Indonesia" />
          <option value="Malyasia" />
          <option value="Srilanka" />
        </datalist>

        <label>Number of Pax</label>
        <input type="text" name="user_people" required />
        <button
          className="enquiry-button"
          type="submit"
          onClick={handleFormSubmit}
        >
          Submit
        </button>
        {/* <input type="submit" value="Send" /> */}
      </form>
    </div>
  );
};
