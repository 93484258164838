import React, { useState, useEffect } from "react";
import { CommonSection3 } from "../shared/CommonSection";
import "../styles/tour.css";
import TourCard from "../shared/TourCard";
import SearchBar from "../shared/SearchBar";
import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
import { thailand } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import ThailandFaq from "../components/Faq/ThailandFaq";

// import useFetch from "../hooks/useFetch";
// import { BASE_URL } from "../utils/config";
const Thailand = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  // const {
  //   data: tours,
  //   loading,
  //   error,
  // } = useFetch(`${BASE_URL}/tours?page=${page}`);
  // const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>
          Thailand tour package for family from India | Budget-Friendly
          thailand Packages
        </title>
        <meta
          name="description"
          content="Dive into luxury on a budget trip with our best-price packages. Perfect for solo adventurers or bachelor getaways!"
        />
        <meta
          name="keywords"
          content="thailand travel packages, thailand vacation packages all inclusive, thailand honeymoon packages, thailand tours package, thailand vacation package, thailand all inclusive packages, thailand packages, thailand travel package, thailand vacations packages, travel thailand packages, thailand family package, thailand family tour package, thailand family holiday packages, thailand family tour packages from india, family trip package to thailand, thailand family packages from mumbai, thailand family trip package, dpauls thailand family package, family holiday packages phuket thailand, thailand family packages from delhi"
        />
      </Helmet>
      <CommonSection3 title={"Thailand Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading...</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            {thailand?.map((tour) => (
              <Col lg="3" className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}
            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Discover the exotic charm of Thailand through our
                  all-inclusive package. From the busy streets of Bangkok to the
                  peaceful beaches of Phuket, Thailand offers diverse
                  experiences for all types of travelers. Enjoy delicious street
                  foods, explore ancient temples, or relax on pristine beaches -
                  the possibilities are endless. With our package, you can
                  immerse yourself in Thailand's rich culture, vibrant
                  nightlife, and beautiful nature that make it a top
                  destination. Don't miss out on this unforgettable adventure,
                  book your Thailand package now and create memories to last a
                  lifetime.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ThailandFaq />
      <Newsletter />
    </>
  );
};

export default Thailand;
