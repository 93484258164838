import React from "react";
import BlogPostCard from "./BlogPostCard";
import "./blog.css";

const GolBlogList = ({ posts }) => {
  console.log(posts.title);
  return (
    <div className="row">
      {posts.map((post) => (
        <div key={post.id} className="blog-post-card col-md-3">
          <BlogPostCard
            id={post.id}
            image={post.image}
            title={post.title}
            // summary={post.summary}
            author={post.author}
            date={post.date}
          />
        </div>
      ))}
    </div>
  );
};

export default GolBlogList;
