import tourImg01 from "../images/packages_imgs/lakshadweep/samudram.webp";
import tourImg02 from "../images/packages_imgs/lakshadweep/bangaram.webp";
import tourImg03 from "../images/packages_imgs/lakshadweep/Wooden_Hut.webp";
import tourImg04L from "../images/packages_imgs/lakshadweep/agatti_island.webp";
import tourImg04 from "../images/packages_imgs/maldives/Dhiffushi_island.webp";
import tourImg05 from "../images/packages_imgs/maldives/Thulusdhoo_island.webp";
import tourImg06 from "../images/packages_imgs/maldives/Thulusdhoo_Dhiffushi_Island.webp";
import tourImg07 from "../images/packages_imgs/maldives/Maldives_Premium_Honeymoon.png";
import tourImg08 from "../images/packages_imgs/maldives/Personalised_island_experiences.jpg";
import tourImg09 from "../images/packages_imgs/maldives/Maldives_Submarine.webp";
import tourImg10 from "../images/packages_imgs/maldives/Splendid_Serenity.webp";
import tourImg11 from "../images/packages_imgs/maldives/Honeymoon_Hideaway_Experience_Two_Island.webp";
import tourImg12 from "../images/packages_imgs/bali/Bali_Odyssey.webp";
import tourImg13 from "../images/packages_imgs/bali/Bali_Island_Odyssey.webp";
import tourImg19 from "../images/packages_imgs/bali/BaliFamily_Adventure.webp";
import tourImg20 from "../images/packages_imgs/thailand/Bangkok_Odyssey.webp";
import tourImg16 from "../images/packages_imgs/thailand/Pattaya_Delight.webp";
import tourImg17 from "../images/packages_imgs/thailand/Pattaya&Bangkok_Escapade.webp";
import tourImg21 from "../images/packages_imgs/malaysia/Malaysian_Marvels.webp";
import tourImg22 from "../images/packages_imgs/malaysia/Malaysian_Odyssey.webp";
import tourImg23 from "../images/packages_imgs/malaysia/Kuala_Lumpur.webp";
import tourImg24 from "../images/sliding_packages_imgs/lakshadsweep.webp";
import tourImg25 from "../images/sliding_packages_imgs/maldives.webp";
import tourImg26 from "../images/sliding_packages_imgs/bali.webp";
import tourImg27 from "../images/sliding_packages_imgs/thailand.webp";
import tourImg28 from "../images/sliding_packages_imgs/malaysia.webp";
import tourImg29 from "../images/packages_imgs/srilanka/train_bridge.jpg";
import tourImg30 from "../images/packages_imgs/srilanka/monument.jpg";

export const Lakshadweeps = [
  {
    id: "Samudram",
    title: "Samudram",
    city: "Lakshadweep",
    price: 39375,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 10,
    description: "this is the description",
    reviews: [],
    avgRating: 4.5,
    photo: tourImg01,
    featured: true,
  },
  {
    id: "Bangaram",
    title: "Bangaram Bliss (AC Cottage)",
    city: "Lakshadweep",
    distance: 400,
    price: 68082,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg02,
    featured: true,
    metaTitle: "Lakshadweep",
    metaDesc:
      "Plan your journey with GoL Travels' Lakshadweep Samudram Package. Find out which package is best for your dream getaway to Lakshadweep islands. Book Best Hotels & Resorts",
  },
  {
    id: "Bangaram_premium",
    title: "Bangaram Premium  (AC Wooden)",
    city: "Lakshadweep",
    distance: 500,
    price: 86982,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg03,
    featured: true,
    metaTitle:
      "Lakshadweep Samudram package |Which package is best for Lakshadweep|",
    metaDesc:
      "Plan your journey with GoL Travels' Lakshadweep Samudram Package. Find out which package is best for your dream getaway to Lakshadweep islands. Book Best Hotels & Resorts",
  },
  {
    id: "Agatti",
    title: "Agatti Island",
    city: "Lakshadweep",
    distance: 500,
    price: 46000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04L,
    featured: true,
    metaTitle:
      "Lakshadweep Samudram package |Which package is best for Lakshadweep|",
    metaDesc:
      "Plan your journey with GoL Travels' Lakshadweep Samudram Package. Find out which package is best for your dream getaway to Lakshadweep islands. Book Best Hotels & Resorts",
  },
];

export const maldives = [
  {
    id: "Dhiffushi",
    title: "Dhiffushi Island",
    city: "Maldives",

    distance: 500,
    price: 47099,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Thulusdhoo",
    title: "Thulusdhoo Island",
    city: "Maldives",
    distance: 500,
    price: 55599,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: true,
  },
  {
    id: "Dhiffushi&Thulusdhoo",
    title: "Dhiffushi & Thulusdhoo Island",
    city: "Maldives",
    distance: 500,
    price: 69999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: true,
  },
  {
    id: "Maldives_Premium_Honeymoon",
    title: "Maldives Premium Honeymoon",
    city: "Maldives",
    distance: 500,
    price: 164000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: true,
  },
  {
    id: "Personalized_Island_Experience",
    title: "Personalized Island Experience, Maldives",
    city: "Maldives",
    distance: 500,
    price: 50594,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg08,
    featured: true,
  },
  {
    id: "Maldives_Submarine_Dive",
    title: "Maldives Submarine Dive Discovery Voyage Package",
    city: "Maldives",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg09,
    featured: true,
  },
  {
    id: "Splendid_Serenity_Retreat",
    title: "Splendid Serenity Retreat Maldives Package",
    city: "Maldives",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg10,
    featured: true,
  },
  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience Two Island",
    city: "Maldives",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: true,
  },
];

export const bali = [
  {
    id: "Bali_Odyssey",
    title: "Bali Odyssey",
    city: "Bali",
    distance: 300,
    price: 53121,
    address: "Bali, Indonesia",
    packages: "7 Nights/6 days",
    maxGroupSize: 2,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg12,
    featured: true,
  },
  {
    id: "Bali_Island_Odyssey",
    title: "Bali Island Odyssey",
    city: "Bali",
    distance: 400,
    price: 42840,
    address: "Bali, Indonesia",
    packages: "6 Nights/5 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg13,
    featured: true,
  },
  {
    id: "BaliFamily_Adventure",
    title: "BaliFamily Adventure",
    city: "Bali",
    distance: 500,
    price: 81872,
    address: "Bali, Indonesia",
    packages: "8 Nights/7 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg19,
    featured: true,
  },
];

export const thailand = [
  {
    id: "Bangkok_Odyssey",
    title: "Bangkok Odyssey",
    city: "Thailand",
    distance: 300,
    price: 20753,
    address: "Thailand",
    packages: "3 Nights/4 days",
    maxGroupSize: 1,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg20,
    featured: true,
  },
  {
    id: "Pattaya_Delight",
    title: "Pattaya Delight",
    city: "Thailand",
    distance: 400,
    price: 24561,
    address: "Thailand",
    packages: "5 Nights/6 days",
    maxGroupSize: 1,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg16,
    featured: true,
  },
  {
    id: "Pattaya&Bangkok_Escapade",
    title: "Pattaya & Bangkok Escapade",
    city: "Thailand",
    distance: 500,
    price: 31321,
    address: "Thailand",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg17,
    featured: true,
  },
];

export const malaysia = [
  {
    id: "Malaysian_Marvels",
    title: "Malaysian Marvels",
    city: "Malaysia",
    distance: 300,
    price: 54850,
    address: "Malaysia",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg21,
    featured: true,
  },
  {
    id: "Malaysian_Odyssey",
    title: "Malaysian Odyssey",
    city: "Malaysia",
    distance: 400,
    price: 24561,
    address: "Malaysia",
    packages: "5 Nights/6 days",
    maxGroupSize: 1,
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg22,
    featured: true,
  },
  {
    id: "Kuala_Lumpur",
    title: "Kuala Lumpur Arrival Bliss",
    city: "Malaysia",
    distance: 500,
    price: 24500,
    address: "Malaysia",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

export const srilanka = [
  {
    id: "Srilanka_one",
    title: "Sri Lanka Ancient Wonders",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg29,
    featured: true,
  },
  {
    id: "Srilanka_two",
    title: "Sri Lanka Unveiled Eternal Memories",
    city: "Srilanka",
    distance: 300,
    price: 142961,
    address: "Srilanka",
    packages: "8 Nights/9 days",
    maxGroupSize: 2,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg30,
    featured: true,
  },
];

export const visaFree = [
  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience",
    city: "Maldives",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: true,
  },
  {
    id: "BaliFamily_Adventure",
    title: "BaliFamily Adventure",
    city: "Bali, Indonatia",
    distance: 500,
    price: 81872,
    address: "Bali, Indonesia",
    packages: "8 Nights/7 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg19,
    featured: true,
  },
  {
    id: "Pattaya&Bangkok_Escapade",
    title: "Pattaya & Bangkok Escapade",
    city: "Thailand",
    distance: 500,
    price: 31321,
    address: "Thailand",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg17,
    featured: true,
  },
  {
    id: "Kuala_Lumpur",
    title: "Kuala Lumpur Arrival Bliss",
    city: "Malaysia",
    distance: 500,
    price: 31321,
    address: "Malaysia",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

export const coralDest = [
  {
    id: "Samudram",
    title: "Samudram",
    city: "Lakshadweep",
    distance: 300,
    price: 39375,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 10,
    desc: "this is the description",
    reviews: [],
    avgRating: 4.5,
    photo: tourImg01,
    featured: true,
  },
  {
    id: "Thulusdhoo",
    title: "Thulusdhoo Island",
    city: "Maldives",
    distance: 500,
    price: 55599,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: true,
  },
  {
    id: "Bali_Odyssey",
    title: " Bali Odyssey",
    city: "Bali",
    distance: 300,
    price: 53121,
    address: "Bali, Indonesia",
    packages: "7 Nights/6 days",
    maxGroupSize: 2,
    reviews: [],
    avgRating: 4.5,
    photo: tourImg12,
    featured: true,
  },
  {
    id: "Pattaya_Delight",
    title: "Pattaya Delight",
    city: "Thailand",
    distance: 400,
    price: 24561,
    address: "Thailand",
    packages: "5 Nights/6 days",
    maxGroupSize: 1,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg16,
    featured: true,
  },
];

export const newPackages = [
  {
    id: "12",
    city: "Lakshadweep",
    photo: tourImg24,
    featured: true,
  },
  {
    id: "13",
    city: "Maldives",
    photo: tourImg25,
    featured: true,
  },
  {
    id: "14",
    city: "Bali",
    photo: tourImg26,
    featured: true,
  },
  {
    id: "15",
    city: "Thailand",
    photo: tourImg27,
    featured: true,
  },
  {
    id: "16",
    city: "Malaysia",
    photo: tourImg28,
    featured: true,
  },
  {
    id: "17",
    city: "Srilanka",
    photo: tourImg29,
    featured: true,
  },
];

export const LakshadweepTours = [
  {
    id: "Samudram",
    title: "Samudram",
    city: "Lakshadweep",
    distance: 300,
    price: 39375,
    address: "Somewhere",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    h2: "Sail on a Leisurely Adventure with GoL Travels' Samudram Package",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Your Samudram Package journey begins with a smooth security check at the reporting center, followed by a comfortable bus ride to the ship. Once onboard, officials will guide you to your cabins, which can be either in the Diamond or Gold class, depending on your booking. At 12:30 PM, you can enjoy a delicious buffet lunch in the first-class cafeteria. The ship will set sail for the Lakshadweep islands between 2:30 and 3:00 PM, and you'll enjoy a mesmerizing 14 to 16-hour journey across the sea. As you sail under the night sky, with stars twinkling above, this magical experience will mark the beginning of your Lakshadweep Samudram package adventure.",
      Day2: "Day-02",
      Desc2:
        "Welcome to Lakshadweep with this amazing Samudram Package! Upon arrival at one of the islands, you'll board a boat to the jetty, where you'll be greeted with a traditional welcome and a refreshing drink at the resort. The day is yours to explore the island and participate in water sports such as snorkeling, kayaking, and sea bathing. Optional scuba diving is also available under expert supervision. After a hearty lunch, you can explore the island's unique culture and sights. A relaxing afternoon tea follows before you head back to the ship. Dinner will be served in the first-class cafeteria, rounding off your day on this Lakshadweep Samudram package for LTC as per terms and conditions.",
      Day3: "Day-03",
      Desc3:
        "The ship will dock at the next island early in the morning, and a delicious buffet breakfast will be served on board. Disembark at 8:00 AM, and continue your adventure with the same exciting activities as the previous day. Each island offers a unique experience with its distinct culture and scenery. After a full day of exploration, you'll return to the ship in the evening. Enjoy a delightful buffet dinner as you prepare for the next leg of your Lakshadweep Samudram package journey. You can also book the Lakshadweep package if your are looking to customize your travel adventure.",
      Day4: "Day-04",
      Desc4:
        "You'll arrive at the third island early in the morning, with breakfast served on board. The day's activities will mirror those of the previous islands, allowing you to fully immerse yourself in the local culture and beauty. After a day of fun and relaxation, return to the ship for dinner. You'll spend the night onboard as the ship sails back to Kochi.",
      Day5: "Day-05",
      Desc5:
        "Your enchanting Lakshadweep Samudram package adventure concludes as the ship docks in Kochi around 10:00 to 10:30 AM. Enjoy a final breakfast onboard, marking the end of your unforgettable experience. This all-inclusive Samudram package ensures a seamless journey filled with memories of diverse cultures, pristine landscapes, and vibrant activities. Thank you for choosing Samudram packages for this extraordinary exploration.",
    },
    inclusion: {
      point1:
        "Ship tickets, Accommodation in AC cabin (Diamond Class), Three Times Food",
      point2: "Three island Visit",
      point3: "Sight seeing with Vehicle and guide",
      point4:
        "3 Water sport Activities (Kayaking, Glass bottom Riding, Snorkeling)",
    },
    exclusion: {
      point1: "Scuba Diving",
      point2: "Airfare",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg01,
    featured: true,
  },
  {
    id: "Bangaram",
    title: " Bangaram Bliss (AC Cottage)",
    city: "Lakshadweep",
    distance: 400,
    price: 68082,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    h2: "Experience a Lifetime of Bliss with the Bangaram Lakshadweep Package",
    desc: {
      Day3: "Day-03 Custom-made Bangaram Lakshadweep package adventure",
      Desc3:
        "On this day of your Lakshadweep Package, embark on an island exploration at Bangaram, starting with captivating sightseeing in the morning. Discover the untouched beauty of nearby uninhabited islands like Thinnakara, Parali 1, and Parali 2 in the first half of the day. We also have the Lakshadweep package for LTC if you wish to customize your Lakshadweep package that can also be done. As the afternoon unfolds, you'll find tranquility at the sandbank area, soaking in the pristine allure until the enchanting sunset. The Samudram Package is also another fiesta that offers a seamless blend of adventure and relaxation, promising a day rich in exploration and natural wonders that you'll treasure forever.",
      Day1: "Day-01 From Arrival to Sunset: A Magical Journey to Bangaram Island",
      Desc1:
        "Upon arrival at Agatti Airport, you'll be warmly welcomed, setting the tone for your unforgettable adventure with the Lakshadweep Package. The journey begins with a scenic road transfer to the ferry jetty, where you'll embark on a one-hour boat ride through the open sea from Agatti to Bangaram. As you sail, keep an eye out for playful dolphins that might accompany you on your journey. Upon reaching the resort, you'll be greeted with a refreshing tender coconut drink. After freshening up and enjoying a delicious lunch, the rest of the day is yours to explore the breathtaking beauty of Bangaram Island. As part of this Lakshadweep Package for LTC, you'll be able to immerse yourself in the unspoiled natural beauty until the mesmerizing sunset.",
      Day2: "Day-02 Thrilling Watersports at Bangaram Island",
      Desc2:
        "Start your day with a delightful breakfast before diving into an aquatic adventure on the pristine shores of Bangaram Island. If you choose to visit the sea, included in your Samudram Package,  also offers a variety of thrilling watersports at our dedicated center. From snorkeling and kayaking to glass-bottom boat rides and turtle spotting, you'll have endless opportunities to explore the vibrant marine life. Optional scuba diving is also available for those seeking a deeper adventure. This Bangaram Lakshadweep Package ensures a day filled with excitement and discovery, making it a perfect choice for water enthusiasts looking to experience the best of Bangaram Island.",
      Day4: "Day-04 Departing Bangaram with Lifelong Memories",
      Desc4:
        "After breakfast, it’s time to bid farewell to the enchanting Bangaram Island. As part of your Lakshadweep Package, a speedboat will take you back to Agatti Island, where you'll then proceed to the airport for your departure. As you leave this tiny paradise behind, you'll carry with you a lifetime of memories. The idyllic beauty and unique experiences of Bangaram Island if you also choose Lakshadweep Package for LTC as the ultimate package, will remain etched in your heart, marking the end of an unforgettable island escape.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg02,
    featured: true,
  },
  {
    id: "Bangaram_premium",
    title: "Bangaram Premium (AC Wooden Hut)",
    city: "Lakshadweep",
    distance: 500,
    price: 86982,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    h2: "Bangaram Premium is the ultimate Lakshadweep Package for your amazing adventure",
    desc: {
      Day3: "Day-03 Discover Tranquil and Adventurous Islands",
      Desc3:
        "“Continue your adventure with the Lakshadweep Package for LTC as you explore the nearby islands around Bangaram. Your day begins with visits to the uninhabited gems of Thinnakara, Parali 1, and Parali 2, where you can soak in the pristine beauty and untouched landscapes. In the afternoon, find peace in the serene sandbank area, enjoying the calm until sunset. This day, part of your Samudram Package, combines the thrill of discovery with moments of tranquility, making it a perfect blend of adventure and relaxation.",
      Day1: "Day-01 A Magical Start to Your Bangaram Island Journey",
      Desc1:
        "Your journey with the Lakshadweep Package begins as soon as you arrive at Agatti Airport, where a warm welcome awaits you. After a scenic drive, you'll board a boat for a one-hour journey to Bangaram Island, possibly accompanied by playful dolphins along the way. Upon arrival, you’ll be greeted with a refreshing drink that perfectly sets the tone for your stay. As part of this Samudram Package, you’ll have the rest of the day to explore the untouched beauty of Lakshadweep, ending with a captivating sunset that will create unforgettable memories in this tropical paradise.",
      Day2: "Day-02 Dive into the Aquatic Wonders of Bangaram",
      Desc2:
        "Start your day with a delightful breakfast, and then get ready for a day of aquatic excitement with the Lakshadweep Package for LTC. Bangaram Island offers an array of thrilling watersports (available at an additional cost) at our watersports center. Whether you're snorkeling among vibrant coral reefs, kayaking along the calm shores, or enjoying a glass-bottom boat ride, there’s plenty to discover. For the more adventurous, scuba diving is also available. This Samudram Package promises a day packed with excitement and exploration beneath the clear, sunlit waters of Bangaram.",
      Day4: "Day-04 A Fond Farewell to Bangaram Island",
      Desc4:
        "As your Lakshadweep Package comes to an end, enjoy a final breakfast before bidding farewell to Bangaram Island. A speedboat will take you back to Agatti Island, where you’ll head to the airport for your departure. As you leave, take with you the memories of a lifetime from this tropical paradise. The experiences offered by your Samudram Package will leave a lasting impression, ensuring that the idyllic beauty of Bangaram remains with you long after your journey concludes.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg03,
    featured: true,
  },
  {
    id: "Agatti",
    title: "Agatti Island",
    city: "Lakshadweep",
    distance: 500,
    price: 46000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    h2: "Have fun and enjoy the memorable adventure that awaits you with GoL travels Lakshadwep  Agatti island package",
    desc: {
      Day3: "Day-03",
      Desc3:
        "Experience a day of exploration with the Lakshadweep Package for LTC at Bangaram. Start with captivating sightseeing of the nearby uninhabited islands—Thinnakara, Parali 1, and Parali 2. As the day progresses, find tranquility in the serene sandbank area, enjoying its pristine allure until the mesmerizing sunset. The Samudram Package is an ultimate choice that blends adventure and relaxation, promising a rich exploration of natural wonders and a peaceful retreat.",
      Day1: "Day-01",
      Desc1:
        "Begin your romantic journey with the Lakshadweep Package as you arrive at Agatti Airport, the gateway to Bangaram Island. After a scenic drive, you'll embark on a one-hour boat ride to Bangaram, where playful dolphins may accompany you. A refreshing welcome drink awaits, setting the stage for exploring the untouched beauty of Lakshadweep. With the choice of Lakshadweep Package for LTC, you'll enjoy a custom-made package itinerary as per the request to cater to your unique needs and requirements.",
      Day2: "Day-02",
      Desc2:
        "Savor a delightful breakfast and dive into the aquatic wonders offered by the Lakshadweep Package. At Bangaram Island, engage in thrilling watersports at our dedicated center (additional costs apply). Snorkel through vibrant coral gardens, kayak along serene coastlines, enjoy glass-bottom boat rides, and spot turtles. For the adventurous, scuba diving is available. The Samudram Package as the other Lakshadweep package option ensures that each sun-drenched day is an exhilarating adventure, making it a perfect choice for water enthusiasts seeking excitement and discovery.",
      Day4: "Day-04",
      Desc4:
        "After breakfast, it's time to bid farewell to the enchanting Bangaram Island. A speedboat will take you back to Agatti Island, where you'll then proceed to the airport. With the Lakshadweep Package, you'll leave with a lifetime of cherished memories from this tropical haven. The beauty of Bangaram Island, experienced through the Lakshadweep Package for LTC and the Samudram Package, will remain etched in your heart, marking the end of an unforgettable island escape.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04L,
    featured: true,
  },
];

export const MaldivesTours = [
  {
    id: "Adaaran-Club-Rannalhi",
    title: "Adaaran Club Rannalhi",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival, settle into your accommodation and take time to explore the amenities offered by the resort. Relax and unwind after your journey, immersing yourself in the tranquil atmosphere of your surroundings.",
      Day2: "Day-02",
      Desc2:
        "Start your day with a delicious breakfast at the resort before embarking on a day of adventure and romance. Engage in thrilling water activities such as snorkeling, diving, or jet skiing (available at an extra cost), creating unforgettable memories with your loved ones. In the evening, indulge in a romantic dinner or take a leisurely sunset stroll along the beach, soaking in the beauty of your surroundings.",
      Day3: "Day-03",
      Desc3:
        "Today is dedicated to leisure and relaxation as you immerse yourself in the natural beauty of the Maldivian beaches. Treat yourself to a pampering spa session, allowing the gentle sound of the waves to soothe your senses as skilled therapists work their magic. Take leisurely walks along the shore, feeling the soft sand between your toes and reveling in the serenity of your surroundings. Savor delicious meals prepared by talented chefs at the resort, indulging in the flavors of the island.",
      Day4: "Day-04",
      Desc4:
        "As your unforgettable journey comes to an end, it's time to bid farewell to the Maldives. Check out from your resort and transfer to the airport for your departure flight, carrying with you memories of sun-kissed beaches, azure waters, and moments of pure bliss.",
    },
    inclusion: {
      point1: "Accommodation on the selected basis.",
      point2: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },

  {
    id: "Dhiffushi",
    title: "Dhiffushi Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 47099,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "Day at Leisure -- Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral gardens and marine life of the Indian Ocean. Go scuba diving, surfing, snorkeling, and island hopping (at extra cost).",
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives – Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Day at Leisure – Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like snorkeling, scuba diving, windsurfing, canoeing etc. (at extra price). ",
      Day4: "Day-04",
      Desc4:
        "Departure from Maldives -- Enjoy a hearty breakfast and check-out from the hotel/resort as you embark on your journey back home. You will be transferred to Male International Airport to catch your fight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling ",
      point3:
        "Transportation, Complimentary candlelight dinner at Agatti, Passengers permit and Transportation ",
      point4:
        "Airport pick up & Drop by speed boat, One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Thulusdhoo",
    title: "Thulusdhoo Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 55599,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "Day at Leisure -- Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral gardens and marine life of the Indian Ocean. Go scuba diving, surfing, snorkeling, and island hopping (at extra cost). ",
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives – Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Day at Leisure – Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like snorkeling, scuba diving, windsurfing, canoeing etc. (at extra price). ",
      Day4: "Day-04",
      Desc4:
        "Departure from Maldives -- Enjoy a hearty breakfast and check-out from the hotel/resort as you embark on your journey back home. You will be transferred to Male International Airport to catch your fight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling ",
      point3:
        "Transportation, Complimentary candlelight dinner at Agatti, Passengers permit and Transportation ",
      point4:
        "Airport pick up & Drop by speed boat, One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: false,
  },
  {
    id: "Dhiffushi&Thulusdhoo",
    title: "Dhiffushi & Thulusdhoo Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 69999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: false,
  },
  {
    id: "Maldives_Premium_Honeymoon",
    title: "Maldives Premium Honeymoon",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 164000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: false,
  },
  {
    id: "Personalized_Island_Experience",
    title: "Personalized Island Experience, Maldives",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 50594,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg08,
    featured: false,
  },
  {
    id: "Maldives_Submarine_Dive",
    title: "Maldives Submarine Dive Discovery Voyage Package",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg09,
    featured: false,
  },
  {
    id: "Splendid_Serenity_Retreat",
    title: "Splendid Serenity Retreat Maldives Package",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg10,
    featured: false,
  },
  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience Two Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: false,
  },
];

export const BaliTours = [
  {
    id: "Bali_Odyssey",
    title: "Bali Odyssey",
    city: "Bali, Indonesia",
    distance: 300,
    price: 53121,
    address: "Bali, Indonesia",
    packages: "7 Nights/6 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "BALI ARRIVAL BLISS, Embark on your Bali adventure with a warm welcome at Ngurah Rai International Airport. Our attentive team ensures a seamless transfer to your accommodation, allowing you to unwind and acclimate to Bali's enchanting charm. Take the initial moments to immerse yourself in the island's ambiance, setting the tone for a memorable stay filled with cultural discoveries and natural wonders.",
      Day2: "Day-02",
      Desc2:
        "Embark on a cultural odyssey with Tampak Siring–Kintamani– Tegalalang. Immerse yourself in the sacred waters of Tirta Empul Temple and Tampak Siring. Indulge in a scenic lunch, marveling at the active volcano and Lake Batur in Kintamani. Uncover the art of Kopi luwak at a Bali coffee plantation. Conclude the day surrounded by the breathtaking beauty of the Tegalalang rice terraces, creating memories of Bali's rich cultural and natural tapestry",
      Day3: "Day-03",
      Desc3:
        "Embark on an exhilarating Nusa Penida Day Tour. Sail from Sanur Harbour to Nusa Penida, discovering the wonders of Angel Billabong, Broken Beach, and the iconic Kelingking Secret Point. Enjoy a delightful lunch at a local restaurant, followed by an exploration of the crystal-clear waters at Crystal Bay. As the day concludes, return to Bali, leaving you with vivid memories of Nusa Penida's natural splendor. ",
      Day4: "Day-04",
      Desc4:
        "Embark on a spiritual journey with Handara Gate - Ulun Danu - Tanah Lot. Marvel at the beauty of Beratan Lake Temple in Bedugul (Ulun Danu Beratan Temple), where serenity meets spirituality. Savor a delicious lunch at a local restaurant before witnessing the breathtaking sunset at the iconic tenah Lot Temple. Immerse Yourself in Bali's cultural and natural wonders, creating a day of tranquility and awe-inspiring vistas.",
      Day5: "Day-05",
      Desc5:
        "Embark on an adventure Wisnu Kencana - Uluwatu. with Water Sport – Garuda Dive into thrilling water sports at Tanjung Benoa, followed by a delectable lunch. Marvel at the iconic Garuda Wisnu Kencana statue, symbolizing Bali's cultural richness. Conclude your day at Uluwatu Temple, witnessing the mesmerizing Kecak Dance performance against the backdrop of a stunning Balinese sunset. Experience the perfect blend of adrenaline, artistry, and awe-inspiring vistas.",
      Day6: "Day-06",
      Desc6:
        "Bid adieu with the Departure package. Enjoy a leisurely breakfast at the hotel, savoring the last moments in Bali's embrace. With free time until check-out, relish the tranquility before bidding farewell. Our seamless transfer service ensures a stress-free journey to Ngurah Rai International Airport for your departure. Depart with cherished memories, leaving Bali with a sense of peace and gratitude for an unforgettable experience. ",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg12,
    featured: true,
  },
  {
    id: "Bali_Island_Odyssey",
    title: "Bali Island Odyssey",
    city: "Bali, Indonesia",
    distance: 300,
    price: 42840,
    address: "Bali, Indonesia",
    packages: "6 Nights/5 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon your arrival at Ngurah Rai International Airport, our friendly local representative will extend a warm welcome and assist with a seamless transfer to your hotel. Enjoy some leisure time to relax or explore the nearby areas, allowing you to settle in and embrace the charm of Bali from the moment you arrive. ",
      Day2: "Day-02",
      Desc2:
        "Indulge in a delightful day with our Tampak Siring– Kintamani–Tegalalang package. Begin with a satisfying breakfast at the hotel, followed by a visit to the sacred Tirta Empul Temple in Tampak Siring. Enjoy a scenic lunch overlooking the active volcano and Lake Batur in Kintamani. Discover the secrets of Kopi luwak at a Bali coffee plantation and marvel at the breathtaking Tegalalang rice terraces. Conclude the day with a comfortable overnight stay at the hotel",
      Day3: "Day-03",
      Desc3:
        "Embark on an unforgettable Nusa Penida Day Tour with our exclusive package. Start your day with a hearty breakfast before a seamless transfer to Sanur Harbour. Cruise to Nusa Penida and marvel at the stunning Angel Billabong, Broken Beach, and the iconic Kelingking Secret Point. Enjoy a delectable lunch, unwind at Crystal Bay, and return to Bali in the evening for a comfortable overnight stay at the hotel. ",
      Day4: "Day-04",
      Desc4:
        "Embark on an exhilarating Water Sport – Garuda WisnuKencana - Uluwatu adventure. After breakfast,venture to Tanjung Benoa for thrilling water sports likeBanana Boat, Parasailing, and Jet Ski (at own expense).Bali Bliss: A Day of Thrills,Culture, and Scenic Wonders Page.06www.goltravels.comRelish a delightful lunch at a local restaurant, marvelat the majestic Garuda Wisnu Kencana statue, andconclude the day with a visit to the iconic UluwatuTemple, featuring the mesmerizing Kecak Danceperformance. Retire to the hotel for a restful overnightstay.",
      Day5: "Day-05",
      Desc5:
        "Bid farewell to Bali with our Departure package. Enjoy a final breakfast at the hoteland cherish some leisure time until check-out.Our seamless transfer service will take you toNgurah Rai International Airport for yourdeparture. Relish the memories of yourenchanting Bali experience as you embark onyour journey home.",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg13,
    featured: true,
  },
  {
    id: "BaliFamily_Adventure",
    title: "Bali Family Adventure",
    city: "Bali, Indonesia",
    distance: 300,
    price: 81872,
    address: "Bali, Indonesia",
    packages: "8 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience a warm welcome as you step into the tropical paradise of Bali at Ngurah Rai International Airport. Our friendly local representatives will greet you with open arms, ensuring a seamless transfer to your accommodation. Take the time to acclimate to Bali's charm as you settle in, surrounded by the island's unique blend of vibrant culture and natural beauty. Get ready for a delightful journey filled with unforgettable moments. ",
      Day2: "Day-02",
      Desc2:
        "Embark on a thrilling Safari Journey through Indonesia, India, and Africa habitats at Bali Safari Park. Enjoy front-of-the-line access, mesmerizing aquarium exhibits, and captivating Animal & Elephant Shows. Immerse yourself in the cultural spectacle of the Bali Agung Show, followed by a delightful lunch. Dive into adventure at the Waterpark and Fun Zone before visiting the enchanting Tegenungan Waterfall —a perfect blend of wildlife wonders and natural beauty.",
      Day3: "Day-03",
      Desc3:
        "Embark on an adrenaline-packed day with our River Rafting – ATV Ride adventure. Conquer the apids of Ayung River, surrounded by breathtaking andscapes. Savor a delicious lunch at a local estaurant before gearing up for a thrilling ATV ide through scenic rice fields and plantations. eel the rush as you navigate the rugged terrains, reating memories of heart-pounding excitement in he heart of Bali's natural beauty. This actionpacked day promises an unforgettable fusion of dventure and stunning views. ",
      Day4: "Day-04",
      Desc4:
        "Embark on a day of digital wonders and family excitement with our Trans Studio Theme Park adventure. Immerse yourself in interactive technology, exploring Bali's nature at Bali Senses and experiencing the thrill of the first flying theatre in Indonesia—Flying Over Indonesia. Let the little ones delight in exciting rides at the Kids & Family Zone, featuring Formula Kart, Ferris Wheels, and more. Indulge in diverse cuisines at food stands throughout the park, ensuring a day filled with joy and culinary delights.",
      Day5: "Day-05",
      Desc5:
        "Bid farewell to Bali with our Departure package. Enjoy a final breakfast at the hoteland cherish some leisure time until check-out.Our seamless transfer service will take you toNgurah Rai International Airport for yourdeparture. Relish the memories of yourenchanting Bali experience as you embark onyour journey home.Dive into the thrill of water sports at Tanjung Benoa, where adventure awaits with activities like Banana Boat and Jet Ski. Visit the iconic Uluwatu Temple perched on a cliff, offering breathtaking ocean views and a captivating Kecak Dance performance. Conclude your day with a delectable seafood BBQ dinner on the shores of Jimbaran Beach, savoring the essence of Bali's sun, sea, and culinary delights.",
      Day6: "Day-06",
      Desc6:
        "Begin your day with a leisurely breakfast at the hotel. As the sun sets, embark on a magical Sunset Pirate Dinner Cruise from Benoa Harbor. Glide over the waters, enjoying breathtaking views and a delightful dinner onboard. Immerse yourself in the tranquility of the ocean, creating cherished memories as you sail into the enchanting Bali sunset.",
      Day7: "Day-07",
      Desc7:
        "Savor a final breakfast at your hotel, soaking in the essence of Bali. With leisure time until checkout, relish the last moments of your tropical getaway. Our seamless transfer service ensures a stress-free journey to Ngurah Rai International Airport for your departure. Depart with a heart full of memories, promising to return to the island paradise. Until we meet again, Bali bids you a fond farewell. ",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg19,
    featured: true,
  },
];

export const ThailandTours = [
  {
    id: "Bangkok_Odyssey",
    title: "Bangkok Odyssey",
    city: "Thailand",
    distance: 300,
    price: 20753,
    address: "Thailand",
    packages: "3 Nights/4 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience a seamless arrival with a private transfer from Bangkok Airport to the vibrant city. Check-in at 2:00 PM at Picnic or Tango Vibrant Bangkok Hotel, offering 3-star comfort. Later, join a Chao Phraya Cruise Dinner for an enchanting evening. Enjoy an overnight stay, including breakfast and dinner. Immerse yourself in the heart of Bangkok with this delightful package.",
      Day2: "Day-02",
      Desc2:
        "Begin your day with a hotel breakfast, setting the stage for an exciting adventure at Dream World with Snow Town, accompanied by lunch. Experience the thrill with a Super Visa on this joined transfer excursion. Conclude the day with an overnight stay at the hotel, ensuring a delightful blend of entertainment and relaxation.",
      Day3: "Day-03",
      Desc3:
        "Start your day with a hotel breakfast and embark on a captivating Bangkok Temple and City Tour, a joined transfer adventure. Explore the cultural wonders of the city, taking in its vibrant energy. Conclude the day with an overnight stay at the hotel, making it a perfect blend of exploration and comfort.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a morning breakfast at the hotel before checking out at noon. Experience a seamless departure with a private transfer from your hotel to Bangkok Airport. Relish the convenience and bid farewell to the city with cherished memories.",
    },
    inclusion: {
      point1: "3 Nights & 4 Days in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg20,
    featured: true,
  },
  {
    id: "Pattaya_Delight",
    title: "Pattaya Delight",
    city: "Thailand",
    distance: 300,
    price: 24561,
    address: "Thailand",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience seamless travel with a private arrival transfer from Bangkok Airport to Baron Beach Resort Pattaya or a similar 3- star hotel. Check in at 14:00 and embark on a Pattaya City Tour & Discovery, exploring captivating viewpoints with a join transfer. Enjoy an overnight stay at the hotel. Meals and other details to be arranged. Discover the best of Pattaya with comfort and style.",
      Day2: "Day-02",
      Desc2:
        "Start your day with a delightful breakfast at the hotel before embarking on a Coral Island Tour with lunch, facilitated by a join transfer. Experience the beauty of the island and savor a delicious meal. Return for an overnight stay at the hotel. Enjoy the perfect blend of adventure and relaxation.",
      Day3: "Day-03",
      Desc3:
        "Indulge in a delightful breakfast at the hotel, followed by a private transfer from your Pattaya hotel to Pinci Hotel or Tango Vibrant Hotel Bangkok, or a similar 3-star accommodation. Check in and unwind. Join a Chao Phraya Cruise Dinner for a memorable evening. Enjoy an overnight stay at the hotel with breakfast and dinner included.",
      Day4: "Day-04",
      Desc4:
        "Begin your day with a leisurely breakfast at the hotel, setting the tone for a day of free exploration and relaxation. Enjoy the freedom to tailor your day to your desires, whether it's exploring local attractions, indulging in spa treatments, or simply unwinding by the pool. Conclude your day with a peaceful overnight stay at the hotel.",
      Day5: "Day-05",
      Desc5:
        "Savor a delicious breakfast at the hotel before embarking on a hassle-free departure. Check out at 12:00 PM, and enjoy a private transfer from the hotel to Bangkok Airport. Relish the convenience of a smooth transition, ensuring a stress-free end to your stay. Bid farewell to Bangkok with fond memories.",
    },
    inclusion: {
      point1:
        "2 nights in Pattaya and 2 nights in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg16,
    featured: true,
  },
  {
    id: "Pattaya&Bangkok_Escapade",
    title: "Pattaya & Bangkok Escapade",
    city: "Thailand",
    distance: 300,
    price: 24561,
    address: "Thailand",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Embark on a seamless journey as a private transfer whisks you from Bangkok Airport to the inviting Baron Beach Resort Pattaya. The 3-star comfort welcomes you with a 2:00 PM check-in. Immerse yourself in Pattaya's allure through a city and discovery tour, curated to unveil scenic viewpoints and cultural gems. The day culminates in an overnight stay at the hotel, promising relaxation and comfort, setting the tone for the days to come.",
      Day2: "Day-02",
      Desc2:
        "Awaken to the aroma of a delightful breakfast, setting the stage for an excursion to Coral Island. Join a transfer for a day filled with sun-soaked beaches, water activities, and a sumptuous lunch. The island's allure unfolds before you, ensuring a memorable experience. The night brings tranquility and repose as you retreat to your Pattaya accommodation.",
      Day3: "Day-03",
      Desc3:
        "Whether exploring local attractions, unwinding at the hotel, or indulging in Pattaya's vibrant offerings, the leisure day promises a perfect balance of exploration and relaxation tailored to your desires.",
      Day4: "Day-04",
      Desc4:
        "After a morning breakfast, a private transfer takes you to the bustling city of Bangkok. Check into the stylish Pinci or Tango Vibrant Hotel, and later, embark on a Chao Phraya Cruise Dinner. The joined transfer ensures a magical evening along the river, offering stunning views of Bangkok's illuminated landmarks.",
      Day5: "Day-05",
      Desc5:
        "The day begins with breakfast, followed by a captivating venture into Dream World with Snow Town. Enjoy lunch, engage in Super Visa experiences, and retreat for the night in the vibrant city of Bangkok. The day promises a blend of amusement and cultural encounters.",
      Day6: "Day-06",
      Desc6:
        "Kickstart the day with a hearty breakfast before joining a captivating Bangkok Temple and City Tour. Delve into the historical and modern facets of the city, exploring cultural landmarks and hidden gems. The day concludes with another night of comfort in Bangkok, leaving you enriched with diverse experiences.",
      Day7: "Day-07",
      Desc7:
        "Conclude your memorable journey with a final breakfast at the hotel, followed by a 12:00 PM check-out. A private transfer ensures a seamless departure from your hotel to Bangkok Airport, marking the end of an unforgettable Thai adventure filled with exploration and relaxation. Cherish the memories crafted throughout this immersive experience.",
    },
    inclusion: {
      point1:
        "3 nights in Pattaya and 3 nights in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg17,
    featured: true,
  },
];

export const MalaysiaTours = [
  {
    id: "Malaysian_Marvels",
    title: "Malaysian Marvels",
    city: "Malaysia",
    distance: 300,
    price: 38250,
    address: "Malaysia",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival at Penang International Airport, indulge in seamless comfort with a swift transfer to your accommodation. Settle down and unwind, embracing the serenity of your new surroundings. As the evening unfolds, immerse yourself in the local charm. Take a leisurely walk, discovering the enchanting streets and savoring the atmosphere. An ideal start to your Penang experience, blending relaxation with the allure of this vibrant destination.",
      Day2: "Day-02",
      Desc2:
        "Begin your day in Penang with a delightful breakfast, energizing yourself for a captivating half-day city tour. Immerse in the cultural tapestry of Penang's iconic landmarks. As the sun sets, take leisurely evening walks, exploring the vibrant streets, and soaking in the local atmosphere. A perfect blend of exploration and relaxation awaits in Penang's enchanting cityscape.",
      Day3: "Day-03",
      Desc3:
        "Upon transferring to Langkawi, your gateway to paradise, seamlessly transition from travel to tranquility. Check into your accommodation and settle down, immersing yourself in the island's serene ambiance. As the day unfolds, embrace the beauty of Langkawi, whether it's lounging on pristine beaches or exploring its vibrant culture. A perfect start to a relaxing and enchanting stay in this tropical haven.",
      Day4: "Day-04",
      Desc4:
        "Commence your Langkawi journey with a delightful breakfast, energizing for the adventures ahead. Immerse in the island's charm with a half-day city tour, exploring its treasures and cultural gems. As the day transitions to evening, embrace the vibrant vibes of Langkawi. Whether strolling along the beach or enjoying local delights, your day promises a perfect blend of exploration and relaxation in this tropical haven.",
      Day5: "Day-05",
      Desc5:
        "Begin your farewell day in Langkawi with a hearty breakfast, savoring the island's flavors one last time. After check-out, enjoy a seamless airport transfer, reminiscing about the memories created. Fly back with the echoes of Langkawi's beauty in your heart—a perfect conclusion to a memorable journey filled with relaxation, exploration, and enchanting moments.",
    },
    inclusion: {
      point1:
        "Accommodation: Enjoy a 2-night stay in Langkawi, ensuring comfort throughout your visit.",
      point2:
        " Breakfast: Start your days with two complimentary breakfasts, savoring the island's flavors.",
      point3:
        "City Tour (SIC): Explore Langkawi's treasures with a half-day city tour, discovering its cultural gems.",
      point4:
        "Transfers APT/PEN/APT (SIC): Enjoy hassle-free transfers between the airport and Penang.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg21,
    featured: true,
  },
  {
    id: "Malaysian_Odyssey",
    title:
      "Malaysian Odyssey: A 7- DAY ADVENTURE THROUGH KUALA LUMPUR, PENANG, AND LANGKAWI",
    city: "Malaysia",
    distance: 300,
    price: 54850,
    address: "Malaysia",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arriving at Kuala Lumpur Airport, experience a seamless transition to relaxation with a swift transfer to your hotel. Check-in, settle down, and embrace the comfort of your new surroundings. As the evening unfolds, immerse yourself in the vibrant atmosphere of Kuala Lumpur, whether it's exploring the city lights, indulging in local cuisine, or simply unwinding. The perfect start to your Kuala Lumpur adventure awaits.",
      Day2: "Day-02",
      Desc2:
        "Kickstart your day in Kuala Lumpur with a hearty breakfast, energizing yourself for the adventures ahead. Immerse in the city's charm with a captivating Kuala Lumpur city tour, exploring its iconic landmarks and cultural gems. As the day transitions to evening, relish the vibrant atmosphere, whether it's exploring cityscapes, enjoying local cuisine, or simply unwinding. A perfect blend of exploration and relaxation awaits in the heart of Kuala Lumpur.",
      Day3: "Day-03",
      Desc3:
        "Penang Arrival Bliss: Indulge in seamless comfort with a swift transfer to your accommodation. Settle down and unwind, embracing the serenity of your new surroundings. As the evening unfolds, immerse yourself in the local charm. Take a leisurely walk, discovering the enchanting streets and savoring the atmosphere. An ideal start to your Penang experience, blending relaxation with the allure of this vibrant destination.",
      Day4: "Day-04",
      Desc4:
        "Begin your day in Penang with a delightful breakfast, energizing yourself for a captivating half-day city tour —Immerse in the cultural tapestry of Penang's iconic landmarks. As the sun sets, take leisurely evening walks, exploring the vibrant streets, and soaking in the local atmosphere. A perfect blend of exploration and relaxation awaits in Penang's enchanting cityscape.",
      Day5: "Day-05",
      Desc5:
        "Upon transferring to Langkawi, your gateway to paradise, seamlessly transition from travel to tranquility. Check into your accommodation and settle down, immersing yourself in the island's serene ambiance. As the day unfolds, embrace the beauty of Langkawi, whether it's lounging on pristine beaches or exploring its vibrant culture. A perfect start to a relaxing and enchanting stay in this tropical haven.",
      Day6: "Day-06",
      Desc6:
        "Commence your Langkawi journey with a delightful breakfast, energizing for the adventures ahead. Immerse in the island's charm with a half-day city tour, exploring its treasures and cultural gems. As the day transitions to evening, embrace the vibrant vibes of Langkawi. Whether strolling along the beach or enjoying local delights, your day promises a perfect blend of exploration and relaxation in this tropical haven.",
      Day7: "Day-07",
      Desc7:
        "Begin your farewell day in Langkawi with a hearty breakfast, savoring the island's flavors one last time. After check-out, enjoy a seamless airport transfer, reminiscing about the memories created. Fly back with the echoes of Langkawi's beauty in your heart—a perfect conclusion to a memorable journey filled with relaxation, exploration, and enchanting moments.",
    },
    inclusion: {
      point1: "2 Nights accommodation.",
      point2: "2 Breakfasts.",
      point3: "Half-Day City Tour on a Shared (SIC) basis.",
      point4: "Airport to Hotel and back on a Shared (SIC) basis.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg22,
    featured: true,
  },
  {
    id: "Kuala_Lumpur",
    title:
      "Kuala Lumpur Arrival Bliss: Unwinding in the Heart of the City Lights",
    city: "Malaysia",
    distance: 300,
    price: 24500,
    address: "Malaysia",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arriving at Kuala Lumpur Airport, experience a seamless transition to relaxation with a swift transfer to your hotel. Check-in, settle down, and embrace the comfort of your new surroundings. As the evening unfolds, immerse yourself in the vibrant atmosphere of Kuala Lumpur, whether it's exploring the city lights, indulging in local cuisine, or simply unwinding. The perfect start to your Kuala Lumpur adventure awaits.",
      Day2: "Day-02",
      Desc2:
        "Kickstart your day in Kuala Lumpur with a hearty breakfast, energizing yourself for the adventures ahead. Immerse in the city's charm with a captivating Kuala Lumpur city tour, exploring its iconic landmarks and cultural gems. As the day transitions to evening, relish the vibrant atmosphere, whether it's exploring cityscapes, enjoying local cuisine, or simply unwinding. A perfect blend of exploration and relaxation awaits in the heart of Kuala Lumpur.",
      Day3: "Day-03",
      Desc3:
        "Immerse yourself in a day filled with rich culture and delectable cuisine. Explore cultural landmarks, museums, or traditional markets to absorb the essence of the destination. Indulge in the diverse culinary offerings, savoring local delicacies that showcase the authentic flavors of the region. Let your senses guide you through a day of cultural exploration and gastronomic delights, creating unforgettable memories.",
      Day4: "Day-04",
      Desc4:
        "On your farewell day, savor a final breakfast, relishing the flavors one last time. Complete your stay with a hassle-free check-out, ensuring a seamless departure. Fly back with cherished memories, as the experiences of your journey linger. This farewell day encapsulates the essence of your stay, leaving you with a tapestry of moments to carry home.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3:
        "Seamless transfers from the airport to the hotel and back on a Shared (SIC) basis.",
      point4:
        "Explore Kuala Lumpur's iconic landmarks with a captivating half-day city tour on Shared (SIC) transport.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

export const SrilankaTours = [
  {
    id: "Srilanka_one",
    title: "Sri Lanka",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon your arrival at Bandaranaike International Airport, you'll be greeted by your friendly national guide from Trip with the traditional Sri Lankan greeting, Ayubowan, meaning May you live long. They ll be there to assist you and ensure a smooth transition to your accommodation in the vibrant city of Colombo. Relax and unwind at the Ocean Colombo Hotel, where you'll spend the night.",

      Day2: "Day-02",
      Desc2:
        "After indulging in a hearty breakfast, embark on a scenic journeyto Udawalawa via Ratnapura. Along the way, immerse yourselfin the rich culture and history of Sri Lanka with a visit toRatnapura Maha Saman Dewalaya, an ancient temple dedicatedto Lord Saman. Delve into the fascinating world of gem mining,an integral part of Sri Lanka's heritage. Spend the night at thetranquil Craft Tunnel Hotel",

      Day3: "Day-03",
      Desc3:
        "Start your day with a delicious breakfast before diving into animmersive cultural experience. Engage in pottery crafting with Mr.Ajith Perera, a master of the craft, who will guide you through theintricate process of creating traditional pottery. Later, enjoy acaptivating Cookery Demonstration and be enthralled by a vibrantTraditional Dance performance. For wildlife enthusiasts, an optionalvisit to Udawalawa National Park offers the chance to encounterdiverse flora and fauna in their natural habitat",

      Day4: "Day-04",
      Desc4:
        "After a rejuvenating breakfast, depart for the coastalparadise of Mirissa. Indulge in a day of leisure, soakingup the sun and sea breeze at the pristine beaches.Unwind amidst the serene surroundings of Salt MirissaHotel, where you'll spend two nights",

      Day5: "Day-05",
      Desc5:
        "Explore the enchanting attractions of Mirissa after breakfast.Venture to Blue Island, a secluded paradise surrounded byturquoise waters, and marvel at the breathtaking Coconut Hills.Experience the ancient art of Stilt Fishing, a traditional fishingmethod practiced by local fishermen. For those seekingadventure, snorkeling offers the opportunity to discover thevibrant marine life thriving beneath the waves",

      Day6: "Day-06",
      Desc6:
        "Embark on an enchanting journey to Bentota after breakfast. Discover the historic charm of Galle with a day trip to Galle Fort, a UNESCO World Heritage Site dating back to the Dutch colonial era. Explore the quaint streets lined with colonial-style monuments and immerse yourself in the rich cultural heritage. Visit the mask museum to learn about the traditional craft of mask-making, and witness the conservation efforts at the turtle hatchery. Conclude the day with a scenic boat ride along the picturesque Madu River, surrounded by lush mangrove forests.",

      Day7: "Day-07",
      Desc7:
        "After a leisurely breakfast, bid farewell to the picturesquelandscapes of Sri Lanka as you depart for BandaranaikeInternational Airport. Reflect on the unforgettablememories and experiences gained during your journey asyou prepare for your onward flight",
    },

    inclusion: {
      point1:
        "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tour and transfers, including arrival and departure.",
      point2:
        "Accommodation: Double/Single/Triple rooms on Half Board Basis at Ocean Colombo, Craft Tunnel, and Joe ’ s Resort, and Bed & Breakfast at Salt Mirissa. ",
      point3:
        "Free visits to Galle Fort, Mask factory, Toddy Tapping experience, Colombo Fort, Independence Square, Old Parliament Building, and Old Colombo Dutch Hospital.",
      point4:
        "Entrance fees to Madu River boat Safari, Turtle Hatchery & Udawalawe Safari (optional and direct pay by the clients).  1.City tours in Colombo & Galle. 2.Two water bottles per person per day during the tour (FOC). 3.Sim Cards (without data). 4.Insurance coverage for each passenger during the tour",
    },

    exclusion: {
      point1:
        "Personal Expenses such as telephone calls, laundry, drinks, etc. ",
      point2:
        "Lunch during the stay in Sri Lanka, Tips and Porterage, Camera Permits for filming.",
      point3: "Any other expenses not mentioned in the 'inclusions'.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg29,
    featured: true,
  },
  {
    id: "Srilanka_two",
    title: "Sri Lanka",
    city: "Srilanka",
    distance: 300,
    price: 142961,
    address: "Srilanka",
    packages: "8 Nights/9 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival, transfer to Sigiriya. En route, visit  inneriya National Park for wildlife spottig. Overnight stay at Sigiriya Village Hotel.",

      Day2: "Day-02",
      Desc2:
        "After breakfast, opt for a visit to Sigiriya Rock Fortress and Pidurangala Rock. Experience a villa tour in Hiriwaduna before heading to Kandy. Visit a spice garden and enjoy the Cultural Dance Show. Overnight at Swisse Hotel Kandy.",

      Day3: "Day-03",
      Desc3:
        "In the morning, visit the Temple of the Tooth Relic before boarding the scenic train to Ella. Enjoy breathtaking views during the journey. Overnight stay at Oak Ray Ella Gap Hotel",

      Day4: "Day-04",
      Desc4:
        "After breakfast, explore Ella's attractions including Halpewatte Tea Factory, Rawana Falls, Little Adam’s Peak, and the Nine Arch Bridge. Optional activities include Flying Ravana. Overnight at Oak Ray Ella Gap Hotel",

      Day5: "Day-05",
      Desc5:
        "After breakfast, transfer to Udawalawe. Visit a traditional pottery craft center and opt for a visit to Upper Diyaluma falls en route. Overnight stay at the Craft Tunnel",

      Day6: "Day-06",
      Desc6:
        "After breakfast, enjoy an optional safari at Udawalawe National Park before transferring to Unawatuna via Mirissa. Overnight stay at Joes Resort Unawatuna. ",

      Day7: "Day-07",
      Desc7:
        "After breakfast, explore Unawatuna's beaches and historical sites such as Galle Fort. Optional activities include visiting Jungle Beach Unawatuna and Rumassala Mountain. Overnight at Joes Resort Unawatuna",

      Day8: "Day-08",
      Desc8:
        "After breakfast, transfer to Colombo via Ambalangoda and Bentota. Visit a mask factory, turtle hatchery, and experience a river safari. Explore Colombo City. Overnight at Ocean Colombo Hotel. ",

      Day9: "Day-09",
      Desc9:
        "After breakfast, enjoy a city tour of Colombo, visiting landmarks like the Old Parliament Building, Independence Memorial Hall, Dutch Hospital, and Gangarama Temple. Departure transfer to the airport",
    },

    inclusion: {
      point1:
        "Private air-conditioned transportation with an English-speaking chauffer guide",
      point2: "Accommodation on a double room sharing basis with breakfast",
      point3: "Excursions and city tours as mentioned in the itinerary",
      point4:
        "Two water bottles per person per day during the tour. 1.SIM cards with data 2.Insurance coverage for each passenger during the tour",
    },

    exclusion: {
      point1: "Personal expenses such as telephone calls, laundry, and drinks.",
      point2: "Tips and porterage, Camera permits for filming.",
      point3: "Any other expenses not mentioned in the inclusions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg30,
    featured: true,
  },
];

export const maldivesResortTours = [
  {
    id: "Adaaran_club_Rannalhi",
    title: "Adaaran club Rannalhi",
    city: "Maldives",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    distance: 300,
    price: 45999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    distance: 300,
    price: 151999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hardrock_Maldivest",
    title: "Hardrock Maldives",
    city: "Maldives",
    distance: 300,
    price: 229999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

export const maldivesHoneymoonTours = [
  {
    id: "Adaaran_club_Rannalhi",
    title: "Adaaran club Rannalhi",
    city: "Maldives",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    distance: 300,
    price: 160999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    distance: 300,
    price: 45999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    distance: 300,
    price: 151999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

export const maldivesWatersportsTours = [
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },

    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
];

// allTours data

export const AllTours = [
  {
    id: "Samudram",
    title: "Samudram",
    city: "Lakshadweep",
    distance: 300,
    price: 39375,
    address: "Somewhere",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Your Samudram Package journey begins with a smooth security check at the reporting center, followed by a comfortable bus ride to the ship. Once onboard, officials will guide you to your cabins, which can be either in the Diamond or Gold class, depending on your booking. At 12:30 PM, you can enjoy a delicious buffet lunch in the first-class cafeteria. The ship will set sail for the Lakshadweep islands between 2:30 and 3:00 PM, and you'll enjoy a mesmerizing 14 to 16-hour journey across the sea. As you sail under the night sky, with stars twinkling above, this magical experience will mark the beginning of your Lakshadweep Samudram package adventure.",
      Day2: "Day-02",
      Desc2:
        "Welcome to Lakshadweep with this amazing Samudram Package! Upon arrival at one of the islands, you'll board a boat to the jetty, where you'll be greeted with a traditional welcome and a refreshing drink at the resort. The day is yours to explore the island and participate in water sports such as snorkeling, kayaking, and sea bathing. Optional scuba diving is also available under expert supervision. After a hearty lunch, you can explore the island's unique culture and sights. A relaxing afternoon tea follows before you head back to the ship. Dinner will be served in the first-class cafeteria, rounding off your day on this Lakshadweep Samudram package for LTC as per terms and conditions.",
      Day3: "Day-03",
      Desc3:
        "The ship will dock at the next island early in the morning, and a delicious buffet breakfast will be served on board. Disembark at 8:00 AM, and continue your adventure with the same exciting activities as the previous day. Each island offers a unique experience with its distinct culture and scenery. After a full day of exploration, you'll return to the ship in the evening. Enjoy a delightful buffet dinner as you prepare for the next leg of your Lakshadweep Samudram package journey. You can also book the Lakshadweep package if your are looking to customize your travel adventure.",
      Day4: "Day-04",
      Desc4:
        "You'll arrive at the third island early in the morning, with breakfast served on board. The day's activities will mirror those of the previous islands, allowing you to fully immerse yourself in the local culture and beauty. After a day of fun and relaxation, return to the ship for dinner. You'll spend the night onboard as the ship sails back to Kochi.",
      Day5: "Day-05",
      Desc5:
        "Your enchanting Lakshadweep Samudram package adventure concludes as the ship docks in Kochi around 10:00 to 10:30 AM. Enjoy a final breakfast onboard, marking the end of your unforgettable experience. This all-inclusive Samudram package ensures a seamless journey filled with memories of diverse cultures, pristine landscapes, and vibrant activities. Thank you for choosing Samudram packages for this extraordinary exploration.",
    },
    inclusion: {
      point1:
        "Ship tickets, Accommodation in AC cabin (Diamond Class), Three Times Food",
      point2: "Three island Visit",
      point3: "Sight seeing with Vehicle and guide",
      point4:
        "3 Water sport Activities (Kayaking, Glass bottom Riding, Snorkeling)",
    },
    exclusion: {
      point1: "Scuba Diving",
      point2: "Airfare",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg01,
    featured: true,
    metaTitle: "Book Lakshadweep samudram package | samudram package |",
    metaDesc:
      "Book the 2024 Lakshadweep Samudram Package. Unbeatable cost, Limited-time deal from Kochi for Families, Couples LTC Employees &More| Book yours Today@Best price",
    metaKeywords:
      "samudram package, lakshadweep samudram packa, samudram package booki, samudram package 2024 schedu, samudram package co, samudram package diamond cla, samudram package online booki, lakshadweep samudram package review,samudram package from kochi, how to book samudram package, do we need PCC for samudram package, best travel company to book samudram package, samudram package for LTC employees",
  },
  {
    id: "Bangaram",
    title: " Bangaram Bliss (AC Cottage)",
    city: "Lakshadweep",
    distance: 400,
    price: 68082,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "“ISLAND ODYSSEY: UNVEILING TRANQUILITY AND ADVENTURE AT BANGARAM” Embark on a day of island exploration at Bangaram, where the morning unfolds with captivating sightseeing. Discover the untouched beauty of nearby uninhabited islands – Thinnakara, Parali 1 & Parali 2 – in the first half. As the day progresses, relish the serenity of the sandbank area, immersing yourself in its pristine allure until the enchanting sunset. Bangaram Island offers a seamless blend of adventure and tranquility, promising a day rich in exploration and natural wonders.",
      Day1: "Day-01",
      Desc1:
        "FROM ARRIVAL TO SUNSET: A MAGICAL JOURNEY TO BANGARAM ISLAND At Agatti Airport, guests receive a warm welcome, ushering them into an enchanting journey to Bangaram Island. The scenic road transfer to the Ferry jetty is just the beginning. Embarking on a one-hour boat journey through the open sea from Agatti to Bangaram, lucky travelers may encounter playful dolphins along the way. Upon arrival at the resort, a refreshing tender coconut drink awaits. After freshening up and savoring lunch, guests are free to explore the breathtaking Bangaram Island, immersing themselves in the unspoiled beauty of nature until the mesmerizing sunset.",
      Day2: "Day-02",
      Desc2:
        "AQUA WONDERLAND: THRILLING WATERSPORTS EXTRAVAGANZA AT BANGARAM ISLAND Dive into a day of aquatic adventures on the pristine shores of Bangaram Island. Following a delicious breakfast, seize the opportunity to explore an array of thrilling watersports at our dedicated center. From snorkeling to kayaking, glass-bottom boat rides, turtle spotting, and scuba diving, immerse yourself in the vibrant marine wonders. Bangaram offers an exhilarating playground for water enthusiasts, ensuring a day filled with excitement and discovery beneath the sun-kissed skies.",
      Day4: "Day-04",
      Desc4:
        "FAREWELL TO PARADISE: DEPARTING BANGARAM WITH LIFELONG MEMORIES Post-breakfast, prepare to bid farewell to the enchanting Bangaram Island. A speedboat ride to Agatti Island awaits, where you'll then transition to the airport for your departure. As you embark on the journey back to your destination, carry with you a lifetime of memories from this tiny paradise. The idyllic beauty of Bangaram Island becomes a cherished chapter, etched in your heart, marking the end of an unforgettable island escape.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg02,
    featured: true,
  },
  {
    id: "Bangaram_premium",
    title: "Bangaram Premium (AC Wooden Hut)",
    city: "Lakshadweep",
    distance: 500,
    price: 86982,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "“ISLAND ODYSSEY: UNVEILING TRANQUILITY AND ADVENTURE AT BANGARAM” Embark on a day of island exploration at Bangaram, where the morning unfolds with captivating sightseeing. Discover the untouched beauty of nearby uninhabited islands – Thinnakara, Parali 1 & Parali 2 – in the first half. As the day progresses, relish the serenity of the sandbank area, immersing yourself in its pristine allure until the enchanting sunset. Bangaram Island offers a seamless blend of adventure and tranquility, promising a day rich in exploration and natural wonders.",
      Day1: "Day-01",
      Desc1:
        "From Arrival to Sunset: A Magical Journey to Bangaram Island Embark on a romantic odyssey at Agatti Airport, the gateway to Bangaram Island. Traverse scenic roads and embark on a onehour boat journey, perhaps accompanied by playful dolphins. A refreshing welcome sets the tone, inviting exploration of Lakshadweep's unspoiled beauty until the enchanting sunset, creating timeless memories for you and your loved one in this idyllic paradise.",
      Day2: "Day-02",
      Desc2:
        "Aquatic Bliss at Bangaram Savor a delectable breakfast and plunge into an aquatic odyssey at Bangaram Island. Engage in thrilling watersports (at extra cost) at our dedicated center—snorkel through coral gardens, kayak along serene coastlines, enjoy glass-bottom boat rides, spot turtles, and explore the depths with scuba diving. Bangaram's vibrant marine realm ensures each sun-kissed day becomes an exhilarating adventure, promising water enthusiasts excitement and discovery.",
      Day4: "Day-04",
      Desc4:
        "Farewell to a Tropical Haven, Carrying Memories of a Lifetime After breakfast, bid farewell to enchanting Bangaram Island. A speedboat ride to Agatti Island precedes your transition to the airport. Carry a lifetime of memories from this tiny paradise, etching the idyllic beauty of Bangaram into your heart, marking the end of an unforgettable island escape. Farewell, memories of a lifetime, as you embark on the journey back, cherishing the moments spent in this tropical haven.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg03,
    featured: true,
  },
  {
    id: "Agatti",
    title: "Agatti Island",
    city: "Lakshadweep",
    distance: 500,
    price: 46000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "“ISLAND ODYSSEY: UNVEILING TRANQUILITY AND ADVENTURE AT BANGARAM” Embark on a day of island exploration at Bangaram, where the morning unfolds with captivating sightseeing. Discover the untouched beauty of nearby uninhabited islands – Thinnakara, Parali 1 & Parali 2 – in the first half. As the day progresses, relish the serenity of the sandbank area, immersing yourself in its pristine allure until the enchanting sunset. Bangaram Island offers a seamless blend of adventure and tranquility, promising a day rich in exploration and natural wonders.",
      Day1: "Day-01",
      Desc1:
        "From Arrival to Sunset: A Magical Journey to Bangaram Island Embark on a romantic odyssey at Agatti Airport, the gateway to Bangaram Island. Traverse scenic roads and embark on a onehour boat journey, perhaps accompanied by playful dolphins. A refreshing welcome sets the tone, inviting exploration of Lakshadweep's unspoiled beauty until the enchanting sunset, creating timeless memories for you and your loved one in this idyllic paradise.",
      Day2: "Day-02",
      Desc2:
        "Aquatic Bliss at Bangaram Savor a delectable breakfast and plunge into an aquatic odyssey at Bangaram Island. Engage in thrilling watersports (at extra cost) at our dedicated center—snorkel through coral gardens, kayak along serene coastlines, enjoy glass-bottom boat rides, spot turtles, and explore the depths with scuba diving. Bangaram's vibrant marine realm ensures each sun-kissed day becomes an exhilarating adventure, promising water enthusiasts excitement and discovery.",
      Day4: "Day-04",
      Desc4:
        "Farewell to a Tropical Haven, Carrying Memories of a Lifetime After breakfast, bid farewell to enchanting Bangaram Island. A speedboat ride to Agatti Island precedes your transition to the airport. Carry a lifetime of memories from this tiny paradise, etching the idyllic beauty of Bangaram into your heart, marking the end of an unforgettable island escape. Farewell, memories of a lifetime, as you embark on the journey back, cherishing the moments spent in this tropical haven.",
    },
    inclusion: {
      point1: "Lodging, Hotel Reservation",
      point2: "Individual Support, Escort ",
      point3:
        "Transfer Services from and to Agatti, All-Inclusive Dining, Entry Permit",
      point4: "A Cozy Candlelit Dinner",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Water Sports Activities, Scuba Diving",
      point3: "Day visit to other islands",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04L,
    featured: true,
  },

  {
    id: "Adaaran-Club-Rannalhi",
    title: "Adaaran Club Rannalhi",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival, settle into your accommodation and take time to explore the amenities offered by the resort. Relax and unwind after your journey, immersing yourself in the tranquil atmosphere of your surroundings.",
      Day2: "Day-02",
      Desc2:
        "Start your day with a delicious breakfast at the resort before embarking on a day of adventure and romance. Engage in thrilling water activities such as snorkeling, diving, or jet skiing (available at an extra cost), creating unforgettable memories with your loved ones. In the evening, indulge in a romantic dinner or take a leisurely sunset stroll along the beach, soaking in the beauty of your surroundings.",
      Day3: "Day-03",
      Desc3:
        "Today is dedicated to leisure and relaxation as you immerse yourself in the natural beauty of the Maldivian beaches. Treat yourself to a pampering spa session, allowing the gentle sound of the waves to soothe your senses as skilled therapists work their magic. Take leisurely walks along the shore, feeling the soft sand between your toes and reveling in the serenity of your surroundings. Savor delicious meals prepared by talented chefs at the resort, indulging in the flavors of the island.",
      Day4: "Day-04",
      Desc4:
        "As your unforgettable journey comes to an end, it's time to bid farewell to the Maldives. Check out from your resort and transfer to the airport for your departure flight, carrying with you memories of sun-kissed beaches, azure waters, and moments of pure bliss.",
    },
    inclusion: {
      point1: "Accommodation on the selected basis.",
      point2: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },

  {
    id: "Dhiffushi",
    title: "Dhiffushi Island",
    city: "Maldives",
    category: "Budget_Packages",
    Category: "Maldives_Budget",
    distance: 500,
    price: 47099,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "Day at Leisure -- Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral gardens and marine life of the Indian Ocean. Go scuba diving, surfing, snorkeling, and island hopping (at extra cost).",
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives – Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Day at Leisure – Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like snorkeling, scuba diving, windsurfing, canoeing etc. (at extra price). ",
      Day4: "Day-04",
      Desc4:
        "Departure from Maldives -- Enjoy a hearty breakfast and check-out from the hotel/resort as you embark on your journey back home. You will be transferred to Male International Airport to catch your fight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling ",
      point3:
        "Transportation, Complimentary candlelight dinner at Agatti, Passengers permit and Transportation ",
      point4:
        "Airport pick up & Drop by speed boat, One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Thulusdhoo",
    title: "Thulusdhoo Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 55599,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day3: "Day-03",
      Desc3:
        "Day at Leisure -- Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral gardens and marine life of the Indian Ocean. Go scuba diving, surfing, snorkeling, and island hopping (at extra cost). ",
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives – Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Day at Leisure – Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like snorkeling, scuba diving, windsurfing, canoeing etc. (at extra price). ",
      Day4: "Day-04",
      Desc4:
        "Departure from Maldives -- Enjoy a hearty breakfast and check-out from the hotel/resort as you embark on your journey back home. You will be transferred to Male International Airport to catch your fight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling ",
      point3:
        "Transportation, Complimentary candlelight dinner at Agatti, Passengers permit and Transportation ",
      point4:
        "Airport pick up & Drop by speed boat, One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: false,
  },
  {
    id: "Dhiffushi&Thulusdhoo",
    title: "Dhiffushi & Thulusdhoo Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 69999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: false,
  },
  {
    id: "Maldives_Premium_Honeymoon",
    title: "Maldives Premium Honeymoon",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 164000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: false,
  },
  {
    id: "Personalized_Island_Experience",
    title: "Personalized Island Experience, Maldives",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 50594,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg08,
    featured: false,
  },
  {
    id: "Maldives_Submarine_Dive",
    title: "Maldives Submarine Dive Discovery Voyage Package",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg09,
    featured: false,
  },
  {
    id: "Splendid_Serenity_Retreat",
    title: "Splendid Serenity Retreat Maldives Package",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "100% refund if the schedule is cancelled due to unfavorable weather conditions and other unexpected technical faults No damages/claims will be payable except money paid by them.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg10,
    featured: false,
  },
  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience Two Island",
    city: "Maldives",
    category: "Budget_Packages",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrival in Maldives Welcome to Maldives - Indian Ocean's most sought-after beach escape, an archipelago of 1,190 coral islands south west of Sri Lanka. Upon arrival at Male Airport, get transferred to your hotel/resort to Thulusdhoo by speedboat.",
      Day2: "Day-02",
      Desc2:
        "Water Activities (optional at extra cost) After an appetizing breakfast, you are free to relax on the beach or try some water activities like Snorkeling, Scuba Diving, Windsurfing, etc. (at extra price)",
      Day3: "Day-03",
      Desc3:
        "Spend your days relaxing on a hammock under the shade of a coconut tree. Explore the magnificent coral garden and marine life of the Indian ocean. Go scuba diving, Surfing, Snorkeling, and island hopping (at extra price). After the magnificent day you will be transfer to Dhiffushi island by speedboat.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island & excursions that are based at the island. Think surfing, snorkeling, diving, fishing, swimming, stand-up paddle boating Enjoy a hearty breakfast and feel the beauty of Dhiffushi Island.",
      Day5: "Day-05",
      Desc5:
        "Departure from Maldives - Enjoy a hearty breakfast and check-out from the hotel as you embark on your journey back home. You will be transferred to Male International Airport to catch your flight back home.",
    },
    inclusion: {
      point1:
        "Hotel Stay (SII Coral Inn), All Meals (Breakfast, Lunch, Dinner)",
      point2: "Pedal Boat, Kayaking , Snorkeling, Bicycle ",
      point3: "Transportation, Airport pick up & Drop by speed boat, ",
      point4:
        " One candle light dinner in beach, Room Decoration for Honeymoon Couples only., GST, Green Tax, Service Charges. ",
    },
    exclusion: {
      point1: "Flight Tickets.",
      point2: "Any Shopping Charges",
      point3: "Wandering Mind",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: false,
  },

  {
    id: "Bali_Odyssey",
    title: "Bali Odyssey",
    city: "Bali, Indonesia",
    distance: 300,
    price: 53121,
    address: "Bali, Indonesia",
    packages: "7 Nights/6 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "BALI ARRIVAL BLISS, Embark on your Bali adventure with a warm welcome at Ngurah Rai International Airport. Our attentive team ensures a seamless transfer to your accommodation, allowing you to unwind and acclimate to Bali's enchanting charm. Take the initial moments to immerse yourself in the island's ambiance, setting the tone for a memorable stay filled with cultural discoveries and natural wonders.",
      Day2: "Day-02",
      Desc2:
        "Embark on a cultural odyssey with Tampak Siring–Kintamani– Tegalalang. Immerse yourself in the sacred waters of Tirta Empul Temple and Tampak Siring. Indulge in a scenic lunch, marveling at the active volcano and Lake Batur in Kintamani. Uncover the art of Kopi luwak at a Bali coffee plantation. Conclude the day surrounded by the breathtaking beauty of the Tegalalang rice terraces, creating memories of Bali's rich cultural and natural tapestry",
      Day3: "Day-03",
      Desc3:
        "Embark on an exhilarating Nusa Penida Day Tour. Sail from Sanur Harbour to Nusa Penida, discovering the wonders of Angel Billabong, Broken Beach, and the iconic Kelingking Secret Point. Enjoy a delightful lunch at a local restaurant, followed by an exploration of the crystal-clear waters at Crystal Bay. As the day concludes, return to Bali, leaving you with vivid memories of Nusa Penida's natural splendor. ",
      Day4: "Day-04",
      Desc4:
        "Embark on a spiritual journey with Handara Gate - Ulun Danu - Tanah Lot. Marvel at the beauty of Beratan Lake Temple in Bedugul (Ulun Danu Beratan Temple), where serenity meets spirituality. Savor a delicious lunch at a local restaurant before witnessing the breathtaking sunset at the iconic tenah Lot Temple. Immerse Yourself in Bali's cultural and natural wonders, creating a day of tranquility and awe-inspiring vistas.",
      Day5: "Day-05",
      Desc5:
        "Embark on an adventure Wisnu Kencana - Uluwatu. with Water Sport – Garuda Dive into thrilling water sports at Tanjung Benoa, followed by a delectable lunch. Marvel at the iconic Garuda Wisnu Kencana statue, symbolizing Bali's cultural richness. Conclude your day at Uluwatu Temple, witnessing the mesmerizing Kecak Dance performance against the backdrop of a stunning Balinese sunset. Experience the perfect blend of adrenaline, artistry, and awe-inspiring vistas.",
      Day6: "Day-06",
      Desc6:
        "Bid adieu with the Departure package. Enjoy a leisurely breakfast at the hotel, savoring the last moments in Bali's embrace. With free time until check-out, relish the tranquility before bidding farewell. Our seamless transfer service ensures a stress-free journey to Ngurah Rai International Airport for your departure. Depart with cherished memories, leaving Bali with a sense of peace and gratitude for an unforgettable experience. ",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg12,
    featured: true,
  },
  {
    id: "Bali_Island_Odyssey",
    title: "Bali Island Odyssey",
    city: "Bali, Indonesia",
    distance: 300,
    price: 42840,
    address: "Bali, Indonesia",
    packages: "6 Nights/5 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon your arrival at Ngurah Rai International Airport, our friendly local representative will extend a warm welcome and assist with a seamless transfer to your hotel. Enjoy some leisure time to relax or explore the nearby areas, allowing you to settle in and embrace the charm of Bali from the moment you arrive. ",
      Day2: "Day-02",
      Desc2:
        "Indulge in a delightful day with our Tampak Siring– Kintamani–Tegalalang package. Begin with a satisfying breakfast at the hotel, followed by a visit to the sacred Tirta Empul Temple in Tampak Siring. Enjoy a scenic lunch overlooking the active volcano and Lake Batur in Kintamani. Discover the secrets of Kopi luwak at a Bali coffee plantation and marvel at the breathtaking Tegalalang rice terraces. Conclude the day with a comfortable overnight stay at the hotel",
      Day3: "Day-03",
      Desc3:
        "Embark on an unforgettable Nusa Penida Day Tour with our exclusive package. Start your day with a hearty breakfast before a seamless transfer to Sanur Harbour. Cruise to Nusa Penida and marvel at the stunning Angel Billabong, Broken Beach, and the iconic Kelingking Secret Point. Enjoy a delectable lunch, unwind at Crystal Bay, and return to Bali in the evening for a comfortable overnight stay at the hotel. ",
      Day4: "Day-04",
      Desc4:
        "Embark on an exhilarating Water Sport – Garuda WisnuKencana - Uluwatu adventure. After breakfast,venture to Tanjung Benoa for thrilling water sports likeBanana Boat, Parasailing, and Jet Ski (at own expense).Bali Bliss: A Day of Thrills,Culture, and Scenic Wonders Page.06www.goltravels.comRelish a delightful lunch at a local restaurant, marvelat the majestic Garuda Wisnu Kencana statue, andconclude the day with a visit to the iconic UluwatuTemple, featuring the mesmerizing Kecak Danceperformance. Retire to the hotel for a restful overnightstay.",
      Day5: "Day-05",
      Desc5:
        "Bid farewell to Bali with our Departure package. Enjoy a final breakfast at the hoteland cherish some leisure time until check-out.Our seamless transfer service will take you toNgurah Rai International Airport for yourdeparture. Relish the memories of yourenchanting Bali experience as you embark onyour journey home.",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg13,
    featured: true,
  },
  {
    id: "BaliFamily_Adventure",
    title: "Bali Family Adventure",
    city: "Bali, Indonesia",
    distance: 300,
    price: 81872,
    address: "Bali, Indonesia",
    packages: "8 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience a warm welcome as you step into the tropical paradise of Bali at Ngurah Rai International Airport. Our friendly local representatives will greet you with open arms, ensuring a seamless transfer to your accommodation. Take the time to acclimate to Bali's charm as you settle in, surrounded by the island's unique blend of vibrant culture and natural beauty. Get ready for a delightful journey filled with unforgettable moments. ",
      Day2: "Day-02",
      Desc2:
        "Embark on a thrilling Safari Journey through Indonesia, India, and Africa habitats at Bali Safari Park. Enjoy front-of-the-line access, mesmerizing aquarium exhibits, and captivating Animal & Elephant Shows. Immerse yourself in the cultural spectacle of the Bali Agung Show, followed by a delightful lunch. Dive into adventure at the Waterpark and Fun Zone before visiting the enchanting Tegenungan Waterfall —a perfect blend of wildlife wonders and natural beauty.",
      Day3: "Day-03",
      Desc3:
        "Embark on an adrenaline-packed day with our River Rafting – ATV Ride adventure. Conquer the apids of Ayung River, surrounded by breathtaking andscapes. Savor a delicious lunch at a local estaurant before gearing up for a thrilling ATV ide through scenic rice fields and plantations. eel the rush as you navigate the rugged terrains, reating memories of heart-pounding excitement in he heart of Bali's natural beauty. This actionpacked day promises an unforgettable fusion of dventure and stunning views. ",
      Day4: "Day-04",
      Desc4:
        "Embark on a day of digital wonders and family excitement with our Trans Studio Theme Park adventure. Immerse yourself in interactive technology, exploring Bali's nature at Bali Senses and experiencing the thrill of the first flying theatre in Indonesia—Flying Over Indonesia. Let the little ones delight in exciting rides at the Kids & Family Zone, featuring Formula Kart, Ferris Wheels, and more. Indulge in diverse cuisines at food stands throughout the park, ensuring a day filled with joy and culinary delights.",
      Day5: "Day-05",
      Desc5:
        "Bid farewell to Bali with our Departure package. Enjoy a final breakfast at the hoteland cherish some leisure time until check-out.Our seamless transfer service will take you toNgurah Rai International Airport for yourdeparture. Relish the memories of yourenchanting Bali experience as you embark onyour journey home.Dive into the thrill of water sports at Tanjung Benoa, where adventure awaits with activities like Banana Boat and Jet Ski. Visit the iconic Uluwatu Temple perched on a cliff, offering breathtaking ocean views and a captivating Kecak Dance performance. Conclude your day with a delectable seafood BBQ dinner on the shores of Jimbaran Beach, savoring the essence of Bali's sun, sea, and culinary delights.",
      Day6: "Day-06",
      Desc6:
        "Begin your day with a leisurely breakfast at the hotel. As the sun sets, embark on a magical Sunset Pirate Dinner Cruise from Benoa Harbor. Glide over the waters, enjoying breathtaking views and a delightful dinner onboard. Immerse yourself in the tranquility of the ocean, creating cherished memories as you sail into the enchanting Bali sunset.",
      Day7: "Day-07",
      Desc7:
        "Savor a final breakfast at your hotel, soaking in the essence of Bali. With leisure time until checkout, relish the last moments of your tropical getaway. Our seamless transfer service ensures a stress-free journey to Ngurah Rai International Airport for your departure. Depart with a heart full of memories, promising to return to the island paradise. Until we meet again, Bali bids you a fond farewell. ",
    },
    inclusion: {
      point1: "Hotel accommodation with breakfast.",
      point2: "Guided tours and entrance fees as specified in the program.",
      point3:
        "Private AC Avanza for 02-04 pax and Toyota Commuter for 05-11 pax with driver assistance.",
      point4: "Meals as mentioned in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Tipping for guide and driver",
      point3: "Travel Insurance",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg19,
    featured: true,
  },

  {
    id: "Bangkok_Odyssey",
    title: "Bangkok Odyssey",
    city: "Thailand",
    distance: 300,
    price: 20753,
    address: "Thailand",
    packages: "3 Nights/4 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience a seamless arrival with a private transfer from Bangkok Airport to the vibrant city. Check-in at 2:00 PM at Picnic or Tango Vibrant Bangkok Hotel, offering 3-star comfort. Later, join a Chao Phraya Cruise Dinner for an enchanting evening. Enjoy an overnight stay, including breakfast and dinner. Immerse yourself in the heart of Bangkok with this delightful package.",
      Day2: "Day-02",
      Desc2:
        "Begin your day with a hotel breakfast, setting the stage for an exciting adventure at Dream World with Snow Town, accompanied by lunch. Experience the thrill with a Super Visa on this joined transfer excursion. Conclude the day with an overnight stay at the hotel, ensuring a delightful blend of entertainment and relaxation.",
      Day3: "Day-03",
      Desc3:
        "Start your day with a hotel breakfast and embark on a captivating Bangkok Temple and City Tour, a joined transfer adventure. Explore the cultural wonders of the city, taking in its vibrant energy. Conclude the day with an overnight stay at the hotel, making it a perfect blend of exploration and comfort.",
      Day4: "Day-04",
      Desc4:
        "Enjoy a morning breakfast at the hotel before checking out at noon. Experience a seamless departure with a private transfer from your hotel to Bangkok Airport. Relish the convenience and bid farewell to the city with cherished memories.",
    },
    inclusion: {
      point1: "3 Nights & 4 Days in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg20,
    featured: true,
  },
  {
    id: "Pattaya_Delight",
    title: "Pattaya Delight",
    city: "Thailand",
    distance: 300,
    price: 24561,
    address: "Thailand",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Experience seamless travel with a private arrival transfer from Bangkok Airport to Baron Beach Resort Pattaya or a similar 3- star hotel. Check in at 14:00 and embark on a Pattaya City Tour & Discovery, exploring captivating viewpoints with a join transfer. Enjoy an overnight stay at the hotel. Meals and other details to be arranged. Discover the best of Pattaya with comfort and style.",
      Day2: "Day-02",
      Desc2:
        "Start your day with a delightful breakfast at the hotel before embarking on a Coral Island Tour with lunch, facilitated by a join transfer. Experience the beauty of the island and savor a delicious meal. Return for an overnight stay at the hotel. Enjoy the perfect blend of adventure and relaxation.",
      Day3: "Day-03",
      Desc3:
        "Indulge in a delightful breakfast at the hotel, followed by a private transfer from your Pattaya hotel to Pinci Hotel or Tango Vibrant Hotel Bangkok, or a similar 3-star accommodation. Check in and unwind. Join a Chao Phraya Cruise Dinner for a memorable evening. Enjoy an overnight stay at the hotel with breakfast and dinner included.",
      Day4: "Day-04",
      Desc4:
        "Begin your day with a leisurely breakfast at the hotel, setting the tone for a day of free exploration and relaxation. Enjoy the freedom to tailor your day to your desires, whether it's exploring local attractions, indulging in spa treatments, or simply unwinding by the pool. Conclude your day with a peaceful overnight stay at the hotel.",
      Day5: "Day-05",
      Desc5:
        "Savor a delicious breakfast at the hotel before embarking on a hassle-free departure. Check out at 12:00 PM, and enjoy a private transfer from the hotel to Bangkok Airport. Relish the convenience of a smooth transition, ensuring a stress-free end to your stay. Bid farewell to Bangkok with fond memories.",
    },
    inclusion: {
      point1:
        "2 nights in Pattaya and 2 nights in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg16,
    featured: true,
  },
  {
    id: "Pattaya&Bangkok_Escapade",
    title: "Pattaya & Bangkok Escapade",
    city: "Thailand",
    distance: 300,
    price: 24561,
    address: "Thailand",
    packages: "4 Nights/5 days",
    maxGroupSize: 1,
    person: "/per person",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Embark on a seamless journey as a private transfer whisks you from Bangkok Airport to the inviting Baron Beach Resort Pattaya. The 3-star comfort welcomes you with a 2:00 PM check-in. Immerse yourself in Pattaya's allure through a city and discovery tour, curated to unveil scenic viewpoints and cultural gems. The day culminates in an overnight stay at the hotel, promising relaxation and comfort, setting the tone for the days to come.",
      Day2: "Day-02",
      Desc2:
        "Awaken to the aroma of a delightful breakfast, setting the stage for an excursion to Coral Island. Join a transfer for a day filled with sun-soaked beaches, water activities, and a sumptuous lunch. The island's allure unfolds before you, ensuring a memorable experience. The night brings tranquility and repose as you retreat to your Pattaya accommodation.",
      Day3: "Day-03",
      Desc3:
        "Whether exploring local attractions, unwinding at the hotel, or indulging in Pattaya's vibrant offerings, the leisure day promises a perfect balance of exploration and relaxation tailored to your desires.",
      Day4: "Day-04",
      Desc4:
        "After a morning breakfast, a private transfer takes you to the bustling city of Bangkok. Check into the stylish Pinci or Tango Vibrant Hotel, and later, embark on a Chao Phraya Cruise Dinner. The joined transfer ensures a magical evening along the river, offering stunning views of Bangkok's illuminated landmarks.",
      Day5: "Day-05",
      Desc5:
        "The day begins with breakfast, followed by a captivating venture into Dream World with Snow Town. Enjoy lunch, engage in Super Visa experiences, and retreat for the night in the vibrant city of Bangkok. The day promises a blend of amusement and cultural encounters.",
      Day6: "Day-06",
      Desc6:
        "Kickstart the day with a hearty breakfast before joining a captivating Bangkok Temple and City Tour. Delve into the historical and modern facets of the city, exploring cultural landmarks and hidden gems. The day concludes with another night of comfort in Bangkok, leaving you enriched with diverse experiences.",
      Day7: "Day-07",
      Desc7:
        "Conclude your memorable journey with a final breakfast at the hotel, followed by a 12:00 PM check-out. A private transfer ensures a seamless departure from your hotel to Bangkok Airport, marking the end of an unforgettable Thai adventure filled with exploration and relaxation. Cherish the memories crafted throughout this immersive experience.",
    },
    inclusion: {
      point1:
        "3 nights in Pattaya and 3 nights in Bangkok with 3-star hotel accommodation.",
      point2: "Daily breakfast at the hotel",
      point3: "Private transfers from the airport to the hotel and vice versa.",
      point4:
        "Tours conducted by air-conditioned vehicles, All admission fees as specified in the program.",
    },
    exclusion: {
      point1: "Airfare",
      point2: "Thailand visa",
      point3: "Any expenses not explicitly mentioned in the program",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg17,
    featured: true,
  },

  {
    id: "Malaysian_Marvels",
    title: "Malaysian Marvels",
    city: "Malaysia",
    distance: 300,
    price: 38250,
    address: "Malaysia",
    packages: "4 Nights/5 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival at Penang International Airport, indulge in seamless comfort with a swift transfer to your accommodation. Settle down and unwind, embracing the serenity of your new surroundings. As the evening unfolds, immerse yourself in the local charm. Take a leisurely walk, discovering the enchanting streets and savoring the atmosphere. An ideal start to your Penang experience, blending relaxation with the allure of this vibrant destination.",
      Day2: "Day-02",
      Desc2:
        "Begin your day in Penang with a delightful breakfast, energizing yourself for a captivating half-day city tour. Immerse in the cultural tapestry of Penang's iconic landmarks. As the sun sets, take leisurely evening walks, exploring the vibrant streets, and soaking in the local atmosphere. A perfect blend of exploration and relaxation awaits in Penang's enchanting cityscape.",
      Day3: "Day-03",
      Desc3:
        "Upon transferring to Langkawi, your gateway to paradise, seamlessly transition from travel to tranquility. Check into your accommodation and settle down, immersing yourself in the island's serene ambiance. As the day unfolds, embrace the beauty of Langkawi, whether it's lounging on pristine beaches or exploring its vibrant culture. A perfect start to a relaxing and enchanting stay in this tropical haven.",
      Day4: "Day-04",
      Desc4:
        "Commence your Langkawi journey with a delightful breakfast, energizing for the adventures ahead. Immerse in the island's charm with a half-day city tour, exploring its treasures and cultural gems. As the day transitions to evening, embrace the vibrant vibes of Langkawi. Whether strolling along the beach or enjoying local delights, your day promises a perfect blend of exploration and relaxation in this tropical haven.",
      Day5: "Day-05",
      Desc5:
        "Begin your farewell day in Langkawi with a hearty breakfast, savoring the island's flavors one last time. After check-out, enjoy a seamless airport transfer, reminiscing about the memories created. Fly back with the echoes of Langkawi's beauty in your heart—a perfect conclusion to a memorable journey filled with relaxation, exploration, and enchanting moments.",
    },
    inclusion: {
      point1:
        "Accommodation: Enjoy a 2-night stay in Langkawi, ensuring comfort throughout your visit.",
      point2:
        " Breakfast: Start your days with two complimentary breakfasts, savoring the island's flavors.",
      point3:
        "City Tour (SIC): Explore Langkawi's treasures with a half-day city tour, discovering its cultural gems.",
      point4:
        "Transfers APT/PEN/APT (SIC): Enjoy hassle-free transfers between the airport and Penang.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg21,
    featured: true,
  },
  {
    id: "Malaysian_Odyssey",
    title:
      "Malaysian Odyssey: A 7- DAY ADVENTURE THROUGH KUALA LUMPUR, PENANG, AND LANGKAWI",
    city: "Malaysia",
    distance: 300,
    price: 54850,
    address: "Malaysia",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arriving at Kuala Lumpur Airport, experience a seamless transition to relaxation with a swift transfer to your hotel. Check-in, settle down, and embrace the comfort of your new surroundings. As the evening unfolds, immerse yourself in the vibrant atmosphere of Kuala Lumpur, whether it's exploring the city lights, indulging in local cuisine, or simply unwinding. The perfect start to your Kuala Lumpur adventure awaits.",
      Day2: "Day-02",
      Desc2:
        "Kickstart your day in Kuala Lumpur with a hearty breakfast, energizing yourself for the adventures ahead. Immerse in the city's charm with a captivating Kuala Lumpur city tour, exploring its iconic landmarks and cultural gems. As the day transitions to evening, relish the vibrant atmosphere, whether it's exploring cityscapes, enjoying local cuisine, or simply unwinding. A perfect blend of exploration and relaxation awaits in the heart of Kuala Lumpur.",
      Day3: "Day-03",
      Desc3:
        "Penang Arrival Bliss: Indulge in seamless comfort with a swift transfer to your accommodation. Settle down and unwind, embracing the serenity of your new surroundings. As the evening unfolds, immerse yourself in the local charm. Take a leisurely walk, discovering the enchanting streets and savoring the atmosphere. An ideal start to your Penang experience, blending relaxation with the allure of this vibrant destination.",
      Day4: "Day-04",
      Desc4:
        "Begin your day in Penang with a delightful breakfast, energizing yourself for a captivating half-day city tour —Immerse in the cultural tapestry of Penang's iconic landmarks. As the sun sets, take leisurely evening walks, exploring the vibrant streets, and soaking in the local atmosphere. A perfect blend of exploration and relaxation awaits in Penang's enchanting cityscape.",
      Day5: "Day-05",
      Desc5:
        "Upon transferring to Langkawi, your gateway to paradise, seamlessly transition from travel to tranquility. Check into your accommodation and settle down, immersing yourself in the island's serene ambiance. As the day unfolds, embrace the beauty of Langkawi, whether it's lounging on pristine beaches or exploring its vibrant culture. A perfect start to a relaxing and enchanting stay in this tropical haven.",
      Day6: "Day-06",
      Desc6:
        "Commence your Langkawi journey with a delightful breakfast, energizing for the adventures ahead. Immerse in the island's charm with a half-day city tour, exploring its treasures and cultural gems. As the day transitions to evening, embrace the vibrant vibes of Langkawi. Whether strolling along the beach or enjoying local delights, your day promises a perfect blend of exploration and relaxation in this tropical haven.",
      Day7: "Day-07",
      Desc7:
        "Begin your farewell day in Langkawi with a hearty breakfast, savoring the island's flavors one last time. After check-out, enjoy a seamless airport transfer, reminiscing about the memories created. Fly back with the echoes of Langkawi's beauty in your heart—a perfect conclusion to a memorable journey filled with relaxation, exploration, and enchanting moments.",
    },
    inclusion: {
      point1: "2 Nights accommodation.",
      point2: "2 Breakfasts.",
      point3: "Half-Day City Tour on a Shared (SIC) basis.",
      point4: "Airport to Hotel and back on a Shared (SIC) basis.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg22,
    featured: true,
  },
  {
    id: "Kuala_Lumpur",
    title:
      "Kuala Lumpur Arrival Bliss: Unwinding in the Heart of the City Lights",
    city: "Malaysia",
    distance: 300,
    price: 24500,
    address: "Malaysia",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arriving at Kuala Lumpur Airport, experience a seamless transition to relaxation with a swift transfer to your hotel. Check-in, settle down, and embrace the comfort of your new surroundings. As the evening unfolds, immerse yourself in the vibrant atmosphere of Kuala Lumpur, whether it's exploring the city lights, indulging in local cuisine, or simply unwinding. The perfect start to your Kuala Lumpur adventure awaits.",
      Day2: "Day-02",
      Desc2:
        "Kickstart your day in Kuala Lumpur with a hearty breakfast, energizing yourself for the adventures ahead. Immerse in the city's charm with a captivating Kuala Lumpur city tour, exploring its iconic landmarks and cultural gems. As the day transitions to evening, relish the vibrant atmosphere, whether it's exploring cityscapes, enjoying local cuisine, or simply unwinding. A perfect blend of exploration and relaxation awaits in the heart of Kuala Lumpur.",
      Day3: "Day-03",
      Desc3:
        "Immerse yourself in a day filled with rich culture and delectable cuisine. Explore cultural landmarks, museums, or traditional markets to absorb the essence of the destination. Indulge in the diverse culinary offerings, savoring local delicacies that showcase the authentic flavors of the region. Let your senses guide you through a day of cultural exploration and gastronomic delights, creating unforgettable memories.",
      Day4: "Day-04",
      Desc4:
        "On your farewell day, savor a final breakfast, relishing the flavors one last time. Complete your stay with a hassle-free check-out, ensuring a seamless departure. Fly back with cherished memories, as the experiences of your journey linger. This farewell day encapsulates the essence of your stay, leaving you with a tapestry of moments to carry home.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3:
        "Seamless transfers from the airport to the hotel and back on a Shared (SIC) basis.",
      point4:
        "Explore Kuala Lumpur's iconic landmarks with a captivating half-day city tour on Shared (SIC) transport.",
    },
    exclusion: {
      point1: "Air Tickets: Not included in the package.",
      point2:
        " Entrance Fees: Any entrance fees to attractions are not covered and should be borne separately.",
      point3:
        "Other Than Mentioned: Expenses beyond the specified inclusions will be charged separately.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  // Maldives Resort Categories

  {
    id: "Adaaran_club_Rannalhi",
    title: "Adaaran club Rannalhi",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 45999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 151999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hardrock_Maldivest",
    title: "Hardrock Maldives",
    city: "Maldives",
    category: "Resort_Packages",
    distance: 300,
    price: 229999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  // maldives Honeymoon Packages

  {
    id: "Adaaran_club_Rannalhi",
    title: "Adaaran club Rannalhi",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 300,
    price: 147999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 300,
    price: 160999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 300,
    price: 45999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 300,
    price: 151999,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },
    special_inclusions: {
      point1: "Romantic Bed decoration (once per stay)",
      point2: "One Honeymoon cake",
      point3: "Romantic Turn-down service (once per stay, on request)",
      point4: "Honeymooners Dinner (once per stay, for minimum 4 nights)",
      point5:
        "Inform at booking, submit wedding certificate (within 6 months from marriage).",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  // Maldives Water Sports
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    distance: 300,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Arrive at the airport and transfer to the resort/hotel. Check-in at the resort/hotel. Settle down in your accommodation. Explore the facilities and amenities of the resort. Relax and unwind after your journey.",
      Day2: "Day-02",
      Desc2:
        "Enjoy a delicious breakfast at the resort. Engage in water activities like snorkeling, diving, or jet skiing.(at extra cost ) Spend quality time with your partner in the evening. Opt for a romantic dinner or take a sunset stroll on the beach together.",
      Day3: "Day-03",
      Desc3:
        "Explore the beauty of Maldivian beaches. Indulge in spa treatments and relaxation. Enjoy serene walks along the shore. Savor delicious meals at the resort. Take time to relax and rejuvenate before departure",
      Day4: "Day-04",
      Desc4:
        " Check out from the resort/hotel. Transfer to the airport for your departure flight.",
    },
    inclusion: {
      point1:
        "Enjoy a 3-night stay in Kuala Lumpur, ensuring comfort and relaxation.",
      point2:
        "Begin your days with 3 complimentary breakfasts, setting the tone for exploration.",
      point3: "Accommodation on the selected basis.",
      point4: "Transfer from the airport to the resort/hotel and back.",
    },
    exclusion: {
      point1: " Flight tickets.",
      point2: " Travel insurance.",
      point3:
        "Any additional activities not mentioned in the itinerary, such as specific water sports or excursions.",
    },

    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg23,
    featured: true,
  },

  // srilanka tour details

  {
    id: "Srilanka_one",
    title: "Sri Lanka Ancient Wonders",
    city: "Srilanka",
    distance: 300,
    price: 109021,
    address: "Srilanka",
    packages: "6 Nights/7 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon your arrival at Bandaranaike International Airport, you'll be greeted by your friendly national guide from Trip with the traditional Sri Lankan greeting, Ayubowan, meaning May you live long. They ll be there to assist you and ensure a smooth transition to your accommodation in the vibrant city of Colombo. Relax and unwind at the Ocean Colombo Hotel, where you'll spend the night.",

      Day2: "Day-02",
      Desc2:
        "After indulging in a hearty breakfast, embark on a scenic journeyto Udawalawa via Ratnapura. Along the way, immerse yourselfin the rich culture and history of Sri Lanka with a visit toRatnapura Maha Saman Dewalaya, an ancient temple dedicatedto Lord Saman. Delve into the fascinating world of gem mining,an integral part of Sri Lanka's heritage. Spend the night at thetranquil Craft Tunnel Hotel",

      Day3: "Day-03",
      Desc3:
        "Start your day with a delicious breakfast before diving into animmersive cultural experience. Engage in pottery crafting with Mr.Ajith Perera, a master of the craft, who will guide you through theintricate process of creating traditional pottery. Later, enjoy acaptivating Cookery Demonstration and be enthralled by a vibrantTraditional Dance performance. For wildlife enthusiasts, an optionalvisit to Udawalawa National Park offers the chance to encounterdiverse flora and fauna in their natural habitat",

      Day4: "Day-04",
      Desc4:
        "After a rejuvenating breakfast, depart for the coastalparadise of Mirissa. Indulge in a day of leisure, soakingup the sun and sea breeze at the pristine beaches.Unwind amidst the serene surroundings of Salt MirissaHotel, where you'll spend two nights",

      Day5: "Day-05",
      Desc5:
        "Explore the enchanting attractions of Mirissa after breakfast.Venture to Blue Island, a secluded paradise surrounded byturquoise waters, and marvel at the breathtaking Coconut Hills.Experience the ancient art of Stilt Fishing, a traditional fishingmethod practiced by local fishermen. For those seekingadventure, snorkeling offers the opportunity to discover thevibrant marine life thriving beneath the waves",

      Day6: "Day-06",
      Desc6:
        "Embark on an enchanting journey to Bentota after breakfast. Discover the historic charm of Galle with a day trip to Galle Fort, a UNESCO World Heritage Site dating back to the Dutch colonial era. Explore the quaint streets lined with colonial-style monuments and immerse yourself in the rich cultural heritage. Visit the mask museum to learn about the traditional craft of mask-making, and witness the conservation efforts at the turtle hatchery. Conclude the day with a scenic boat ride along the picturesque Madu River, surrounded by lush mangrove forests.",

      Day7: "Day-07",
      Desc7:
        "After a leisurely breakfast, bid farewell to the picturesquelandscapes of Sri Lanka as you depart for BandaranaikeInternational Airport. Reflect on the unforgettablememories and experiences gained during your journey asyou prepare for your onward flight",
    },

    inclusion: {
      point1:
        "Private air-conditioned tourist vehicle with an English-speaking chauffeur guide for tour and transfers, including arrival and departure.",
      point2:
        "Accommodation: Double/Single/Triple rooms on Half Board Basis at Ocean Colombo, Craft Tunnel, and Joe ’ s Resort, and Bed & Breakfast at Salt Mirissa. ",
      point3:
        "Free visits to Galle Fort, Mask factory, Toddy Tapping experience, Colombo Fort, Independence Square, Old Parliament Building, and Old Colombo Dutch Hospital.",
      point4:
        "Entrance fees to Madu River boat Safari, Turtle Hatchery & Udawalawe Safari (optional and direct pay by the clients).  1.City tours in Colombo & Galle. 2.Two water bottles per person per day during the tour (FOC). 3.Sim Cards (without data). 4.Insurance coverage for each passenger during the tour",
    },

    exclusion: {
      point1:
        "Personal Expenses such as telephone calls, laundry, drinks, etc. ",
      point2:
        "Lunch during the stay in Sri Lanka, Tips and Porterage, Camera Permits for filming.",
      point3: "Any other expenses not mentioned in the 'inclusions'.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg29,
    featured: true,
  },
  {
    id: "Srilanka_two",
    title: "Sri Lanka Unveiled Eternal Memories",
    city: "Srilanka",
    distance: 300,
    price: 142961,
    address: "Srilanka",
    packages: "8 Nights/9 days",
    maxGroupSize: 2,
    person: "/2 adults",
    desc: {
      Day1: "Day-01",
      Desc1:
        "Upon arrival, transfer to Sigiriya. En route, visit  inneriya National Park for wildlife spottig. Overnight stay at Sigiriya Village Hotel.",

      Day2: "Day-02",
      Desc2:
        "After breakfast, opt for a visit to Sigiriya Rock Fortress and Pidurangala Rock. Experience a villa tour in Hiriwaduna before heading to Kandy. Visit a spice garden and enjoy the Cultural Dance Show. Overnight at Swisse Hotel Kandy.",

      Day3: "Day-03",
      Desc3:
        "In the morning, visit the Temple of the Tooth Relic before boarding the scenic train to Ella. Enjoy breathtaking views during the journey. Overnight stay at Oak Ray Ella Gap Hotel",

      Day4: "Day-04",
      Desc4:
        "After breakfast, explore Ella's attractions including Halpewatte Tea Factory, Rawana Falls, Little Adam’s Peak, and the Nine Arch Bridge. Optional activities include Flying Ravana. Overnight at Oak Ray Ella Gap Hotel",

      Day5: "Day-05",
      Desc5:
        "After breakfast, transfer to Udawalawe. Visit a traditional pottery craft center and opt for a visit to Upper Diyaluma falls en route. Overnight stay at the Craft Tunnel",

      Day6: "Day-06",
      Desc6:
        "After breakfast, enjoy an optional safari at Udawalawe National Park before transferring to Unawatuna via Mirissa. Overnight stay at Joes Resort Unawatuna. ",

      Day7: "Day-07",
      Desc7:
        "After breakfast, explore Unawatuna's beaches and historical sites such as Galle Fort. Optional activities include visiting Jungle Beach Unawatuna and Rumassala Mountain. Overnight at Joes Resort Unawatuna",

      Day8: "Day-08",
      Desc8:
        "After breakfast, transfer to Colombo via Ambalangoda and Bentota. Visit a mask factory, turtle hatchery, and experience a river safari. Explore Colombo City. Overnight at Ocean Colombo Hotel. ",

      Day9: "Day-09",
      Desc9:
        "After breakfast, enjoy a city tour of Colombo, visiting landmarks like the Old Parliament Building, Independence Memorial Hall, Dutch Hospital, and Gangarama Temple. Departure transfer to the airport",
    },

    inclusion: {
      point1:
        "Private air-conditioned transportation with an English-speaking chauffer guide",
      point2: "Accommodation on a double room sharing basis with breakfast",
      point3: "Excursions and city tours as mentioned in the itinerary",
      point4:
        "Two water bottles per person per day during the tour. 1.SIM cards with data 2.Insurance coverage for each passenger during the tour",
    },

    exclusion: {
      point1: "Personal expenses such as telephone calls, laundry, and drinks.",
      point2: "Tips and porterage, Camera permits for filming.",
      point3: "Any other expenses not mentioned in the inclusions.",
    },
    policy: {
      point1:
        "Full payment (100%) is required for bookings made in the same month.",
      point2:
        "Bookings within 2-3 months require a 25% payment of the total amount.",
      point3: "Bookings exceeding 4 months necessitate a 10% payment upfront.",
      point4: "Full settlement must occur 14 days before the arrival date.",
      point5:
        "Failure to make timely payments may result in the release of bookings, unless alternative arrangements are agreed upon with the hotel. ",
    },
    reviews: [],
    avgRating: 4.5,
    photo: tourImg30,
    featured: true,
  },
];
