import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { bali } from "../../assets/data/tours";

const BaliTourList = () => {
  return (
    <>
      {bali?.map((indo) => (
        <Col
          lg="4"
          md="6"
          sm="6"
          className="malaysia__cards mb-4"
          key={indo.id}
        >
          <TourCard tour={indo} />
        </Col>
      ))}
    </>
  );
};

export default BaliTourList;
