import { useState } from "react";
import "../../styles/faq.css";

const faqs = [
  {
    title: "What is included in a Lakshadweep Trip package?",
    text: "A standard Lakshadweep vacation package typically covers your accommodations at resorts or guest houses, meals (either full-board or half-board), guided tours, or activities. Some other Lakshadweep packages may also include extras like, water sports, or cultural experiences. It's important to review the details of each Lakshadweep tour package to make sure it fits your needs and preferences. We have a variety of Lakshadweep Packages, for example, the honeymoon Package, Lakshadweep Samudram Package, etc.",
  },
  {
    title:
      "What is the time for obtaining permits for a Lakshadweep trip package?",
    text: "The processing time for obtaining permits for a Lakshadweep trip package can vary depending on various factors, including the type of permit required and the current administrative procedures. If you book the Lakshadweep Samudram Package, it also depends upon the norms of government  The  GoL Travels can facilitate your trip can provide guidance on the permit application process and timelines for all Lakshadweep and other destination packages.",
  },
  {
    title:
      "When is the best time to visit Lakshadweep Islands for a Samudram Package tour?",
    text: "The best time to visit the Lakshadweep Islands for a Samudram Package tour is typically during the winter months, from November to March. During this time, the weather is pleasant, and the sea conditions are calm, making it an ideal period for water activities like snorkeling, and scuba diving, and boat trips can also be done in the blue waters of Lakshadweep. However, it's important to check the specific schedule and availability of the Lakshadweep Samudram Package tours as per the Govt guidelines, as they may vary.",
  },
  {
    title:
      "Can you provide the Lakshadweep Samudram Package 2024 schedule for planning my trip?",
    text: "The Lakshadweep Samudram Package schedule for 2024 can be obtained from the Lakshadweep Tourism Department or authorized tour operators offering these tours like us. The schedule typically booked online, outlines the departure dates, duration, itinerary, and pricing for each tour. GoL Travels will ensure to secure your spot and avoid last-minute disappointment in booking your Lakshadweep Cruise Package.",
  },
  {
    title: "How can I book a Lakshadweep Samudram package online?",
    text: "Booking a Lakshadweep Samudram package online can be done through our website, GoL Travels. To book online, travelers typically need to select their preferred tour dates, provide necessary personal details, and make a reservation payment. Confirmation of the booking is usually sent via email along with any additional instructions or requirements.",
  },
  {
    title:
      "Can I customize my Lakshadweep Holiday package to include specific preferences or activities?",
    text: "Yes, we provide customization. Travelers can often choose from a range of accommodation options, from luxury resorts to eco-friendly beach huts, based on their budget and comfort preferences Lakshadweep tour packages have it all. Additionally, activities and excursions can be tailored to suit different interests, whether it's water sports, cultural experiences, nature walks, or romantic sunset cruises, Lakshadweep Cruise package can be customized. With us, you can create a personalized itinerary that aligns with your dream vacation in Lakshadweep.",
  },
  {
    title:
      "What amenities are included in the Lakshadweep Bangaram Island package?",
    text: "The Lakshadweep Bangaram Island package provides guests with a comfortable­ and pleasant stay. There are AC huts that have air conditioning, especially customized as per different Lakshadweep packages and direct­ access to the beach, roomy rooms, beautiful views, and attentive­ service. You can enjoy the­ natural charm of the Lakshadweep Bangaram Island holiday package while staying in comfort and convenience­.",
  },
  {
    title:
      "Are there any specific water activities included in the Lakshadweep tour package itinerary?",
    text: "Common highlights may include visits to popular Lakshadweep islands like Bangaram, Agatti, and Kadmat, where travelers can engage in water sports like snorkeling, scuba diving, and kayaking in the best Lakshadweep tour package. Thrilling experiences with Lakshadweep Samudram Ship Package additionally. Exploring traditional fishing villages, and attending cultural performances may also be part of the itinerary in Lakshadweep.",
  },
  {
    title:
      "What amenities and facilities are provided during a  Lakshadweep Samudram Package tour?",
    text: "Learn about the onboard facilities, accommodation, dining options, in beautiful islands of Lakshadweep  and recreational activities available during a  Lakshadweep Samudram Package tour, ensuring comfort and enjoyment throughout your journey to Lakshadweep.",
  },
  {
    title:
      "What are the highlights of a 10-day tour package to the Lakshadweep Islands?",
    text: "A 10-day tour package to the Lakshadweep Islands allows ample time to explore the diverse attractions. Highlights may include visits to pristine beaches, coral reefs, and lagoons for snorkeling and diving adventures. Additionally, travelers can immerse themselves in the local culture by sampling delicious seafood cuisine in different Lakshadweep Packages. Explore the beauty of Lakshadweep at its fullest.",
  },
];

export default function LakshadweepFaqs() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Lakshadweep Packages FAQ's</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
