import React from "react";
import "./sitemap.css";

const Sitemap = () => {
  return (
    <>
      <h1>Goltravels Sitemap</h1>
      <div className="mainsitemap">
        <div>
          <ul>
            <h4>Home Page</h4>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/login">Login</a>
            </li>
            <li>
              <a href="/register">Register</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <h4>Packages</h4>
            <li>
              <a href="/packages/Bali">Bali, Indonesia</a>
              <ul>
                <li>
                  <a href="/packages/Bali/Bali_Odyssey">Bali Odyssey</a>
                </li>
                <li>
                  <a href="/packages/Bali/Bali_Island_Odyssey">
                    Bali Island Odyssey
                  </a>
                </li>
                <li>
                  <a href="/packages/Bali,%20Indonatia/BaliFamily_Adventure">
                    BaliFamily Adventure
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/packages/Thailand">Thailand</a>
              <ul>
                <li>
                  <a href="/packages/Thailand/Bangkok_Odyssey">
                    Bangkok Odyssey
                  </a>
                </li>
                <li>
                  <a href="/packages/Thailand/Pattaya_Delight">
                    Pattaya Delight
                  </a>
                </li>
                <li>
                  <a href="/packages/Thailand/Pattaya&Bangkok_Escapade">
                    Pattaya & Bangkok Escapade
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/packages/Malaysia">Malaysia</a>
              <ul>
                <li>
                  <a href="/packages/Malaysia/Malaysian_Marvels">
                    Malaysian Marvels
                  </a>
                </li>
                <li>
                  <a href="/packages/Malaysia/Malaysian_Odyssey">
                    Malaysian Odyssey
                  </a>
                </li>
                <li>
                  <a href="/packages/Malaysia/Kuala_Lumpur">Kuala Lumpur</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="/packages/Lakshadweep">Lakshadweep</a>
              <ul>
                <li>
                  <a href="/packages/Lakshadweep/Samudram">Samudram</a>
                </li>
                <li>
                  <a href="/packages/Lakshadweep/Bangaram">Bangaram</a>
                </li>
                <li>
                  <a href="/packages/Lakshadweep/Bangaram_premium">Samudram</a>
                </li>
                <li>
                  <a href="/packages/Lakshadweep/Agatti">Agatti</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="/packages/Maldives">Maldives</a>
              <ul>
                <li>
                  <a href="/packages/Maldives/ADAARAN">Adaaran</a>
                </li>
                <li>
                  <a href="/packages/Maldives/Dhiffushi">Dhiffushi</a>
                </li>
                <li>
                  <a href="/packages/Maldives/Thulusdhoo">Thulusdhoo</a>
                </li>
                <li>
                  <a href="/packages/Maldives/Dhiffushi&Thulusdhoo">
                    Dhiffushi & Thulusdhoo
                  </a>
                </li>
                <li>
                  <a href="/packages/Maldives/Maldives_Premium_Honeymoon">
                    Maldives Premium Honeymoon
                  </a>
                </li>
                <li>
                  <a href="/Maldives/Personalized_Island_Experience">
                    Personalized Island Experience
                  </a>
                </li>
                <li>
                  <a href="/packages/Maldives/Maldives_Submarine_Dive">
                    Maldives Submarine Dive
                  </a>
                </li>
                <li>
                  <a href="/packages/Maldives/Splendid_Serenity_Retreat">
                    Splendid Serenity Retreat
                  </a>
                </li>
                <li>
                  <a href="/packages/Maldives/Honeymoon_Hideaway_Experience_Two_Island">
                    Honeymoon Hideaway Experience Two Island
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
