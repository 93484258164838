import { useState } from "react";
import "../../../styles/faq.css";

const faqs = [
  {
    title: "What is included in the Bangaram Bliss Lakshadweep Package?",
    text: "This package includes accommodation in an AC cottage on Bangaram Island, all meals (breakfast, lunch, and dinner), transfers between Agatti and Bangaram Island, entry permits, personalized support and escort services, and a special candlelit dinner. The package ensures a seamless and memorable experience on the island.",
  },
  {
    title:
      "Are water sports activities included in the Bangaram Bliss package?",
    text: "Water sports activities, including snorkeling, kayaking, glass-bottom boat rides, and scuba diving, are available at an additional cost. While the package offers a range of activities to enhance your experience, these specific adventures can be booked separately at the watersports center on Bangaram Island.",
  },
  {
    title: "How does the transfer from Agatti Airport to Bangaram Island work?",
    text: "Upon arrival at Agatti Airport, you’ll be welcomed and taken to the ferry jetty. From there, you'll enjoy a scenic one-hour boat ride to Bangaram Island. The boat transfer is included in your package and offers a chance to spot dolphins along the way, setting the tone for your island adventure.",
  },
  {
    title: "Can I customize my Bangaram Bliss package?",
    text: "Yes, the Bangaram Bliss package offers some flexibility for customization. If you wish to explore additional islands, extend your stay, or include specific activities, GoL Travels can assist in tailoring the package to fit your preferences. However, any customizations may come with additional costs.",
  },
  {
    title: "What is the payment policy for the Bangaram Bliss package? ",
    text: "The payment policy requires a 25% deposit if booking within 2-3 months before arrival, or a 10% deposit if booking more than 4 months in advance. Full payment is due 14 days before your arrival. In case of unfavorable weather or technical faults, a 100% refund will be provided, but no other claims will be entertained.",
  },
  {
    title:
      "  When is the best time to book the Bangaram Bliss Lakshadweep Package?",
    text: "The best time to visit Bangaram Island is between November and March, when the weather is pleasant, and the sea is calm, ideal for water activities and exploration. Booking in advance is recommended to secure your preferred dates and ensure availability.",
  },
  {
    title:
      " What amenities are included in the Lakshadweep Bangaram Island package?",
    text: "The Lakshadweep Bangaram Island package provides guests with a comfortable­ and pleasant stay. There are AC huts that have air conditioning, specially customized as per different Lakshadweep packages, and direct­ access to the beach, roomy rooms, beautiful views, and attentive­ service. You can enjoy the­ natural charm of the Lakshadweep Bangaram Island holiday package while staying in comfort and convenience­.",
  },

  {
    title: "What’s included in a Lakshadweep package?",
    text: "A Lakshadweep package typically includes accommodations, meals (full or half-board), and guided tours. Some packages may also offer water sports or cultural experiences. Review each package to match your preferences.",
  },
  {
    title: "How long to get permits for a Lakshadweep trip?",
    text: "The time varies based on the permit type and administrative processes. For packages like Lakshadweep Samudram, it depends on government regulations. GoL Travels can assist with permits and timelines.",
  },
  {
    title: "Best time to visit Lakshadweep for a Samudram Package tour?",
    text: "The ideal time is November to March when the weather is pleasant and sea conditions are perfect for water activities. Check specific schedules, as they may vary with government guidelines.",
  },
  {
    title: "What is the Lakshadweep Package for LTC?",
    text: "The Lakshadweep Package for LTC (Leave Travel Concession) is a special travel package designed for government employees. It allows you to explore the beautiful islands of Lakshadweep while utilizing your LTC benefits.",
  },
  {
    title: "Who is eligible for the Lakshadweep Package for LTC?",
    text: "Government employees and their families who are eligible for LTC benefits can avail of this package.",
  },
];

export default function BangaramFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Bangaram Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
