import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";
import maleTourist from "../assets/images/male-tourist.png";
import { Link } from "react-router-dom";

const Newsletter = () => {
  return (
    <section>
      <Container className="newsletter">
        <Row>
          <Col lg="6">
            <div className="newsletter__content">
              <h2>Subscribe for Your ticket to Extraordinary Journeys!</h2>
              <div className="newsletter__input">
                <input type="email" placeholder="Enter your email" />
                <button className="btn newsletter__btn">Subscribe</button>
              </div>
              <p>
                Adventure awaits, and we've got the key to unlock a world of
                travel wisdom just for you! Subscribe now, and join our tribe of
                savvy explorers. Imagine receiving not just information but a
                personalized guide, let us be your compass in the world of
                wanderlust.
              </p>
              <button>
                <Link to="/packages/Lakshadweep/DmcLakshadweep">
                  DMC for Lakshadweep
                </Link>
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="newsletter__img">
              <img src={maleTourist} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
