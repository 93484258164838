import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { Lakshadweeps } from "../../assets/data/tours";
import { Helmet } from "react-helmet";

const LakshadweepTourList = (tour) => {
  const { metaTitle, metaDesc } = tour;
  return (
    <>
      {Lakshadweeps?.map((tour) => (
        <Col lg="3" md="6" sm="6" className="mb-4" key={tour.id}>
          <TourCard tour={tour} />
        </Col>
      ))}
    </>
  );
};

export default LakshadweepTourList;
