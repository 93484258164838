import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-header">Terms and Conditions</h1>
      <div className="terms-content">
        <p>
          Welcome to our website. By accessing and using this website, you agree
          to comply with and be bound by the following terms and conditions...
        </p>
        {/* More content */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
