import React from "react";
import PackageCard from "../../shared/PackageCard";
import { Col } from "reactstrap";
import { newPackages } from "../../assets/data/tours";
import Slider from "react-slick";
import "./newPackeage.css";

const NewPackagesList = () => {
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {newPackages?.map((packages) => (
          <Col lg="3" md="6" sm="6" className="mb-4" key={packages.id}>
            <PackageCard tour={packages} />
          </Col>
        ))}
      </Slider>
    </>
  );
};

export default NewPackagesList;
