import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { malaysia } from "../../assets/data/tours";

const MalaysiaTourList = () => {
  return (
    <>
      {malaysia?.map((mal) => (
        <Col lg="4" md="6" sm="6" className="malaysia__cards mb-4" key={mal.id}>
          <TourCard tour={mal} />
        </Col>
      ))}
    </>
  );
};

export default MalaysiaTourList;
