import React, { useEffect, useRef, useState } from "react";
import "../styles/tour-details.css";
import { useParams } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "reactstrap";
import calculateAvgRating from "../utils/avgRating";
import avatar from "../assets/images/avatar.jpg";
import Booking from "../components/Booking/Booking";
import Newsletter from "../shared/Newsletter";
// import { BASE_URL } from "../utils/config";
// import useFetch from "../hooks/useFetch";
import { AllTours, LakshadweepTours } from "../assets/data/tours";

import "../shared/tour-card.css";
import LakshadweepTourDetails from "./AllTours/LakshadweepTourDetails";
import MaldivesTourDetails from "./AllTours/MaldivesTourDetails";
import BaliTourDetails from "./AllTours/BaliTourDetails";
import ThailandTourDetails from "./AllTours/ThailandTourDetails";
import MalaysiaTourDetails from "./AllTours/MalaysiaTourDetails";
import SrilankaTourDetails from "./AllTours/SrilankaTourDetails";
import { Helmet } from "react-helmet";

const TourDetails = () => {
  const { id } = useParams();
  console.log(id);
  const reviewMsgRef = useRef("");
  // eslint-disable-next-line no-unused-vars
  const [tourRating, setTourRating] = useState(null);

  const tour = AllTours.find((tour) => tour.id === id);

  // fetch data from database
  //const { data: tour, loading, error } = useFetch(`${BASE_URL}/tours/${id}`);

  const {
    photo,
    title,
    desc,
    inclusion,
    exclusion,
    policy,
    price,
    packages,
    reviews,
    city,
    maxGroupSize,
    metaTitle,
    metaDesc,
  } = tour;
  console.log(city);

  const { totalRating, avgRating } = calculateAvgRating(reviews);

  // format date
  const options = { day: "numeric", month: "long", year: "numeric" };

  // submit request to the server
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   const reviewText = reviewMsgRef.current.value;
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tour]);

  return (
    <>
      <div>
        {city === "Lakshadweep" ? (
          <div>
            <Helmet>
              <title>{tour.metaTitle}</title>
              <meta name="description" content={tour.metaDesc} />
              <meta name="keywords" content={tour.metaKeywords} />
            </Helmet>
            <LakshadweepTourDetails />
          </div>
        ) : city === "Maldives" ? (
          <div>
            <MaldivesTourDetails />
          </div>
        ) : city === "Bali, Indonesia" ? (
          <div>
            <BaliTourDetails />
          </div>
        ) : city === "Thailand" ? (
          <div>
            <ThailandTourDetails />
          </div>
        ) : city === "Malaysia" ? (
          <div>
            <MalaysiaTourDetails />
          </div>
        ) : (
          <div>
            <SrilankaTourDetails />
          </div>
        )}
      </div>

      {/* <Newsletter /> */}
    </>
  );
};

export default TourDetails;
