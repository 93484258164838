import Card from "react-bootstrap/Card";
import "./aboutCard.css";
// import { SalesTeam } from '../../assets/data/alldata';

//founder
import sabeernew from "../../assets/allimages/Sabeer_sir-3.webp";

//cardTour
import AboutUs from "../../assets/images/AboutUs.png";
import Mission from "../../assets/images/Mission.png";
import Vision from "../../assets/images/Vision.png";
import WhyGol from "../../assets/images/WhyGol.png";
import AboutReadMoreButton from "./AboutReadMore";

//directors
import kasali from "../../assets/allimages/Kasali.webp";
import nabeel from "../../assets/allimages/nabeel.webp";

//Department
import zakaria from "../../assets/allimages/Zakaria.webp";
import aman from "../../assets/allimages/Aman.webp";
import indra from "../../assets/allimages/indrajeeth1.webp";
import Rajvi from "../../assets/allimages/Rajvi.webp";

// Technical Team
import govi from "../../assets/allimages/Goviraj.webp";
import rahul from "../../assets/allimages/Rahul.webp";

//Transport Team
import yafee from "../../assets/allimages/Yafee.webp";
import hamdulla from "../../assets/allimages/Hambulla.webp";
import mufaruddin from "../../assets/allimages/Mufahirudheen.webp";
import shareef from "../../assets/allimages/Shareef.webp";
import shameer from "../../assets/allimages/Shameer.webp";
import sahalsinan from "../../assets/allimages/SahalSinan.webp";
import hajahussain from "../../assets/allimages/HajaHussain.webp";
import savad from "../../assets/allimages/Savad.png";
import navas from "../../assets/allimages/Navas.png";
import megha from "../../assets/allimages/Megha.png";

//social media
import balu from "../../assets/allimages/balu.webp";
import aasta from "../../assets/allimages/aasta.webp";
import krushna from "../../assets/allimages/krushna.webp";

//Sales Team
import ashw from "../../assets/allimages/Aswin.webp";
import jasmin from "../../assets/allimages/Jasmin.webp";
import pranav from "../../assets/allimages/Pranav.webp";
import rasid from "../../assets/allimages/Rashid.webp";
// import shifa from "../../assets/allimages/Shifa Rafeeque.webp";
// import shivani from "../../assets/allimages/Shivani.webp";
// import nitya from "../../assets/allimages/Nityan.webp";
import mantasa from "../../assets/allimages/Mantasa.webp";
import asab from "../../assets/allimages/Asab.webp";
import michael from "../../assets/allimages/Michael.jpg";
import chandra from "../../assets/allimages/chandra.jpg";
import najeeb from "../../assets/allimages/Najeeb.png";
import hidayath from "../../assets/allimages/Hidayath.png";
import khaleel from "../../assets/allimages/Khaleel.png";
import dharnesh from "../../assets/allimages/Dharnesh.png";
// import daniel from "../../assets/allimages/Daniel.jpg";

//guest relation
// import Erfan from "../../assets/allimages/Erfan.webp";
import krishna from "../../assets/allimages/Krishnakant.webp";
import jepsy from "../../assets/allimages/jepsy.webp";
import deepika from "../../assets/allimages/Deepika.webp";
import umavathi from "../../assets/allimages/umavathi.webp";
import Dharnesh from "../../assets/allimages/Dharnesh.png";

//Accounts
// import shama from "../../assets/allimages/shama.webp";
// import farsana from "../../assets/allimages/Farsana.webp";
import simna from "../../assets/allimages/simna.webp";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";

function AboutCard() {
  const ourMission =
    "Welcome travelers, We are GOl Travels- your passport to unforgettable adventures! Yes, you heard it right. We aim to redefine travel, connecting individuals with authentic experiences worldwid Starting from the beautiful beaches of the Maldives and the pristine landscapes of Lakshadweep, we've added amazing destinations like Thailand, Malaysia, and the picturesque Bali in Indonesia to our list.  At GOL Travels, we connect people through tailor-made experiences, ensuring every journey is a personalized exploration of diverse cultures and landscapes. Our commitment to quality, global presence, and sustainable travel makes us your trusted partner in creating lasting memories.  Join us on a GOL Travel adventure and discover a world where extraordinary experiences meet seamless travel experti";

  const ourVision =
    "To provide indian tourist diverse travel experience in to the white sandy beaches of Lakshadweep and Maldives and to make Lakshadweep tourism a Global presence by innovating travel technology, offering a customer-centric approach, diverse travel experience, and sustainable tourism.";

  const aboutUs =
    "Welcome to Gol Travels, your gateway to the mesmerizing paradise of Lakshadweep and the tropical haven of the Maldives. GOL Travels is a Government Authorized travel agent; we provide tour packages and holiday services to the enchanting Union Territory of Lakshadweep and the coral Islands of Maldives. GoL is owned and managed by GoL Ventures Pvt Ltd, committed to creating unforgettable travel experiences in these breath-taking destinations of Lakshadweep and Maldives.We are dedicated to delivering exceptional vacations that will leave you with long lasting memories with your beloveds. As pioneers in digitalizing the travel process exclusively for the islands of Lakshadweep, we take immense pride in showcasing their unspoiled splendour to the global community. In the Maldives, we do the entire budget friendly and premium packages for the tourists from India and we have our own property in Lakshadweep and Maldives focusing on a customer-centric approach and setting the highest quality standards to craft extraordinary moments, especially for you";

  const whyGol =
    "We aim to make booking a trip to Lakshadweep and the Maldives as effortless as reserving any other sought-after tourist destination across the globe. As a government-authorized travel agent, we take care of all the government stipulated requirements and local travel assistance, allowing you to unwind and revel in a truly relaxing getaway to Lakshadweep. Embarking on a trip to Lakshadweep is not a mere fleeting endeavor; it entails a unique and captivating experience.That’s precisely why we have meticulously developed this platform, aiming to make booking a trip to Lakshadweep as effortless as reserving any other sought-after tourist destination across the globe. GOL stands as the sole indigenous Lakshadweep islander’s platform, revolutionizing travel experiences through our dynamic Web Portal. As an Islander we can give best service to the tourist. Our mission is to present the awe-inspiring beauty of these exotic islands to the world, rivalling and even surpassing the allure of numerous renowned beach destinations. Even for Indian travellers, discovering this hidden gem within their own country will undoubtedly come as a delightful surprise.";

  const Directors = [
    {
      image: kasali,
      name: " PK Kasali",
      role: "Director",
    },
    {
      image: nabeel,
      name: "Nabeel",
      role: "Director",
    },
  ];

  const Department = [
    
    {
      image: aman,
      name: "Aman Upadyay",
      role: "Chief Operating Officer",
    },
    {
      image: zakaria,
      name: "Zakaria",
      role: "Sr.Manager(Admin) ",
    },
    {
      image: Rajvi,
      name: "Rajvi",
      role: "Hr.Manager",
    },
    {
      image: indra,
      name: "Inderjeet Janagal",
      role: "Manager Marketing",
    },
    {
      image: jepsy,
      name: "Jepsy",
      role: "Manager",
    },
    
  ];

  const Technical = [
    {
      image: rahul,
      name: "Rahul Hulikoppe",
      role: "Software Engineer",
    },
    {
      image: govi,
      name: "Govindaraj GC",
      role: "Software Engineer",
    },
  ];

  const TransportTeam = [
    {
      image: yafee,
      name: "Yafee",
      role: "Manager",
    },
    {
      image: hamdulla,
      name: "Hamdulla",
      role: "Transport Team",
    },
    {
      image: mufaruddin,
      name: "Mufahirudhen",
      role: "Transport Team",
    },
    {
      image: shareef,
      name: "Shareef",
      role: "Transport Team",
    },

    {
      image: shameer,
      name: "Sameer",
      role: "Transport Team",
    },
    {
      image: sahalsinan,
      name: "Sahal Sinan",
      role: "Transport Team",
    },
    {
      image: hajahussain,
      name: "Haja Hussain",
      role: "Transport Team",
    },
    // {
    //   image: savad,
    //   name: "Savad",
    //   role: "Transport Team",
    // },
    {
      image: navas,
      name: "Navas",
      role: "Transport Team",
    },
    // {
    //   image: megha,
    //   name: "Megha",
    //   role: "Transport Team",
    // },
  ];

  const SocialMedia = [
    {
      image: krushna,
      name: "Krushna Musne",
      role: "Social Media Executive",
    },
    {
      image: balu,
      name: "Balaraj",
      role: "Graphic Designer",
    },
    {
      image: aasta,
      name: "Aastha",
      role: "Content Writer",
    },
  ];

  const SalesTeam = [
    {
      image: ashw,
      name: "Ashwin",
      role: "Team Sales",
    },
    {
      image: jasmin,
      name: "Jasmin",
      role: "Team Sales",
    },

    {
      image: pranav,
      name: "Pranav",
      role: "Team Sales",
    },
    {
      image: rasid,
      name: "Rashid",
      role: "Team Sales",
    },
   
    {
      image: mantasa,
      name: "Mantasa",
      role: "Team Sales",
    },
    {
      image: michael,
      name: "Michael Roberts",
      role: "Team Sales",
    },
    
    {
      image: chandra,
      name: "Chandrakala",
      role: "Team Sales",
    },
    {
      image: najeeb,
      name: "Najeeb",
      role: "Team Sales",
    },
    {
      image: hidayath,
      name: "Hidayath",
      role: "Team Sales",
    },
    {
      image: khaleel,
      name: "Khaleel",
      role: "Team Sales",
    },

    
  ];

  const TeamOperation = [
    
    {
      image: krishna,
      name: "Krishnakanth",
      role: "Team Operation",
    },
    {
      image: deepika,
      name: "Deepika",
      role: "Guest Relation",
    },
    {
      image: asab,
      name: "Ashab",
      role: "Guest Relation",
    },
    {
      image: umavathi,
      name: "Umavathi",
      role: "Team Operation",
    },
    {
      image: Dharnesh,
      name: "Dharnesh",
      role: "Team Operation",
    },
  ];

  const Accounts = [
    {
      image: simna,
      name: "Simna PB",
      role: "Accountant",
    },
  ];

  const renderCard = (card, index) => {
    return (
      <div className="box">
        <Card style={{ maxWidth: "100%", maxHeight: "300px" }} key={index}>
          <Card.Img className="card-imgs" variant="top" src={card.image} />
          <Card.Body>
            <Card.Title className="card-name">{card.name}</Card.Title>
            <Card.Text className="card-role">{card.role}</Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Meet the­ Creators of Memorable Trips | Know our Gol Trave­l’s Story
        </title>
        <meta
          name="description"
          content=" Learn about the pe­ople behind your dream vacations at Gol Trave­ls. Read our story, fueled by a love­ of travel, skills, and a strong desire to craft e­xperiences you'll ne­ver forget. Click here to learn more"
        />
      </Helmet>
      <section>
        {" "}
        <Container>
          <Row>
            <Col lg="6">
              <img src={sabeernew} class="img-fluid rounded-start" alt="..." />
            </Col>
            <Col lg="6">
              <div className="ceo-note">
                <p className="ceo-history">
                  Mr.Sabeer is a dynamic and visionary entrepreneur hailing from
                  Lakshadweep, distinguished for spearheading and steering a
                  diverse portfolio of successful ventures. A passionate
                  advocate for travel and tourism, he sets his sights on
                  elevating the Lakshadweep tourism industry to world-class
                  standards. He pioneers the infusion of digital technologies
                  into the travel industry, revolutionizing its landscape. His
                  innovative approach and embrace of modern business paradigms
                  serve as a testament to his adaptability and foresight.
                </p>
                <h5>Sabeer</h5>
                <p className="designation">Founder & CEO</p>{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div class="col">
                <div class="card-color">
                  <img src={Mission} class="card-images" alt="..." />
                  <div class="card-body">
                    <h4 class="card-title">our mission</h4>
                    <AboutReadMoreButton text={ourMission} maxLength={200} />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div class="col">
                <div class="card-color">
                  <img src={Vision} class="card-images" alt="..." />
                  <div class="card-body">
                    <h4 class="card-title">our vision</h4>
                    <AboutReadMoreButton text={ourVision} maxLength={200} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div class="col">
                <div class="card-color">
                  <img src={AboutUs} class="card-images" alt="..." />
                  <div class="card-body">
                    <h4 class="card-title">about us</h4>
                    <AboutReadMoreButton text={aboutUs} maxLength={200} />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div class="col">
                <div class="card-color">
                  <img src={WhyGol} class="card-images" alt="..." />
                  <div class="card-body">
                    <h4 class="card-title">why gol</h4>
                    <AboutReadMoreButton text={whyGol} maxLength={200} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div>
        <h1>Directors</h1>
      </div>
      <div className="grid">{Directors.map(renderCard)}</div>

      <div>
        <h1>Management</h1>
      </div>
      <div className="grid">{Department.map(renderCard)}</div>

      <div>
        <h1>Team Sales</h1>
      </div>
      <div className="grid">{SalesTeam.map(renderCard)}</div>

      <div>
        <h1>Team Social Media & Marketing </h1>
      </div>
      <div className="grid">{SocialMedia.map(renderCard)}</div>

      <div>
        <h1>Team Operation & Guest Relation </h1>
      </div>
      <div className="grid">{TeamOperation.map(renderCard)}</div>

      {/* <div>
        <h1>Team Guest Relation </h1>
      </div>
      <div className="grid">{GuestRelation.map(renderCard)}</div> */}

      <div>
        <h1>Team Accounts </h1>
      </div>
      <div className="grid">{Accounts.map(renderCard)}</div>

      <div>
        <h1>Team Technical</h1>
      </div>
      <div className="grid">{Technical.map(renderCard)}</div>

      <div>
        <h1>Team Transport</h1>
      </div>
      <div className="grid">{TransportTeam.map(renderCard)}</div>
    </>
  );
}

export default AboutCard;
