import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Tours from "../pages/Tours";
import TourDetails from "../pages/TourDetails";
import Login from "../pages/Login";
import Register from "../pages/Register";
import SearchResultList from "../pages/SearchResultList";
import About from "../pages/About";
import ThankYou from "../pages/ThankYou";
import Lakshadweep from "../shared/Lakshadweep";
import Bali from "../shared/Bali";
import Maldives from "../shared/Maldives";
import Thailand from "../shared/Thailand";
import Malaysia from "../shared/Malaysia";
// import BlogPost from "../components/Blog/BlogPost";
import ContactCard from "../components/Contact/ContactCard";
import Sitemap from "../components/Sitemap/Sitemap";
import TermsAndConditions from "../components/Terms&Conditions/TermsAndConditions";
// import Sitemap from "../components/Sitemap/Sitemap";
// import TermsAndConditions from "../components/Terms&Conditions/TermsAndConditions";
import CancellationAndRefund from "../components/Terms&Conditions/CancellationAndRefund";
import { EnquiryEmail } from "../components/EnquiryForm/EnquireEmail";
import MaldivesCategoryPage from "../pages/MaldivesMain/MaldivesCategoryPackages/MaldivesCategoryPage";
import MaldivesHoneymoon from "../pages/MaldivesMain/MaldivesSubCategory/MaldivesHoneymoon";
import MaldivesTourDetails from "../pages/AllTours/MaldivesTourDetails";
import Srilanka from "../shared/Srilanka";
import DmcLakshadweep from "../pages/DmcLakshadweep";
// import BlogDetail from "../components/Blog/BlogDetail";
// import DmcLakshadweep from "../pages/DmcLakshadweep";
// import BlogPostCard from "../pages/GolBlogs/BlogPostCard";
import FullBlogPost from "../pages/GolBlogs/FullBlogPost";
import GolBlogList from "../pages/GolBlogs/BlogList";
import samplePosts from "../assets/data/BlogsData/samplePosts";

// import MaldivesTourDetails from "../pages/AllTours/MaldivesTourDetails";
// import LakshadweepTourDetails from "../pages/AllTours/LakshadweepTourDetails";
// import BaliTourDetails from "../pages/AllTours/BaliTourDetails";
// import MalaysiaTourDetails from "../pages/AllTours/MalaysiaTourDetails";
// import ThailandTourDetails from "../pages/AllTours/ThailandTourDetails";
const Routers = () => {
  const [posts, setPosts] = useState(samplePosts);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      {/* <Route path="/packages" element={<Tours />} /> */}
      <Route path="/packages/lakshadweep" element={<Lakshadweep />} />
      {/* ---------------Maldives Routes -----------------*/}
      <Route path="/packages/maldives" element={<Maldives />} />
      <Route path="/packages/maldives" element={<MaldivesCategoryPage />} />
      <Route
        path="/packages/maldives/:category"
        element={<MaldivesHoneymoon />}
      />

      <Route path="/packages/bali" element={<Bali />} />
      <Route path="/packages/thailand" element={<Thailand />} />
      <Route path="/packages/malaysia" element={<Malaysia />} />
      <Route path="/packages/srilanka" element={<Srilanka />} />
      <Route path="/packages/:city/:id" element={<TourDetails />} />
      <Route
        path="/packages/:city/:category/:id"
        element={<MaldivesTourDetails />}
      />

      {/* <Route path="/packages/:city/:id" element={<LakshadweepTourDetails />} />
      <Route path="/packages/:city/:id" element={<BaliTourDetails />} /> */}
      {/* <Route path="/packages/:city/:id" element={<MalaysiaTourDetails />} />
      <Route path="/packages/:city/:id" element={<ThailandTourDetails />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="paymentsuccess" element={<ThankYou />} />
      <Route path="/tours/search" element={<SearchResultList />} />

      <Route path="/blog/" element={<GolBlogList posts={posts} />} />
      <Route path="/blog/:id" element={<FullBlogPost posts={posts} />} />

      <Route path="/contact" element={<ContactCard />} />
      <Route path="/sitemap.html" element={<Sitemap />} />
      <Route path="/terms&conditions" element={<TermsAndConditions />} />
      <Route path="/Cancellation&Refund" element={<CancellationAndRefund />} />
      <Route path="/enquiry" element={<EnquiryEmail />} />
      <Route
        path="/packages/lakshadweep/DmcLakshadweep"
        element={<DmcLakshadweep />}
      />
    </Routes>
  );
};

export default Routers;
