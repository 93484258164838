import { useState } from "react";
import "../../../styles/faq.css";

const faqs = [
  {
    title: "What does the Agatti Island package include?",
    text: "The package covers lodging, all-inclusive dining, transfers to and from Agatti Island, individual support, an entry permit, and a cozy candlelit dinner. Water sports and day trips to other islands are available at an additional cost.",
  },
  {
    title: " What activities can I enjoy on Bangaram Island with this package?",
    text: "You can engage in various water sports like snorkeling, kayaking, and glass-bottom boat rides. Scuba diving is also available for an additional fee. The package offers a mix of adventure and relaxation, perfect for exploring the island's natural beauty.",
  },
  {
    title: "Can the itinerary be customized?",
    text: "Yes, with the Lakshadweep Package for LTC, you can request a custom-made itinerary tailored to your specific needs and preferences, ensuring a unique and personalized experience.",
  },
  {
    title: "What are the payment policies for this package?",
    text: "A 25% payment is required for bookings made within 2-3 months of the trip. For bookings made more than 4 months in advance, a 10% upfront payment is needed. Full payment is due 14 days before arrival. Refunds are available if the trip is canceled due to unfavorable weather or technical faults.",
  },
  {
    title: "When is the best time to visit Agatti Island?",
    text: "The ideal time to visit Agatti Island is from November to March when the weather is pleasant, and sea conditions are favorable for water activities.",
  },
  {
    title: "  What should I expect upon arrival at Agatti Island?",
    text: "Upon arrival at Agatti Airport, you'll be welcomed and transferred to a boat for a one-hour ride to Bangaram Island. You'll be greeted with a refreshing drink and have the rest of the day to explore the island's beauty.",
  },
  {
    title:
      " What are the highlights of a 10-day tour package to the Lakshadweep Islands?",
    text: "A 10-day tour package to the Lakshadweep Islands allows ample time to explore the diverse attractions. Highlights may include visits to pristine beaches, coral reefs, and lagoons for snorkeling and diving adventures. Additionally, travelers can immerse themselves in the local culture by sampling delicious seafood cuisine in different Lakshadweep Packages. Explore the beauty of Lakshadweep at its fullest.",
  },

  {
    title: "What is the Lakshadweep Package for LTC?",
    text: "The Lakshadweep Package for LTC (Leave Travel Concession) is a special travel package designed for government employees. It allows you to explore the beautiful islands of Lakshadweep while utilizing your LTC benefits.",
  },
  {
    title: "Who is eligible for the Lakshadweep Package for LTC?",
    text: "Government employees and their families who are eligible for LTC benefits can avail of this package.",
  },
];

export default function AgattiFaq() {
  return (
    <div>
      <Accordion data={faqs} />
    </div>
  );
}

function Accordion({ data }) {
  return (
    <>
      <h1 className="head-line">Agatti Packages FAQ’s</h1>
      <div className="accordion123">
        {data.map((el, i) => (
          <AccordionItem
            title={el.title}
            text={el.text}
            num={i}
            key={el.title}
          />
        ))}
      </div>
    </>
  );
}

function AccordionItem({ num, title, text }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className={`item ${isOpen ? "open" : ""}`} onClick={handleToggle}>
      <p className="number">{num < 9 ? `0${num + 1}` : num + 1}</p>
      <p className="title">{title}</p>
      <p className="icon">{isOpen ? "-" : "+"}</p>
      {isOpen && <div className="content-box">{text}</div>}
    </div>
  );
}
