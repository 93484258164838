import React from "react";
import "./service-card.css";
import ReadMoreButton from "./ReadMoreButton";

const ServiceCard = ({ item }) => {
  const { imgUrl, title, desc } = item;
  return (
    <div className="service__item">
      <div className="service__img">
        <img src={imgUrl} alt="" />
      </div>
      <h5>{title}</h5>
      {/* <p>{desc}</p> */}
      <ReadMoreButton text={desc} maxLength={100} />

      {/* <ReadMoreButton text={desc} maxLength={100} /> */}
    </div>
  );
};

export default ServiceCard;
