/* eslint-disable no-lone-blocks */
import React from "react";
import { Card } from "reactstrap";
// import { Link } from "react-router-dom";
// import "./tour-card.css";
// import "./packageCard.css";
import { Link } from "react-router-dom";
import TrendingTag from "../../../shared/TrendingTag";

// import tour from "../assets/data/tours"

const MaldivesCardDesign = ({ tour }) => {
  const { id, city, photo, category } = tour;
  console.log(category);

  return (
    <div className="tour__card">
      <Card>
        <div className="tour__img">
          <div style={{ position: "relative", display: "inline-block" }}>
            <Link rel="canonical" to={`/packages/${city}/${category}`}>
              <img className="curser" src={photo} alt="tour-img" />
            </Link>
            <TrendingTag />
            {/* </Link> */}
          </div>
          <div className="slider-text">{category}</div>
        </div>
      </Card>
      {/* ))} */}
    </div>
  );
};

export default MaldivesCardDesign;
