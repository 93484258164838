import tourImg04 from "../images/packages_imgs/maldives/Dhiffushi_island.webp";
import tourImg05 from "../images/packages_imgs/maldives/Thulusdhoo_island.webp";
import tourImg06 from "../images/packages_imgs/maldives/Thulusdhoo_Dhiffushi_Island.webp";
import tourImg07 from "../images/packages_imgs/maldives/Maldives_Premium_Honeymoon.png";
import tourImg08 from "../images/packages_imgs/maldives/Personalised_island_experiences.jpg";
import tourImg09 from "../images/packages_imgs/maldives/Maldives_Submarine.webp";
import tourImg10 from "../images/packages_imgs/maldives/Splendid_Serenity.webp";
import tourImg11 from "../images/packages_imgs/maldives/Honeymoon_Hideaway_Experience_Two_Island.webp";
import tourImg24 from "../images/sliding_packages_imgs/lakshadsweep.webp";
import tourImg25 from "../images/sliding_packages_imgs/maldives.webp";
import tourImg26 from "../images/sliding_packages_imgs/bali.webp";
import tourImg27 from "../images/sliding_packages_imgs/thailand.webp";
import tourImg31 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/adaaran_club_rannalhi.jpg";
import tourImg32 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/adaaran_hudhuran_fushi.jpg";
import tourImg33 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/adaaran_prestige_vadoo.jpg";
import tourImg34 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/arena_beach_hotel.jpg";
import tourImg35 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/embudu_village_resort.jpg";
import tourImg36 from "../images/packages_imgs/maldives/Honeymoon&Resort_imgs/hardrock_resort.jpg";

// Main category

export const MaldivesCatogories = [
  {
    id: "Budget_Package",
    city: "Maldives",
    category: "Budget_Packages",
    photo: tourImg24,
    featured: true,
  },
  {
    id: "Honeymoon_Package",
    city: "Maldives",
    category: "Honeymoon",
    photo: tourImg25,
    featured: true,
  },
  {
    id: "Resort_Package",
    city: "Maldives",
    category: "Resort_Packages",
    photo: tourImg26,
    featured: true,
  },
  {
    id: "Watersports_Package",
    city: "Maldives",
    category: "Watersports",
    photo: tourImg27,
    featured: true,
  },
];

// Honeymoon Packages

export const maldives_Honeymoon = [
  {
    id: "Maldives_Premium_Honeymoon",
    title: "Maldives Premium Honeymoon",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 164000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: true,
  },

  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience Two Island",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: true,
  },
  {
    id: "Adaaran_Club_Rannalhi",
    title: "Adaaran Club Rannalhi",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 147999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg31,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 251511,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg33,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 160999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg32,
    featured: true,
  },
  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 45999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg34,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    category: "Honeymoon_Packages",
    distance: 500,
    price: 151999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg35,
    featured: true,
  },
];

//Resort Packages

export const maldives_Resort = [
  {
    id: "Adaaran_Club_Rannalhi",
    title: "Adaaran Club Rannalhi",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 147999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg31,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 251511,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg33,
    featured: true,
  },
  {
    id: "Hudhuran_Fushi",
    title: "Adaaran Select Hudhuran Fushi",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 251511,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg32,
    featured: true,
  },
  {
    id: "Arena_Beach_Hotel",
    title: "Arena Beach Hotel",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 45999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg34,
    featured: true,
  },
  {
    id: "Embudu_Village_Resort",
    title: "Embudu Village Resort",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 151999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg35,
    featured: true,
  },
  {
    id: "Hardrock_Maldives",
    title: "Hardrock Maldives",
    city: "Maldives",
    category: "Maldives_Resort",
    distance: 500,
    price: 229999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg36,
    featured: true,
  },
];

//Budget Packages

export const maldives_Budget = [
  {
    id: "ADAARAN",
    title: "ADAARAN CLUB RANNALHI",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 147999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Dhiffushi",
    title: "Dhiffushi Island",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 47099,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Thulusdhoo",
    title: "Thulusdhoo Island",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 55599,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg05,
    featured: true,
  },
  {
    id: "Dhiffushi&Thulusdhoo",
    title: "Dhiffushi & Thulusdhoo Island",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 69999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg06,
    featured: true,
  },
  {
    id: "Maldives_Premium_Honeymoon",
    title: "Maldives Premium Honeymoon",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 164000,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg07,
    featured: true,
  },
  {
    id: "Personalized_Island_Experience",
    title: "Personalized Island Experience, Maldives",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 50594,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg08,
    featured: true,
  },
  {
    id: "Maldives_Submarine_Dive",
    title: "Maldives Submarine Dive Discovery Voyage Package",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg09,
    featured: true,
  },
  {
    id: "Splendid_Serenity_Retreat",
    title: "Splendid Serenity Retreat Maldives Package",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg10,
    featured: true,
  },
  {
    id: "Honeymoon_Hideaway_Experience_Two_Island",
    title: "Honeymoon Hideaway Experience Two Island",
    city: "Maldives",
    Category: "Budget_Packages",
    distance: 500,
    price: 76014,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    reviews: [
      {
        name: "jhon doe",
        rating: 4.6,
      },
    ],
    avgRating: 4.5,
    photo: tourImg11,
    featured: true,
  },
];

//watersports Packages

export const maldives_WaterSports = [
  {
    id: "Personalized_Island_Experience",
    title: "Personalized Island Experience, Maldives",
    city: "Maldives",
    Category: "Watersports_Packages",
    distance: 500,
    price: 50594,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg08,
    featured: true,
  },
  {
    id: "Maldives_Submarine_Dive",
    title: "Maldives Submarine Dive Discovery Voyage Package",
    city: "Maldives",
    Category: "Watersports_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg09,
    featured: true,
  },
  {
    id: "Splendid_Serenity_Retreat",
    title: "Splendid Serenity Retreat Maldives Package",
    city: "Maldives",
    Category: "Watersports_Packages",
    distance: 500,
    price: 58220,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 8,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg10,
    featured: true,
  },
  {
    id: "Adaaran_Prestige",
    title: "Adaaran Prestige Vadoo Maldives",
    city: "Maldives",
    Category: "Watersports_Packages",
    distance: 500,
    price: 251511,
    address: "Maldives",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    desc: "this is the description",
    avgRating: 4.5,
    photo: tourImg08,
    featured: true,
  },
];

// maldives below iternary sub Packages

export const Adaaran_Rannalhi = [
  {
    id: "Adaaran_Club_Rannalhi",
    title: "Adaaran Club Rannalhi (Standard Room)",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 223999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];

export const Adaaran_Vadoo = [
  {
    id: "Sunrise_Water_Villa",
    title: "Sunrise Water Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 259999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Sunset_Water_Villa",
    title: "Sunset Water Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 286999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Honeymoon_Water_Villa",
    title: "Honeymoon Water Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 299999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];

export const Adaaran_Hudhuran = [
  {
    id: "Beach_Villa",
    title: "Beach Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 176899,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Sunset_Beach_Villa",
    title: "Sunset Beach Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 192799,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Sunrise_Ocean_Villa",
    title: "Sunrise Ocean Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 217899,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Sunset_Ocean_Villa",
    title: "Sunset Ocean Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 223999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Garden_Villa",
    title: "2N Garden Villa + 1N Sunrise Ocean Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 179779,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Beach_Villa",
    title: "2N Beach Villa + 1N Sunrise Ocean Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 189999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];

export const Embudu_Village_Resort = [
  {
    id: "Water_Bungalow",
    title: "Water Bungalow",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 186566,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];

export const Arena_Beach_Hotel = [
  {
    id: "Standard_Deluxe_Room",
    title: "Standard Deluxe Room With Balcony",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 47999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Deluxe_Double_Room",
    title: "Deluxe Double Room with Balcony and Island View",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 50999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Super_Deluxe_Room",
    title: "Super Deluxe Room with Balcony and Island View",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 60999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Deluxe_Double_Room1",
    title: "Deluxe Double Room with Balcony and Seaview",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 56999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];

export const Hardrock_Maldives_Resorts = [
  {
    id: "Silver_Beach_Studio",
    title: "Silver Beach Studio",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 253789,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Silver_Family_Suite",
    title: "Silver Family Suite (2 Floor Duplex)",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 483999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Gold_Beach_Villa",
    title: "Gold Beach Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 288999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Gold_Beach_Pool_Villa",
    title: "Gold Beach Pool Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 360666,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Platinum_Overwater_Villa",
    title: "Platinum Overwater Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 312777,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Platinum_Overwater_Pool_Villa",
    title: "Platinum Overwater Pool Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 384714,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Diamond_Overwater_pool_Villa",
    title: "Diamond Overwater pool Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 416999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Rock_Royalty_Overwater_Pool_Villa",
    title: "Rock Royalty Overwater Pool Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 801818,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
  {
    id: "Rock_Star_Villa",
    title: "Rock Star Villa",
    city: "Maldives",
    Category: "Maldives_Resort",
    distance: 500,
    price: 1547999,
    address: "Somewhere",
    packages: "3 Nights/4 days",
    maxGroupSize: 2,
    avgRating: 4.5,
    photo: tourImg04,
    featured: true,
  },
];
