import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { coralDest } from "../../assets/data/tours";

const GolRecommended = () => {
  return (
    <>
      {coralDest?.map((coral) => (
        <Col lg="3" md="6" sm="6" className="mb-4" key={coral.id}>
          <TourCard tour={coral} />
        </Col>
      ))}
    </>
  );
};

export default GolRecommended;
