import React from "react";
import "./CancellationAndRefund.css";

const CancellationAndRefund = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-header">Cancellation And Refund</h1>
      <div className="terms-content">
        <p>
          100% refund if the schedule is cancelled due to unfavorable weather
          conditions and other unexpected technical faults No damages/claims
          will be payable except money paid by them.
        </p>
        <p>
          Bookings within 2-3 months require a 25% payment of the total amount.
        </p>
        <p>Bookings exceeding 4 months necessitate a 10% payment upfront.</p>
        <p>Full settlement must occur 14 days before the arrival date.</p>
        <p>
          Failure to make timely payments may result in the release of bookings,
          unless alternative arrangements are agreed upon with the hotel.
        </p>
        {/* More content */}
      </div>
    </div>
  );
};

export default CancellationAndRefund;
