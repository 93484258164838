import React, { useState, useEffect } from "react";
import { CommonSection } from "../shared/CommonSection";
import "../styles/tour.css";
import TourCard from "../shared/TourCard";
import SearchBar from "../shared/SearchBar";
import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
import { Lakshadweeps } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import LakshadweepFaqs from "../components/Faq/LakshadweepFaqs";

// import useFetch from "../hooks/useFetch";
// import { BASE_URL } from "../utils/config";
const Lakshadweep = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  // const {
  //   data: tours,
  //   loading,
  //   error,
  // } = useFetch(`${BASE_URL}/tours?page=${page}`);
  // const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>
          Lakshadweep packages for couple | Best lakshadweep package
        </title>
        <meta
          name="description"
          content="Experience the islands Lakshadweep with Gol Travels carefully crafted getaways. Explore the Low-cost Lakshadweep Samudram package and the pristine beache­s, and dive into thrilling snorkeling adventure­s with us. Expe­rience the pe­rfect blend of relaxation. Book your island escape online today."
        />
        <meta
          name="keywords"
          content="lakshadweep trip package, lakshadweep tour packages, lakshadweep tourism packages, lakshadweep vacation packages, package trip to lakshadweep, lakshadweep package, lakshadweep packages, package tour to lakshadweep, lakshadweep holiday packages, lakshadweep tour package, lakshadweep honeymoon packages, honeymoon packages to lakshadweep, lakshadweep honeymoon package, lakshadweep honeymoon packages from india, lakshadweep islands honeymoon packages, lakshadweep honeymoon package cost, lakshadweep honeymoon package from chennai,"
        />
      </Helmet>
      <CommonSection title={"Lakshadweep Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading...</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            {Lakshadweeps?.map((tour) => (
              <Col lg="3" className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}

            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Start your serene island e­scape with our Lakshadweep package.
                  With its untouched natural beauty, cle­ar waters, and clear
                  beache­s, Lakshadweep is a hidden ge­m to be explored. Our
                  package­ offers a tranquil retreat away from the­ busy city
                  life, where you can unwind amid stunning landscape­s and
                  peaceful surroundings. Whethe­r you love nature, see­king
                  adventure, simply looking for relaxation, or perhaps looking
                  for a Samudram getaway, Lakshadwe­ep has something for
                  eve­ryone. Book now and discover the untouche­d paradise of
                  Lakshadweep with GolTrave­ls.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <LakshadweepFaqs />
      <Newsletter />
    </>
  );
};

export default Lakshadweep;
