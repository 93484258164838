import React from "react";
import { Col } from "reactstrap";
import MaldivesCardDesign from "./MaldivesCardDesign";
import { MaldivesCatogories } from "../../../assets/data/maldivesTours";

const MaldivesAllCatogories = () => {
  return (
    <>
      {MaldivesCatogories?.map((packages) => (
        <Col lg="3" md="6" sm="6" className="mb-4" key={packages.id}>
          <MaldivesCardDesign tour={packages} />
        </Col>
      ))}
    </>
  );
};

export default MaldivesAllCatogories;
