import React from "react";
import { Navbar } from "reactstrap";
import "./bannernav.css";
import { ReactTyped } from "react-typed";
import SearchBar from "../shared/SearchBar";
import Header1 from "../components/Header/Header1";
import BannerSearchbar from "../shared/BannerSearchbar";
// import Header from "../components/Header/Header";
// import SearchBar from "../shared/SearchBar";
// import Banner1 from "../assets/images/banners/B1.png"
const BannerWithNavbar = () => {
  return (
    <>
      {/* <Header1 /> */}
      <div className="banner-container">
        <div className="banner">
          {/* <Navbar
            color="transparent"
            light
            expand="md"
            className="navbar-inside-banner"
          >
            <Header1 />
          </Navbar> */}
          {/* <Header1
            color="transparent"
            light
            expand="lg"
            className="navbar-inside-banner"
          /> */}
          <h1 className="banner-content">
            Your -{" "}
            <ReactTyped
              className="react-typed"
              strings={[
                "dream like Destinations that await you!",
                "dreams getaway is here",
              ]}
              typeSpeed={100}
              loop
              backSpeed={20}
              showCursor={false}
            />
          </h1>
          {/* </p> */}
        </div>
        {/* <div>
          <BannerSearchbar />
        </div> */}
      </div>
      <div className="social-content">
        <div>
          <img
            src="	https://pyt-images.imgix.net/Group_3997_1_a96092eaf7.png?auto=format"
            alt=""
          />
          <span>
            4.6{" "}
            <span>
              <i class="ri-star-fill"></i>
              <span>rated</span>
            </span>
          </span>
        </div>
        <div>
          <i class="ri-checkbox-circle-fill"></i>
          <span>100% Customised Trips</span>
        </div>
        <div className="responsive-social">
          <i class="ri-checkbox-circle-fill"></i>
          <span>95% Visa Success Rate</span>
        </div>
        <div className="responsive-social">
          <i class="ri-checkbox-circle-fill"></i>
          <span>24x7 Concierge</span>
        </div>
      </div>
    </>
  );
};

export default BannerWithNavbar;
