import React, { useState, useEffect } from "react";
import { CommonSection2 } from "../shared/CommonSection";
import "../styles/tour.css";
import TourCard from "../shared/TourCard";
import SearchBar from "../shared/SearchBar";
import Newsletter from "../shared/Newsletter";
import { Col, Container, Row } from "reactstrap";
import { bali } from "../assets/data/tours";
import { Helmet } from "react-helmet";
import BaliFaq from "../components/Faq/BaliFaq";

// import useFetch from "../hooks/useFetch";
// import { BASE_URL } from "../utils/config";
const Bali = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  // const {
  //   data: tours,
  //   loading,
  //   error,
  // } = useFetch(`${BASE_URL}/tours?page=${page}`);
  // const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    const pages = Math.ceil(11 / 8);
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <>
      <Helmet>
        <title>Best bali packages for couple | Bali honeymoon packages</title>
        <meta
          name="description"
          content="Explore the best in travel packages with GoL. Luxury on a budget in Bali! Ideal for solo travelers, bachelor getaways, and couples."
        />
        <meta
          name="keywords"
          content="bali vacation packages, bali packages, bali trip package, bali all inclusive packages, bali all inclusive packages with flight, bali island package, bali travel packages, bali indonesia package, all inclusive bali packages, bali honeymoon packages, bali honeymoon package, bali for honeymoon package, honeymoon packages in bali, bali honeymoon tour packages, bali honeymoon trip package, bali island honeymoon package, bali honeymoon packages all inclusive, bali indonesia honeymoon package, bali packages for honeymoon"
        />
      </Helmet>
      <CommonSection2 title={"Bali, Indonasia Packages"} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading...</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            {bali?.map((tour) => (
              <Col lg="3" className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}
            <Col lg="12">
              <div className="pagination d-flex align-item-center justify-content-center mt-4 gap-3">
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? "active__page" : ""}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
              <div className="desc">
                <p>
                  Experience the­ magical beauty of Bali with our exclusive
                  package­. Let yourse­lf immerse in the vibrant culture,
                  pristine be­aches, and lush landscapes that make Bali an
                  e­arthly paradise. Whether e­xploring ancient temples or
                  indulging in thrilling wate­r sports, our package offers an
                  unforgettable­ journey filled with adventure­ and relaxation.
                  Whether se­eking tranquil moments amidst rice te­rraces or a
                  lively nightlife in be­ach clubs, our Bali package ensures a
                  we­ll-rounded experie­nce catering to eve­ry traveler's
                  desire­s. Book now and let us carry you to a tropical paradise
                  exce­eding your wildest dreams.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <BaliFaq />
      <Newsletter />
    </>
  );
};

export default Bali;
