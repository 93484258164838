import React from "react";
import TourCard from "../../shared/TourCard";
import { Col } from "reactstrap";
import { srilanka } from "../../assets/data/tours";

const SrilankaTourList = () => {
  return (
    <>
      {srilanka?.map((sri) => (
        <Col lg="4" md="6" sm="6" className="malaysia__cards mb-4" key={sri.id}>
          <TourCard tour={sri} />
        </Col>
      ))}
    </>
  );
};

export default SrilankaTourList;
