import React from "react";
import AboutCard from "../components/AboutUS/AboutCard";
import Aboutus from ".././assets/images/sub-banners/About-us.webp";

const About = () => {
  return (
    <>
      <div className="about">
        <img src={Aboutus} alt="" width="100%" height="400px" />
        <AboutCard />
      </div>
    </>
  );
};

export default About;
