import React, { useEffect, useRef, useState } from "react";
import "../../styles/tour-details.css";
import "../../styles/Home.css";

import { useParams } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "reactstrap";
import calculateAvgRating from "../../utils/avgRating";
import avatar from "../../assets/images/avatar.jpg";
import Booking from "../../components/Booking/Booking";
import Newsletter from "../../shared/Newsletter";
// import { BASE_URL } from "../utils/config";
// import useFetch from "../hooks/useFetch";
import { MalaysiaTours } from "../../assets/data/tours";

const MalaysiaTourDetails = () => {
  const { id } = useParams();
  const reviewMsgRef = useRef("");
  // eslint-disable-next-line no-unused-vars
  const [tourRating, setTourRating] = useState(null);

  const tour = MalaysiaTours.find((tour) => tour.id === id);

  // fetch data from database
  //const { data: tour, loading, error } = useFetch(`${BASE_URL}/tours/${id}`);

  const {
    photo,
    title,
    desc,
    inclusion,
    exclusion,
    policy,
    price,
    packages,
    reviews,
    city,
    maxGroupSize,
  } = tour;

  const { totalRating, avgRating } = calculateAvgRating(reviews);

  // format date
  const options = { day: "numeric", month: "long", year: "numeric" };

  // submit request to the server
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   const reviewText = reviewMsgRef.current.value;
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tour]);

  return (
    <>
      <section>
        <Container>
          {/* {loading && <h4 className="text-center pt-5">Loading....</h4>}
          {error && <h4 className="text-center pt-5">{error}</h4>}

          {!loading && !error && ( */}
          <Row>
            <Col lg="8">
              <div className="tour__content">
                <img src={photo} alt="" />
                <div className="tour__info">
                  <h1>{title}</h1>
                  <div className="d-flex align-items-center gap-5">
                    <span className="tour__rating d-flex align-items-center gap-1">
                      <i
                        class="ri-star-s-fill"
                        style={{ color: "var(--secondary-color)" }}
                      ></i>
                      {avgRating === 0 ? null : avgRating}{" "}
                      {totalRating === 0 ? (
                        "Not rated"
                      ) : (
                        <span>{reviews?.length}</span>
                      )}
                    </span>
                    <span>
                      <i class="ri-map-pin-2-line"></i>
                      {city}
                    </span>
                  </div>
                  <div className="tour__extra-details">
                    <span>{packages}</span>
                    <span>
                      <i class="ri-money-dollar-circle-line"></i>₹{price}
                    </span>
                    {/* <span>
                      <i class="ri-map-pin-time-line"></i>
                      {distance} k/m
                    </span> */}
                    <span>
                      <i class="ri-group-line"></i>
                      {maxGroupSize}
                    </span>
                  </div>
                  <h5>Description</h5>
                  <p>
                    <span>{desc.Day1}</span>
                    {desc.Desc1}
                  </p>
                  <p>
                    <span>{desc.Day2}</span>
                    {desc.Desc2}
                  </p>
                  <p>
                    <span>{desc.Day3}</span>
                    {desc.Desc3}
                  </p>
                  <p>
                    <span>{desc.Day4}</span>
                    {desc.Desc4}
                  </p>
                  <p>
                    <span>{desc.Day5}</span>
                    {desc.Desc5}
                  </p>
                  <p>
                    <span>{desc.Day6}</span>
                    {desc.Desc6}
                  </p>
                  <p>
                    <span>{desc.Day7}</span>
                    {desc.Desc7}
                  </p>

                  <h5>Inclusion</h5>
                  <li>{inclusion.point1}</li>
                  <li>{inclusion.point2}</li>
                  <li>{inclusion.point3}</li>
                  <li>{inclusion.point4}</li>

                  <h5>Exclusion</h5>
                  <li>{exclusion.point1}</li>
                  <li>{exclusion.point2}</li>
                  <li>{exclusion.point3}</li>

                  <h5>PAYMENT POLICY & TERMS</h5>
                  <li>{policy.point1}</li>
                  <li>{policy.point2}</li>
                  <li>{policy.point3}</li>
                  <li>{policy.point4}</li>
                  <li>{policy.point5}</li>
                </div>
                {/* ======= tour review section ========= */}
                <div className="tour__reviews mt-4">
                  <h4>Reviews ({reviews?.length} reviews)</h4>
                  {/* <Form onSubmit={submitHandler}> */}
                  <div className="d-flex align-items-center gap-3 mb-4 rating__group">
                    <span onClick={() => setTourRating(1)}>
                      1 <i class="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setTourRating(2)}>
                      2 <i class="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setTourRating(3)}>
                      3 <i class="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setTourRating(4)}>
                      4 <i class="ri-star-s-fill"></i>
                    </span>
                    <span onClick={() => setTourRating(5)}>
                      5 <i class="ri-star-s-fill"></i>
                    </span>
                  </div>
                  <div className="review__input">
                    <input
                      type="text"
                      ref={reviewMsgRef}
                      placeholder="share your thoughts"
                      required
                    />
                    <button
                      className="btn primary__btn text-white"
                      type="submit"
                    >
                      submit
                    </button>
                  </div>
                  {/* </Form> */}

                  <ListGroup className="user__reviews">
                    {reviews?.map((review) => (
                      <div className="review__item">
                        <img src={avatar} alt="" />
                        <div className="w-100">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <h5>Rahul</h5>
                              <p>
                                {new Date("01-18-2023").toLocaleString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>
                            <span className="d-flex align-items-center">
                              5 <i class="ri-star-s-fill"></i>
                            </span>
                          </div>
                          <h6>Amazing tour</h6>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>

                {/* ======= tour review section end========= */}
              </div>
            </Col>
            <Col lg="4">
              <Booking tour={tour} avgRating={avgRating} />
            </Col>
          </Row>
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default MalaysiaTourDetails;
