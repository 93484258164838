import React, { useState, useContext } from "react";

import { Container, Row, Col, Form, FormGroup, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import "../styles/login.css";

import loginImg from "../assets/images/login1.png";
import userIcon from "../assets/images/user1.png";

// import google_logo from "../assets/images/google_logo.png";
// import facebook_logo from "../assets/images/facebook_logo.png";

import { AuthContext } from "./../context/AuthContext";
import { BASE_URL } from "../utils/config";
import { Helmet } from "react-helmet";

const Login = () => {
  const apiKey = process.env.REACT_APP_API_KEY;

  const [credentials, setCredentials] = useState({
    email: undefined,
    password: undefined,
  });

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();

    dispatch({ type: "LOGIN_START" });

    try {
      const res = await fetch(`${apiKey}/api/v1/auth/login`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        // credentials: "include",
        body: JSON.stringify(credentials),
      });
      const result = await res.json();
      if (!res.ok) alert(result.message);

      console.log(result.data);

      dispatch({ type: "LOGIN_SUCCESS", Payload: result.data });
      navigate("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", Payload: err.message });
      navigate("/register/");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Welcome­ back to Gol Travels! Access your travel account here
        </title>
        <meta
          name="description"
          content=" Log in to your Gol Travels account to manage­ bookings, view your travel plans, and take advantage­ of exclusive offers. Enjoy a hassle­-free travel planning e­xperience with our e­asy-to-use platform. Click and start your travel journey here"
        />
      </Helmet>
      <section>
        <Container>
          <Row>
            <Col lg="8" className="m-auto">
              <div className="login__container d-flex justify-content-between">
                <div className="login__img">
                  <img src={loginImg} alt="" />
                </div>

                <div className="login__form">
                  <div className="user">
                    <img src={userIcon} alt="" />
                  </div>
                  <h2>Login</h2>
                  <Form onSubmit={handleClick}>
                    <FormGroup>
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        id="email"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        id="password"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <Button
                      className="btn secondary__btn auth__btn"
                      type="submit"
                    >
                      Login
                    </Button>
                  </Form>
                  <p>
                    Don't have an account? <Link to="/register">Create</Link>
                  </p>
                  {/* <button className="google-login-button">
                  <img
                    src={google_logo}
                    alt="Google Logo"
                    className="google-logo"
                  />
                  <span>Login with Google</span>
                </button>
                <button className="facebook-login-button">
                  <img
                    src={facebook_logo}
                    alt="Facebook Logo"
                    className="facebook-logo"
                  />
                  <span>Login with Facebook</span>
                </button> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
