/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "../../components/Contact/contactcard.css";
import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import Contactus from "../../assets/images/sub-banners/contact-us.webp";

const ContactCard = () => {
  return (
    <>
      <img src={Contactus} alt="" width="100%" height="400px" />

      <div className="map-cards">
        <Row className="row-cards">
          <Col sm="6" className="single-cards">
            <Card body>
              <div>
                <iframe
                  width="100%"
                  height="200"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gol%20Travels,%20435,%20ground%20floor,%203rd%20A%20cross%20Road,%205th%20Main%20Rd,%20HRBR%20Layout%202nd%20Block,%20Kalyan%20Nagar,%20Bengaluru,%20Karnataka%20560043+(Gol%20travels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/">gps devices</a>
                </iframe>
              </div>
              <CardTitle Title className="name" tag="h1">
                Branch : Bengaluru
              </CardTitle>
              <CardText className="address">
                Address: 435, ground floor, 3rd A cross Road, 5th Main Rd, HRBR
                Layout 2nd Block, Kalyan Nagar, Bengaluru, Karnataka 560043
                <p> Mail Id : info@goltravels.com </p>
                <p> Contact No : 9778389592</p>
              </CardText>
            </Card>
          </Col>

          <Col sm="6" className="single-cards">
            <Card body>
              <div>
                <iframe
                  width="100%"
                  height="200"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=45%20Indra%20Palace,%20H%20Block,%20Middle%20Circle,%20Connaught%20Place,%20New%20Delhi,%C2%A0Delhi%C2%A0110001+(Gol%20travels%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/">gps trackers</a>
                </iframe>
              </div>
              <CardTitle className="name" tag="h1">
                Branch : Delhi
              </CardTitle>
              <CardText className="address">
                Address: 45 Indra Palace, H Block, Middle Circle, Connaught
                Place, New Delhi, Delhi 110001
                <p> Mail Id : info@goltravels.com </p>
                <p> Contact No : 9778389592</p>
              </CardText>
            </Card>
          </Col>

          <Col sm="6" className="single-cards">
            <Card body>
              <div>
                <iframe
                  width="100%"
                  height="200"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gol%20Travels,%20Pallichal%20Road,%20Pyari%20Jct%20Rd,%20Thoppumpady,%20Kochi,%20Kerala%20682005+(Gol%20travels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/">gps vehicle tracker</a>
                </iframe>
              </div>
              <CardTitle className="name" tag="h1">
                Branch : Kochi
              </CardTitle>
              <CardText className="address">
                Address: Pallichal Road, Pyari Jct Rd, Thoppumpady, Kochi,
                Kerala 682005
                <p> Mail Id : info@goltravels.com </p>
                <p> Contact No : 9778389592</p>
              </CardText>
            </Card>
          </Col>

          <Col sm="6" className="single-cards">
            <Card body>
              <div>
                <iframe
                  width="100%"
                  height="200"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kavaratti+(Gol%20Travels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/">gps vehicle tracker</a>
                </iframe>
              </div>

              <CardTitle className="name" tag="h1">
                Branch : Lakshadweep
              </CardTitle>
              <CardText className="address">
                Address: Kavaratti, Lakshadweep. 682555
                <p> Mail Id : info@goltravels.com </p>
                <p> Contact No : 9778389592</p>
              </CardText>
            </Card>
          </Col>

          <Col sm="6" className="single-cards">
            <Card body>
              <div>
                <iframe
                  width="100%"
                  height="200"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=male,%20maldives+(Gol%20Travels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.gps.ie/">gps systems</a>
                </iframe>
              </div>
              <CardTitle className="name" tag="h1">
                Branch : Male
              </CardTitle>
              <CardText className="address">
                Address: Male, Maldives.
                <p> Mail Id : info@goltravels.com </p>
                <p> Contact No : 9778389592</p>
              </CardText>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactCard;
