import React, { useState } from "react";
import "./booking.css";
import { Form, FormGroup, ListGroup, ListGroupItem, Button } from "reactstrap";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/logo1.png";
// import { BASE_URL } from "../../utils/config";

const Booking = ({ tour, avgRating }) => {
  const { price, reviews, person } = tour;

  // const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    userId: "01",
    userEmail: "example@gmail.com",
    fullName: "",
    phone: "",
    guestSize: 1,
    bookAt: "",
  });

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // const serviceFee = 10;
  // const totalAmount =
  //   Number(price) * Number(credentials.guestSize) + Number(serviceFee);

  // send data to the server
  // const handleClick = (e) => {
  //   e.preventDefault();

  //   navigate("/thank-you");
  // };

  const handleClick = async () => {
    debugger;
    const apiKey = "http://localhost:4000";
    const {
      data: { key },
    } = await axios.get(`${apiKey}/api/v1/payment/getkey`);

    const {
      data: { order },
    } = await axios.post(`${apiKey}/api/v1/payment/checkout`, {
      price,
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "Gol Travels",
      description: "Goltravels Razorpay",
      image: logo,
      order_id: order.id,
      callback_url: `${apiKey}/api/v1/payment/paymentverification`,
      prefill: {
        name: "Sabeera Beegum T K",
        email: "help@golakshadweep.com",
        contact: "7012550984",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <div className="booking">
      <div className="booking__top d-flex align-items-center justify-content-between">
        <h3>
          ₹{price} <span>{person}</span>
        </h3>
        <span className="tour__rating d-flex align-items-center">
          <i class="ri-star-s-fill"></i>
          {avgRating === 0 ? null : avgRating}({reviews?.length})
        </span>
      </div>
      {/* ============ booking form ============ */}
      <div className="booking__form">
        <h5>Information</h5>
        <Form className="booking__info-form" onSubmit={handleClick}>
          <FormGroup>
            <input
              type="text"
              placeholder="Full Name"
              id="fullName"
              required
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <input
              type="number"
              placeholder="Phone"
              id="phone"
              required
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup className="d-flex align-items-center gap-3">
            <input
              type="date"
              placeholder=""
              id="bookAt"
              required
              onChange={handleChange}
            />
            <input
              type="number"
              placeholder="Guest"
              id="guestSize"
              required
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </div>
      {/* ============ booking end ============ */}

      {/* ============ booking bottom ============ */}
      <div className="booking__bottom">
        <ListGroup>
          <ListGroupItem className="border-0 px-0">
            <h5 className="d-flex align-items-center gap-1">
              ₹{price} <i class="ri-close-line"> {person}</i>
            </h5>
            <span>₹{price}</span>
          </ListGroupItem>
          <ListGroupItem className="border-0 px-0">
            <h5>Including GST and Service Charge</h5>
            {/* <span>₹{serviceFee}</span> */}
          </ListGroupItem>
          <ListGroupItem className="border-0 px-0 total">
            <h5>Total</h5>
            <span>₹{price}</span>
          </ListGroupItem>
        </ListGroup>
        <Button
          className="btn primary__btn w-100 mt-4"
          onClick={() => handleClick()}
        >
          Book Now
        </Button>
      </div>
    </div>
  );
};

export default Booking;
