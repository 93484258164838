import React from "react";
import "../styles/Home.css";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../assets/images/hero-img01.webp";
import heroImg02 from "../assets/images/hero-img02.webp";
import heroVideo from "../assets/images/hero-video.mp4";
import worldImg from "../assets/images/world.png";
import experienceImg from "../assets/images/experience3.png";
import Subtitle from "../shared/Subtitle";
import SearchBar from "../shared/SearchBar";
import ServiceList from "../services/ServiceList";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";
// import SlidingImages from "./slidingImages";
import NewPackagesList from "../components/Packages.tours/newPackagesList";
import MaldivesTourList from "../components/Featured.tours/MaldivesTourList";
import LakshadweepTourList from "../components/Featured.tours/LakshadweeTourList";
import BaliTourList from "../components/Featured.tours/BaliTourList";
import ThailandTourList from "../components/Featured.tours/ThailandTourList";
import MalaysiaTourList from "../components/Featured.tours/MalaysiaTourList";
import BannerWithNavbar from "./BannerWithNavbar";
import Partner from "./Partner";
import "../pages/partner.css";
import VisaFreeDest from "../components/Featured.tours/VisaFreeDest";
import GolRecommended from "../components/Featured.tours/GolRecommended";
import { Helmet } from "react-helmet";
import AutoLaunchModal from "../components/EnquiryForm/PopupModel";
import SrilankaTourList from "../components/Featured.tours/SrilankaTourList";
// import Enquire from "../components/EnquiryForm/Enquire";
// import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Explore Exotic Destinations: Lakshadweep, Maldives, Thailand, Bali &
          Malaysia Packages | Gol Travels
        </title>
        <meta
          name="description"
          content="Discover breathtaking beaches, rich cultures, and unforgettable adventures with Gol Travels' exclusive travel packages to Lakshadweep, Maldives, Thailand, Bali, and Malaysia. Start planning your dream vacation today!"
        />
      </Helmet>
      <BannerWithNavbar />
      {/* <Popup position="right center"> 
        <Enquire />
      </Popup> */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">MOST POPULAR PACKAGES</h2>
            </Col>
            <NewPackagesList />
          </Row>
        </Container>
      </section>

      {/* featured tour section start */}

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title1">
                Best Coral Destination Recommended By Gol
              </h2>
            </Col>
            <GolRecommended />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title2">Visa Free Destinations</h2>
            </Col>
            <VisaFreeDest />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Lakshadweep Packages</h2>
            </Col>
            <LakshadweepTourList />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Maldives Packages</h2>
            </Col>
            <MaldivesTourList />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Bali, Indonesia Packages</h2>
            </Col>
            <BaliTourList />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Thailand Packages</h2>
            </Col>
            <ThailandTourList />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Malaysia Packages</h2>
            </Col>
            <MalaysiaTourList />
          </Row>
        </Container>
      </section>

      <section className="section">
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Srilanka Packages</h2>
            </Col>
            <SrilankaTourList />
          </Row>
        </Container>
      </section>
      {/* featured tour section end */}

      {/* know before you go */}

      <section className="section">
        <Container>
          <Row>
            <Col lg="6" className="mb-5">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-item-center">
                  <Subtitle subtitle={"Know Before You Go"} />
                  <img src={worldImg} alt="" />
                </div>
                <h1>
                  Discover the comfort of traveling with{" "}
                  <span className="highlight">
                    convenience and joy with us.
                  </span>
                </h1>
                <p>
                  Discover the comfort of travelling with convenience and joy
                  with us.
                </p>
                <p>
                  At GOL, we understand that every journey is a masterpiece
                  waiting to be crafted. It's about the joy of connecting with
                  new cultures, the thrill of unexpected discoveries, and the
                  warmth of shared laughter with fellow explorers.
                </p>
                <p>
                  We are your complete service provider for Lakshadweep and
                  Maldives, Thailand, Indonesia ( Bali), Malaysia Sri Lanka and
                  more. Offering a diverse range of services including Tours and
                  Travels, Shore excursions for cruises, world-class
                  accommodation, etc.
                </p>

                {/* <SearchBar /> */}
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box">
                <img src={heroImg} alt="" />
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box hero__video-box mt-4">
                <video src={heroVideo} alt="" controls autoPlay />
              </div>
            </Col>
            <Col lg="2">
              <div className="hero__img-box mt-5">
                <img src={heroImg02} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* hero section start */}
      <section className="section">
        <Container>
          <Row>
            <Col lg="3">
              <h5 className="services__subtitle">Services we offer </h5>
              <h2 className="services__title"> State of the art Services</h2>
            </Col>
            <ServiceList />
          </Row>
        </Container>
      </section>

      {/* end of know before you go */}

      {/* experience section start */}
      <section className="section">
        <Container>
          <Row>
            <Col lg="6">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"}></Subtitle>
                <h2>
                  With our all experience <br /> we will serve you
                </h2>
                <p>
                  With a wealth of experience in the travel industry, GOL
                  Travels is a pioneer to serve you with expertise and
                  dedication. Our seasoned team brings a deep understanding of
                  the intricacies of travel, ensuring that every aspect of your
                  journey is meticulously planned and executed.
                  <br />
                  <br />
                  Whether it's crafting customized itineraries, providing
                  insightful recommendations, or ensuring seamless logistics,
                  our experience is your assurance of a memorable and
                  stress-free travel experience.
                  <br />
                  Trust GOL Travels to transform your wanderlust into a journey
                  of unparalleled excellence.
                </p>
              </div>
              <div className="counter__wrapper d-flex align-items-center gap-5">
                <div className="counter__box">
                  <span>4k+</span>
                  <h6>Successful Trip</h6>
                </div>
                <div className="counter__box">
                  <span>500+</span>
                  <h6>Regular clients</h6>
                </div>
                <div className="counter__box">
                  <span>5</span>
                  <h6>Years experience</h6>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="experience__img">
                <img src={experienceImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* experience section end */}

      {/* gallery section start */}
      <section className="section">
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"gallery"} />
              <h2 className="gallery__title">
                Visit our customers tour gallery
              </h2>
            </Col>
            <Col lg="12">
              <MasonryImagesGallery />
            </Col>
          </Row>
        </Container>
      </section>

      {/* gallery section end */}

      {/* testimonial section start */}

      <section className="section">
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Fans Love"} />
              <h2 className="testimonial__title">Our happy customers</h2>
            </Col>
            <Col>
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
      {/* testimonial section end */}
      <Newsletter />
      <section className="section">
        <Container className="partner__logo">
          <h3>Partnered with the best in the industry</h3>
          <Row>
            <Col>
              <Partner />
            </Col>
          </Row>
        </Container>
      </section>
      <AutoLaunchModal />
    </>
  );
};

export default Home;
